import styled, { css } from 'styled-components'

export const Paginate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  @media (max-width: 825px) {
    margin-top: 20px;
  }
`
export const ArrowLeft = styled.div`
  background-color: var(--box-background);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  max-width: 40px;
  height: 30px;
  border-radius: 5px;
`
export const Page = styled.div`
  width: 40px;
  max-width: 40px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  background-color: ${props =>
    props.active ? css`var(--box-background)` : css``};
  div {
    color: ${props =>
      props.active ? css`var(--primary)` : css`var(--dark-text-color)`};
    font-size: 14px;
    font-weight: 700;
  }
`
export const ArrowRight = styled.div`
  background-color: var(--box-background);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  max-width: 40px;
  height: 30px;
  border-radius: 5px;
`
export const Link = styled.div`
  cursor: pointer;
`
