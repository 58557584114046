import { all } from 'redux-saga/effects'

import auth from './auth'

import billings from './billings'

import compras from './compras'

import notifications from './notifications'

import packs from './packs'

import produto from './produto'

import produtos from './produtos'

import terminals from './terminals'

import websocket from './websocket'

export default function* rootSaga() {
  return yield all([
    auth,
    websocket,
    packs,
    produtos,
    produto,
    compras,
    billings,
    terminals,
    notifications
  ])
}
