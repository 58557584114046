import styled from 'styled-components'

export const Container = styled.div`
  background-color: var(--black);
`
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
  h1 {
    color: var(--primary);
    font-size: 40px;
    font-weight: 300;
  }
  div {
    color: var(--primary);
    font-size: 20px;
    font-weight: 400;
    margin-top: 15px;
    text-align: center;
  }
  @media (max-width: 825px) {
    h1 {
      font-size: 30px;
      width: 100%;
      text-align: center;
    }
    div {
      font-size: 18px;
    }
  }
`
export const ContainerBox = styled.div`
  position: relative;
  margin-top: 80px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: minmax(360px, auto);
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  padding-bottom: 50px;
  .thumbnail_container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  @media (max-width: 1120px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, minmax(300px, auto));
    /* padding-bottom: 60px; */
    margin-top: 50px;
    .pack {
      padding-bottom: 30px;
    }
  }
  .pack {
    position: relative;
    background-color: var(--box-background);
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.2s;
  }
  .pack .title_pack {
    color: var(--primary);
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .pack .title_topico {
    color: var(--primary);
  }
  .active {
    .button_pack_container {
      background-color: #4a4a4d;
    }
    background-color: #313538;
    transform: translateY(-8px);
    @media (max-width: 1125px) {
      transform: translateY(0);
    }
  }
  .pack:last-child {
    background-color: #4a4a4d;
  }
`
export const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  max-height: 140px;
  max-width: 140px;
  flex-shrink: 0;
`
export const ButtonPackContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  position: absolute;
  bottom: 30px;
  transition: all 0.2s ease 0s;

  > div {
    width: 100%;
    max-width: 140px;
    background-color: #313538;
    display: flex;
    justify-content: center;

    padding: 15px;
    border-radius: 5px;

    border: none;
    color: var(--primary);
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
  }

  &:hover {
    transform: translateY(-5px);
  }
  @media (max-width: 1120px) {
    position: unset;
  }
`
export const ContainerTerms = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;

    > li {
      position: relative;
      margin: 0 10px 0 10px;
      > a {
        color: var(--dark-text-color);
        font-size: 15px;
        font-weight: 400;
        cursor: pointer;
        transition: opacity 0.3s ease-in-out;
        opacity: 0.8;
        &:hover {
          opacity: 1;
        }
      }
      &:not(:last-child)::before {
        content: '';
        position: absolute;
        background-color: var(--orange);
        width: 3px;
        height: 90%;
        bottom: 0;
        right: -11px;
      }
    }
  }
  @media (max-width: 1120px) {
    > ul {
      flex-direction: column;
      > li {
        margin: 0 0 15px 0;
        &:not(:last-child)::before {
          display: none;
        }
      }
    }
  }
`
