import styled, { css } from 'styled-components'

export const SidebarContent = styled.div`
  position: sticky;
  top: 120px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-width: 350px;
  background-color: var(--box-background);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin: 0 60px 0 0;
  padding: 8px 0px;

  @media (max-width: 1180px) {
    width: 240px;
    margin-right: 40px;
  }
  @media (max-width: 960px) {
    display: none;
  }
  @media (max-width: 825px) {
    width: 100%;
    max-width: 100%;
    margin: 0 0 20px 0;
  }
`

export const Menu = styled.div`
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  padding: 12px 24px;
  border-width: 0px 0px 0px 2px;
  border-top-style: initial;
  border-right-style: initial;
  border-bottom-style: initial;
  border-top-color: initial;
  border-right-color: initial;
  border-bottom-color: initial;
  border-image: initial;
  border-left-style: solid;
  border-left-color: transparent;

  transition: background 0.2s ease 0s;
  div {
    color: var(--primary);
    font-size: 16px;
    font-weight: 400;
  }
  &:hover {
    width: 100%;
    background-color: var(--hover-gray);
    border-radius: 0;
  }
  ${props =>
    props.active &&
    css`
      width: 100%;
      background-color: var(--hover-gray);
      border-radius: 0;
      border-left-color: var(--orange);
    `};

  &:last-child {
    padding-bottom: 16px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  @media (max-width: 825px) {
    max-width: 100%;
  }
`
