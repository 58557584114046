import React, { useRef, useCallback, useState, useMemo } from 'react'

import useOnclickOutside from 'react-cool-onclickoutside'

import {
  FaShoppingCart,
  FaUser,
  FaArrowLeft,
  FaClipboardList,
  FaBell,
  FaArrowRight
} from 'react-icons/fa'

import { useDispatch, useSelector } from 'react-redux'

import { Link, useLocation } from 'react-router-dom'

import { formatDistance, parseISO } from 'date-fns'

import pt from 'date-fns/locale/pt'

import LogoImage from '../../assets/logo_header.png'

import { Button } from '../../components/Buttons'

import Modal from '../../components/Modal'

import history from '../../routes/history'

import { logout } from '../../services/auth'

import { logoutUser } from '../../store/ducks/auth'

import { resetCart } from '../../store/ducks/cart'

import { updateNotificationRequest } from '../../store/ducks/notifications'

import {
  ContainerHeader,
  HeaderContainer,
  Logo,
  ContainerIconsUser,
  IconGroup,
  ContainerModal,
  Balloon,
  NumberNotification,
  PerfilContainer,
  Perfil,
  Avatar,
  MenuContainerPerfil,
  NotificacoesContainer,
  LinkNotification,
  MenuContainerNotificacoes,
  HeaderNotificacao,
  NotificacaoContainer,
  FooterNotificacao,
  Notification,
  ContainerUserNotificacao,
  AvatarNotification
} from './styles'

export default function Header({ color }) {
  const [menuPerfil, setMenuPerfil] = useState(false)
  const [menuNotificacoes, setMenuNotificacoes] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const modalRef = useRef(null)
  const menuPerfilRef = useRef(null)
  const menuNotificacoesRef = useRef(null)
  const location = useLocation()
  const dispatch = useDispatch()

  const { count } = useSelector(state => state.cart)
  const user = useSelector(state => state.auth.data)
  const { data: notifications, notificationsUnread } = useSelector(
    state => state.notifications
  )

  const handleOpenModal = useCallback(() => {
    modalRef.current.openModal()
    setDisabled(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    modalRef.current.closeModal()
    setDisabled(false)
  }, [])

  const handleOpenMenuPerfil = useCallback(() => {
    setMenuPerfil(!menuPerfil)
    setMenuNotificacoes(false)
  }, [menuPerfil])

  const handleOpenMenuNotificacoes = useCallback(() => {
    setMenuNotificacoes(!menuNotificacoes)
    setMenuPerfil(false)
    dispatch(updateNotificationRequest())
  }, [menuNotificacoes, dispatch])

  const handleLogoutIn = useCallback(() => {
    logout()

    dispatch(logoutUser())
    dispatch(resetCart())

    history.push('/')
  }, [dispatch])

  const backPage = useCallback(() => {
    history.goBack()
  }, [])

  useOnclickOutside(
    () => {
      setMenuPerfil(false)
      setMenuNotificacoes(false)
    },
    { disabled, refs: [menuPerfilRef, menuNotificacoesRef] }
  )

  const contentModal = useMemo(() => {
    return (
      <ContainerModal>
        <h1>Quer mesmo sair?</h1>
        <div className="modal_buttons">
          <Button width={180} onClick={handleCloseModal} title="Não" />
          <Button width={180} onClick={handleLogoutIn} title="Sim" />
        </div>
      </ContainerModal>
    )
  }, [handleCloseModal, handleLogoutIn])

  let sobrenome

  const nome = user?.nome.split(' ')[0]
  const qtdnome = user?.nome.split(' ').length

  if (qtdnome > 1) {
    sobrenome = user?.nome.split(' ')[qtdnome - 1]
  }

  return (
    <>
      <ContainerHeader>
        <HeaderContainer>
          <Link to="/dashboard">
            <Logo src={LogoImage} />
          </Link>
          {location.pathname !== '/dashboard' && (
            <div>
              <Link onClick={backPage} to="#">
                <FaArrowLeft size={16} color="var(--primary)" />
              </Link>
              <Link onClick={backPage} to="#">
                <p>Voltar</p>
              </Link>
            </div>
          )}
        </HeaderContainer>
        <ContainerIconsUser>
          <NotificacoesContainer ref={menuNotificacoesRef}>
            <LinkNotification
              onClick={handleOpenMenuNotificacoes}
              menu={menuNotificacoes}
            >
              <IconGroup>
                <FaBell size={21} color={color} />
                {notificationsUnread > 0 && (
                  <Balloon>
                    <NumberNotification>
                      {notificationsUnread}
                    </NumberNotification>
                  </Balloon>
                )}
              </IconGroup>
            </LinkNotification>

            <MenuContainerNotificacoes menu={menuNotificacoes}>
              <HeaderNotificacao>
                <span>Notificações</span>
              </HeaderNotificacao>
              <NotificacaoContainer>
                {notifications.length > 0 ? (
                  notifications
                    .sort(
                      (a, b) => new Date(b.created_at) - new Date(a.created_at)
                    )
                    .map(({ notification, read }) => (
                      <Link
                        key={notification.id}
                        onClick={() => setMenuNotificacoes(false)}
                        to="/notifications"
                      >
                        <Notification>
                          <ContainerUserNotificacao unRead={read}>
                            <AvatarNotification
                              src={LogoImage}
                            ></AvatarNotification>
                            <div className="notification_data">
                              <div className="container_title">
                                <h1>Meu Fole</h1>
                                {notification.type === 'updates' ? (
                                  <p className="updates">Actualização</p>
                                ) : (
                                  <p className="info">Informação</p>
                                )}
                              </div>
                              <strong>
                                {formatDistance(
                                  parseISO(notification.created_at),
                                  new Date(),
                                  {
                                    locale: pt,
                                    addSuffix: true
                                  }
                                )}
                              </strong>
                              <p>
                                {notification.mensagem.length > 150
                                  ? `${notification.mensagem.substring(
                                      0,
                                      150
                                    )}...`
                                  : notification.mensagem}
                              </p>
                            </div>
                          </ContainerUserNotificacao>
                        </Notification>
                      </Link>
                    ))
                ) : (
                  <div className="not_found_notification">
                    Não tem notificações
                  </div>
                )}
              </NotificacaoContainer>
              {notifications.length > 0 && (
                <Link
                  onClick={() => setMenuNotificacoes(false)}
                  to="/notifications"
                >
                  <FooterNotificacao>
                    <span>Ver todas</span>
                    <FaArrowRight size={16} color="var(--orange)" />
                  </FooterNotificacao>
                </Link>
              )}
            </MenuContainerNotificacoes>
          </NotificacoesContainer>

          <Link to="/cart">
            <IconGroup>
              <FaShoppingCart size={21} color={color} />
              {count > 0 && (
                <Balloon>
                  <NumberNotification>{count}</NumberNotification>
                </Balloon>
              )}
            </IconGroup>
          </Link>

          <PerfilContainer ref={menuPerfilRef}>
            <Perfil onClick={handleOpenMenuPerfil}>
              <span>
                {nome} {sobrenome}
              </span>
              <Avatar>
                <img src={user?.avatar} alt="" />
              </Avatar>
            </Perfil>
            <MenuContainerPerfil menu={menuPerfil}>
              <Link onClick={() => setMenuPerfil(false)} to="/account">
                <span>Meu perfil</span>
                <FaUser size={21} color="var(--orange)" />
              </Link>
              <Link onClick={() => setMenuPerfil(false)} to="/account/compras">
                <span>Lista de compras</span>
                <FaClipboardList size={21} color="var(--orange)" />
              </Link>
              <button onClick={handleOpenModal} type="button">
                <span>Sair da plataforma</span>
                <svg width="21" height="21" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M12.59 6L10 8.59L7.41 6L6 7.41L8.59 10L6 12.59L7.41 14L10 11.41L12.59 14L14 12.59L11.41 10L14 7.41L12.59 6ZM10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
                    fill="var(--orange)"
                  ></path>
                </svg>
              </button>
            </MenuContainerPerfil>
          </PerfilContainer>
        </ContainerIconsUser>
      </ContainerHeader>
      <Modal ref={modalRef} setDisabled={setDisabled} data={contentModal} />
    </>
  )
}
