import React, { useEffect, useCallback, useState, useLayoutEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { useParams } from 'react-router-dom'

import Vimeo from '@u-wave/react-vimeo'

import logo from '../../assets/logo.png'

import { Button } from '../../components/Buttons'

import history from '../../routes/history'

import { produtoRequest } from '../../store/ducks/produto'

import {
  Container,
  ContainerTitle,
  ContainerDetalheVideo,
  ContainerVideo,
  DescricaoVideo,
  DescricaoContainer,
  ContainerFilterVideo,
  VideoPlayer,
  LoadingContainer
} from './styles'

export default function Video() {
  const [sources, setSources] = useState('')
  const dispatch = useDispatch()
  const { slug, lesson } = useParams()

  const { data: video, loading } = useSelector(state => state.produto)

  const getVideo = useCallback(async () => {
    dispatch(produtoRequest(slug))
  }, [dispatch, slug])

  useLayoutEffect(() => {
    getVideo()
  }, [dispatch, getVideo])

  useEffect(() => {
    const { video_fa, video_sol } = video

    if (lesson === 'fa') {
      setSources(video_fa)
    } else if (lesson === 'sol') {
      setSources(video_sol)
    } else {
      setSources(video_fa)
    }
  }, [lesson, video])

  const handleViewVideoFa = useCallback(() => {
    const { video_fa, slug } = video

    history.push(`/video/${slug}/fa`)

    setSources(video_fa)
  }, [video])

  const handleViewVideoSol = useCallback(() => {
    const { video_sol } = video

    history.push(`/video/${slug}/sol`)

    setSources(video_sol)
  }, [slug, video])

  if (loading) {
    return (
      <LoadingContainer>
        <div>
          <img src={logo} alt="" />
        </div>
      </LoadingContainer>
    )
  }

  return (
    <Container>
      <ContainerTitle>
        <h1>{video.titulo}</h1>
      </ContainerTitle>
      <ContainerDetalheVideo>
        <ContainerFilterVideo>
          <Button
            onClick={handleViewVideoFa}
            title="Assistir em Fa"
            margin="20px 0px 0px 0px"
            transform="true"
            background={
              (lesson === 'fa' && 'transparent') || (!lesson && 'transparent')
            }
            border={
              lesson === 'fa'
                ? '2px solid var(--orange)'
                : '2px solid var(--orange)'
            }
          />
          <Button
            onClick={handleViewVideoSol}
            title="Assistir em Sol"
            margin="20px 0px 0px 0px"
            transform="true"
            background={lesson === 'sol' && 'transparent'}
            border={
              lesson === 'sol'
                ? '2px solid var(--orange)'
                : '2px solid var(--orange)'
            }
          />
        </ContainerFilterVideo>
        <ContainerVideo>
          <VideoPlayer>
            {sources && <Vimeo video={sources} autoplay={false} />}
          </VideoPlayer>
        </ContainerVideo>
      </ContainerDetalheVideo>
      <DescricaoVideo>
        <div className="descricao_button">
          <button>Descrição</button>
        </div>
        <DescricaoContainer>
          <div className="sub_container">
            <div className="container_content">
              <div className="content">
                {video.descricao ? (
                  <span className="active">{video.descricao}</span>
                ) : (
                  <span>Este vídeo ainda não tem descrição</span>
                )}
              </div>
            </div>
          </div>
        </DescricaoContainer>
      </DescricaoVideo>
    </Container>
  )
}
