import produce from 'immer'

export const Types = {
  ADD: '@cart/ADD_CART',
  REMOVE: '@cart/REMOVE_CART',
  QUANTITY_CART: '@cart/QUANTITY_CART',
  SUBTRACT_CART: '@cart/SUBTRACT_CART',
  RESET_CART: '@cart/RESET_CART'
}

const INITIAL_STATE = {
  items: [],
  count: 0,
  subtotal: 0,
  iva: 0,
  total: 0
}

export default function cart(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.ADD:
      return produce(state, draft => {
        const { item } = action

        const productIndex = draft.items.findIndex(
          product => product.id === item.id
        )

        if (productIndex >= 0) {
          return
        } else {
          draft.items.push({
            ...item,
            amount: 1,
            subtotal: Number(item.preco),
            total: Number(item.preco)
          })
        }

        const count = draft.items.reduce(
          (accumulator, productCart) => accumulator + productCart.amount,
          0
        )

        const subtotal = draft.items.reduce((accumulator, productCart) => {
          return accumulator + productCart.subtotal
        }, 0)

        // const iva = subtotal * 0.23

        const total = draft.items.reduce((accumulator, productCart) => {
          return accumulator + productCart.total
        }, 0)

        draft.count = count
        draft.subtotal = subtotal
        // draft.iva = iva
        draft.total = total
      })

    case Types.REMOVE:
      return produce(state, draft => {
        const productIndex = draft.items.findIndex(
          product => product.id === action.id
        )

        if (productIndex >= 0) {
          draft.items.splice(productIndex, 1)
        }

        const count = draft.items.reduce(
          (accumulator, productCart) => accumulator + productCart.amount,
          0
        )

        const subtotal = draft.items.reduce((accumulator, productCart) => {
          return accumulator + productCart.subtotal
        }, 0)

        // const iva = subtotal * 0.23

        const total = draft.items.reduce((accumulator, productCart) => {
          return accumulator + productCart.total
        }, 0)

        draft.count = count
        draft.subtotal = subtotal
        // draft.iva = iva
        draft.total = total
      })
    case Types.QUANTITY_CART:
      return produce(state, draft => {
        const productIndex = draft.items.findIndex(
          product => product.id === action.id
        )

        if (productIndex >= 0) {
          draft.items[productIndex].amount = Number(
            draft.items[productIndex].amount + 1
          )

          draft.items[productIndex].subtotal =
            draft.items[productIndex].amount * draft.items[productIndex].preco

          draft.items[productIndex].total =
            draft.items[productIndex].amount * draft.items[productIndex].preco
        }

        const count = draft.items.reduce(
          (accumulator, productCart) => accumulator + productCart.amount,
          0
        )

        const subtotal = draft.items.reduce((accumulator, productCart) => {
          return accumulator + productCart.subtotal
        }, 0)

        // const iva = subtotal * 0.23

        const total = draft.items.reduce((accumulator, productCart) => {
          return accumulator + productCart.total
        }, 0)

        draft.count = count
        draft.subtotal = subtotal
        // draft.iva = iva
        draft.total = total
      })

    case Types.SUBTRACT_CART:
      return produce(state, draft => {
        const productIndex = draft.items.findIndex(
          product => product.id === action.id
        )

        if (productIndex >= 0) {
          if (draft.items[productIndex].amount > 1) {
            draft.items[productIndex].amount = Number(
              draft.items[productIndex].amount - 1
            )
          }

          draft.items[productIndex].subtotal =
            draft.items[productIndex].amount * draft.items[productIndex].preco

          draft.items[productIndex].total =
            draft.items[productIndex].amount * draft.items[productIndex].preco
        }

        const count = draft.items.reduce(
          (accumulator, productCart) => accumulator + productCart.amount,
          0
        )

        const subtotal = draft.items.reduce((accumulator, productCart) => {
          return accumulator + productCart.subtotal
        }, 0)

        // const iva = subtotal * 0.23

        const total = draft.items.reduce((accumulator, productCart) => {
          return accumulator + productCart.total
        }, 0)

        draft.count = count
        draft.subtotal = subtotal
        // draft.iva = iva
        draft.total = total
      })

    case Types.RESET_CART:
      return INITIAL_STATE

    default:
      return state
  }
}

export function addToCart(item) {
  return {
    type: Types.ADD,
    item
  }
}
export function removeFromCart(id) {
  return {
    type: Types.REMOVE,
    id
  }
}
export function AddQuantity(id) {
  return {
    type: Types.QUANTITY_CART,
    id
  }
}
export function SubtractQuantity(id) {
  return {
    type: Types.SUBTRACT_CART,
    id
  }
}
export function resetCart() {
  return {
    type: Types.RESET_CART
  }
}
