import React, { useEffect, useRef, useCallback } from 'react'

import { MdArrowBack } from 'react-icons/md'

import { useSelector, useDispatch } from 'react-redux'

import { useHistory } from 'react-router-dom'

import { Form } from '@unform/web'

import * as Yup from 'yup'

import { Button } from '../../../components/Buttons'

import { Input, Radio } from '../../../components/Form'

import Modal from '../../../components/Modal'

import Phases from '../../../components/Phases'

import api from '../../../services/api'

import {
  paymentSelected,
  paymentsData,
  phoneMbway
} from '../../../store/ducks/checkout'

import { phaseThree } from '../../../store/ducks/phases'

import {
  Container,
  ContainerTitleSeccao,
  ContainerOpcoes,
  ContainerButton,
  ContainerModal,
  InputGroup,
  LabelInput
} from './styles'

function Metodos() {
  const formRef = useRef(null)
  const modalRef = useRef(null)
  const history = useHistory()
  const dispatch = useDispatch()

  const { paymentOptionSelected, paymentMethods } = useSelector(
    state => state.checkout
  )

  const loadPayments = useCallback(async () => {
    const response = await api.get('payments')
    dispatch(paymentsData(response.data))
  }, [dispatch])

  useEffect(() => {
    loadPayments()
  }, [loadPayments])

  const RadiosButton = paymentMethods?.map(method => {
    return {
      id: method.id,
      label: method.titulo,
      descricao: method.descricao,
      thumbnail: method.thumbnail
    }
  })

  const handleOpenModal = useCallback(() => {
    modalRef.current.openModal()
  }, [])

  const handleCloseModal = useCallback(() => {
    modalRef.current.closeModal()
    formRef.current.setErrors({})
  }, [])

  const handleFormSubmit = useCallback(
    async metodo => {
      const { metodo: id } = metodo
      const response = await api.get(`payments/${id}`)
      const { metodo: responseMetodo } = response.data

      dispatch(paymentSelected(response.data))

      if (responseMetodo === 'mbway') {
        handleOpenModal()
        return
      }

      dispatch(phaseThree(true))

      history.push('resume')
    },
    [dispatch, handleOpenModal, history]
  )

  const handleFormSubmitMbWay = useCallback(
    async data => {
      const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

      try {
        const schema = Yup.object().shape({
          telefone: Yup.string()
            .matches(phoneRegExp, 'Insira um número de telefone válido')
            .required('Telefone obrigatório')
        })

        await schema.validate(data, {
          abortEarly: false
        })

        formRef.current.setErrors({})

        dispatch(phoneMbway(data.telefone))

        dispatch(phaseThree(true))

        history.push('resume')
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const validationErrors = {}
          if (err instanceof Yup.ValidationError) {
            err.inner.forEach(error => {
              validationErrors[error.path] = error.message
            })
            formRef.current.setErrors(validationErrors)
          }
        }
      }
    },
    [dispatch, history]
  )

  const backPage = useCallback(
    e => {
      e.preventDefault()
      history.goBack()
    },
    [history]
  )

  const ContentModal = () => {
    return (
      <ContainerModal>
        <h1>Insira o número MB WAY</h1>
        <Form ref={formRef} onSubmit={handleFormSubmitMbWay}>
          <InputGroup>
            <LabelInput>Número Telemóvel</LabelInput>
            <Input type="text" name="telefone" />
          </InputGroup>
          <div className="modal_buttons">
            <Button
              width={180}
              type="button"
              onClick={handleCloseModal}
              title="Sair"
            />
            <Button width={180} type="submit" title="Continuar" />
          </div>
        </Form>
      </ContainerModal>
    )
  }

  return (
    <>
      <Container>
        <Phases activeMetodo="metodos" />
        <Form
          ref={formRef}
          initialData={{ metodo: paymentOptionSelected?.id }}
          onSubmit={handleFormSubmit}
        >
          <ContainerOpcoes>
            <ContainerTitleSeccao>
              <span>Métodos de pagamentos:</span>
            </ContainerTitleSeccao>
            <Radio name="metodo" options={RadiosButton} />
          </ContainerOpcoes>
          <ContainerButton>
            <div>
              <Button
                onClick={backPage}
                title="Voltar"
                width={150}
                icon={<MdArrowBack size={20} color="var(--primary)" />}
              />
              <Button type="submit" width={150} title="Continuar" />
            </div>
          </ContainerButton>
        </Form>
      </Container>
      <Modal ref={modalRef} data={<ContentModal />} />
    </>
  )
}

export default Metodos
