import React from 'react'

import { useHistory } from 'react-router-dom'

import concertinaImage from '../../assets/concertina.png'

import { Button } from '../../components/Buttons'

import {
  Container,
  ContainerContent,
  ContainerNotFoundImage,
  ContainerNotFoundContent
} from './styles'

function NotFound() {
  const history = useHistory()

  return (
    <Container>
      <ContainerContent>
        <ContainerNotFoundImage>
          <img src={concertinaImage} alt="" />
        </ContainerNotFoundImage>
        <ContainerNotFoundContent>
          <h1>404...</h1>
          <h2>Repito, 404. Câmbio!</h2>
          <span>Meu Fole responde:</span>
          <p>
            <span>A página que requisitou não foi encontrada.</span>
          </p>
          <Button
            onClick={() => history.push('/dashboard')}
            title="Voltar a homepage"
            margin="40px 0px 0px 0px"
            transform="true"
            background="var(--orange)"
            color="var(--primary)"
          />
        </ContainerNotFoundContent>
      </ContainerContent>
    </Container>
  )
}

export default NotFound
