import React, { useCallback, useRef, useEffect } from 'react'

import { MdArrowBack } from 'react-icons/md'

import ReactLoading from 'react-loading'

import { useDispatch, useSelector } from 'react-redux'

import { toast } from 'react-toastify'

import Billings from '../../../components/Billings'

import { Button } from '../../../components/Buttons'

import Cart from '../../../components/Cart'

import Modal from '../../../components/Modal'

import Phases from '../../../components/Phases'

import history from '../../../routes/history'

import {
  paymentDataRequest,
  paymentSelectedResquest
} from '../../../store/ducks/checkout'

import { requestCompras } from '../../../store/ducks/compras'

import { phaseThree } from '../../../store/ducks/phases'

import {
  Container,
  ContainerButton,
  Grid,
  CondicoesCompra,
  PaymentSelected,
  PaymentSelectedTitle,
  ContainerModal
} from './styles'

function Resumo() {
  const termsRef = useRef(null)
  const modalRef = useRef(null)
  const dispatch = useDispatch()

  const { paymentOptionSelected, loading } = useSelector(
    state => state.checkout
  )

  const getCompras = useCallback(() => {
    dispatch(requestCompras())
  }, [dispatch])

  useEffect(() => {
    getCompras()
  }, [getCompras])

  const handleCheckoutClose = useCallback(() => {
    const { checked } = termsRef.current

    if (!checked) {
      toast.error('Tem de aceitar as condições de compra', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    }

    dispatch(paymentSelectedResquest(paymentOptionSelected))
    dispatch(paymentDataRequest())
  }, [dispatch, paymentOptionSelected])

  const handleOpenModal = useCallback(() => {
    modalRef.current.openModal()
  }, [])

  const handleCloseModal = useCallback(() => {
    modalRef.current.closeModal()
  }, [])

  const handleAcceptTerms = useCallback(() => {
    termsRef.current.checked = !modalRef.current.checked
    handleCloseModal()
  }, [handleCloseModal])

  const backPage = useCallback(() => {
    dispatch(phaseThree(false))
    history.goBack()
  }, [dispatch])

  const ContentModal = useCallback(() => {
    return (
      <ContainerModal>
        <h1>Condições de compra</h1>

        <div className="condicoes">
          Lorem Ipsum é simplesmente uma simulação de texto da indústria
          tipográfica e de impressos, e vem sendo utilizado desde o século XVI,
          quando um impressor desconhecido pegou uma bandeja de tipos e os
          embaralhou para fazer um livro de modelos de tipos. Lorem Ipsum
          sobreviveu não só a cinco séculos, como também ao salto para a
          editoração eletrônica, permanecendo essencialmente inalterado. Se
          popularizou na década de 60, quando a Letraset lançou decalques
          contendo passagens de Lorem Ipsum, e mais recentemente quando passou a
          ser integrado a softwares de editoração eletrônica como Aldus
          PageMaker.
        </div>

        <div className="modal_buttons">
          <Button
            width={180}
            type="button"
            onClick={handleCloseModal}
            title="Sair"
          />
          <Button
            width={180}
            type="button"
            onClick={handleAcceptTerms}
            title="Aceitar"
          />
        </div>
      </ContainerModal>
    )
  }, [handleAcceptTerms, handleCloseModal])

  return (
    <>
      <Container>
        <Phases activeMetodo="resumo" />
        <Cart shadow={true} quantity={false} delete={false} />
        <Grid>
          <Billings
            margin={false}
            background="var(--gray-light)"
            color="var(--primary)"
          />
          <PaymentSelected>
            <PaymentSelectedTitle>Forma de pagamento:</PaymentSelectedTitle>
            <ul>
              <li>{paymentOptionSelected.titulo}</li>
              <li>{paymentOptionSelected.descricao}</li>
            </ul>
          </PaymentSelected>
        </Grid>

        <CondicoesCompra>
          <label htmlFor="">
            Li e aceito as{' '}
            <span onClick={handleOpenModal}>condições de compra.</span>
          </label>
          <input
            ref={termsRef}
            type="checkbox"
            id="condicoes_compra"
            name="condicoes_compra"
          />
        </CondicoesCompra>

        <ContainerButton>
          <div>
            <Button
              onClick={backPage}
              title="Voltar"
              width={150}
              icon={<MdArrowBack size={20} color="var(--primary)" />}
            />
            <Button
              onClick={handleCheckoutClose}
              type="submit"
              width={150}
              title="Finalizar"
              loading={
                loading && (
                  <ReactLoading
                    type="spin"
                    color="#fff"
                    height={20}
                    width={20}
                  />
                )
              }
            />
          </div>
        </ContainerButton>
      </Container>
      <Modal ref={modalRef} data={<ContentModal />} />
    </>
  )
}

export default Resumo
