import { combineReducers } from 'redux'

import auth from '../ducks/auth'

import cart from '../ducks/cart'

import checkout from '../ducks/checkout'

import compras from '../ducks/compras'

import notifications from '../ducks/notifications'

import packs from '../ducks/packs'

import payments from '../ducks/payments'

import phases from '../ducks/phases'

import produto from '../ducks/produto'

import produtos from '../ducks/produtos'

export default combineReducers({
  auth,
  compras,
  cart,
  checkout,
  packs,
  produtos,
  produto,
  payments,
  notifications,
  phases
})
