import styled from 'styled-components'

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  thead th {
    color: var(--dark-text-color);
    font-size: 15px;
    font-weight: 400;
    text-align: left;
    padding-left: 30px;
    padding-bottom: 20px;
  }
  tbody {
    background-color: var(--box-background);
    box-shadow: 0 0 0 1px var(--box-background);
    border-radius: 5px;
  }
  tbody tr td {
    padding: 30px 0 0 30px;
  }
  tbody tr:last-child td {
    padding-bottom: 30px;
  }
  @media (max-width: 768px) {
    thead {
      display: none;
    }
    tbody tr {
      display: flex;
      flex-wrap: wrap;
    }

    tbody tr td {
      width: 100%;
      max-width: 100%;
      padding: 30px 0 0 30px;
    }
  }
`
export const CartEmpty = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
  img {
    width: 100%;
    height: 100%;
    max-width: 200px;
    max-height: 200px;
  }
  span {
    color: var(--primary);
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    margin: 15px 0 0 40px;
  }
  @media (max-width: 825px) {
    padding-left: 0;
  }
`
export const Produto = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 135px;
    height: 135px;
    border-radius: 5px;
    object-fit: contain;
  }
  h1 {
    color: var(--primary);
    font-size: 18px;
    font-weight: 400;
    margin-left: 15px;
  }
`
export const Preco = styled.div`
  color: var(--primary);
  font-size: 16px;
  font-weight: 400;
`
export const Quantidade = styled.div`
  span {
    height: 40px;
    padding: 10px 20px;
    background: var(--primary);
    cursor: pointer;
    display: inline-block;
    user-select: none;
    -webkit-transition: 0.1s;
    transition: 0.1s;
    border-radius: 5px;
  }
  p {
    width: 60px;
    height: 40px;
    display: inline-block;
    padding: 10px;
    outline: none;
    margin: 0;
    text-align: center;
    vertical-align: top;
    color: var(--primary);
    font-weight: 400;
    font-size: 14px;
  }
`
export const TotalPreco = styled.div`
  color: var(--primary);
  font-size: 16px;
  font-weight: 400;
`
export const DeleteProduto = styled.div`
  color: var(--primary);
  font-size: 16px;
  font-weight: 400;
  margin-right: 30px;
  cursor: pointer;
`
export const TotalCart = styled.div`
  height: 100%;
  background-color: var(--box-background);
  margin-top: 20px;
  border-radius: 5px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  padding: 20px 50px 20px 20px;
  margin-bottom: 20px;
`
export const ResumeTotalCart = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  &:last-child {
    padding-bottom: 0;
  }
`
export const Total = styled.h1`
  color: var(--primary);
  font-size: 16px;
  font-weight: 400;
  padding-right: 50px;
`
export const TotalPrice = styled.div`
  span {
    color: var(--primary);
    font-size: 18px;
    font-weight: 400;
  }
`
