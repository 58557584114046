import React, { useRef, useState } from 'react'

import ReactLoading from 'react-loading'

import { Link } from 'react-router-dom'

import { toast } from 'react-toastify'

import { Form } from '@unform/web'

import * as Yup from 'yup'

import FundoLogin from '../../assets/fundo_login.jpg'

import Button from '../../components/Buttons/Button'

import { Input } from '../../components/Form'

// import SliderShow from '../../components/SliderShow'

import api from '../../services/api'

import {
  Container,
  ContainerForm,
  ContainerRecuperar,
  RecuperarContainer,
  SliderLogin
} from './styles'

export default function Recuperar() {
  const formRef = useRef(null)
  const [loading, setLoading] = useState(false)

  async function handleResetPassword(data, { reset }) {
    setLoading(true)
    try {
      const schema = Yup.object().shape({
        email: Yup.string()
          .email('Email inválido')
          .trim()
          .required('Email obrigatório')
      })

      await schema.validate(data, {
        abortEarly: false
      })

      formRef.current.setErrors({})

      const { email: user_email } = data

      const email = user_email.trim()

      const response = await api.post('/forgot-password', { email })

      setLoading(false)

      if (response.data) {
        toast.success('Recuperação enviada por email!', {
          position: toast.POSITION.TOP_RIGHT
        })
      }

      reset()
    } catch (err) {
      setLoading(false)

      if (err.response) {
        toast.error(err.response.data.message.error, {
          position: toast.POSITION.TOP_RIGHT
        })
      }

      if (err instanceof Yup.ValidationError) {
        const validationErrors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
          setLoading(false)
        }
      }
    }
  }

  return (
    <Container>
      <RecuperarContainer>
        <h1>Recuperar</h1>
        <ContainerForm>
          <Form ref={formRef} onSubmit={handleResetPassword}>
            <div className="form-group">
              <Input
                autocomplete="off"
                autocorrect="off"
                autocapitalize="none"
                type="text"
                name="email"
                placeholder="Email"
                spellcheck="false"
              />
            </div>

            <Button
              type="submit"
              transform="true"
              title="Recuperar"
              loading={
                loading && (
                  <ReactLoading
                    type="spin"
                    color="#fff"
                    height={20}
                    width={20}
                  />
                )
              }
            />

            <ContainerRecuperar>
              <Link className="entrar_link" to="/">
                Entrar na conta
              </Link>
            </ContainerRecuperar>
          </Form>
        </ContainerForm>
      </RecuperarContainer>
      <SliderLogin src={FundoLogin} />
    </Container>
  )
}
