import styled from 'styled-components'

export const InputGroup = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  /* padding-left: 30px; */
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: var(--hover-gray);
    top: 100%;
    left: 0;
    margin-top: 25px;
  }
  &:last-child:before {
    display: none;
  }
  &:last-child {
    margin-bottom: 0;
  }
  span {
    display: inline-block;
    color: #fa2b56;
    font-size: 13px;
    font-weight: 500;
    padding-top: 7px;
  }
  input {
    width: 25px;
    height: 25px;
    background-color: #fa2b56;
    border-radius: 5px;
    color: #fff;
    padding-left: 15px;
    font-weight: 400;
    font-size: 14px;
    flex-shrink: 0;
  }
  @media (max-width: 825px) {
    flex-direction: row;
    align-items: start;
  }
`
export const ContainerIconPayment = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ImageMetodo = styled.div`
  width: 100px;
  margin-left: 25px;
  flex-shrink: 0;
  background-color: var(--primary);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 20px 20px;
  margin-right: 20px;
  img {
    width: 100%;
    height: 100%;
    max-height: 60px;
    max-width: 60px;
  }
  @media (max-width: 825px) {
    background-color: unset;
    width: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    img {
      display: none;
    }
  }
`
export const Descricao = styled.div`
  flex-direction: column;
  p {
    color: var(--dark-text-color);
    font-size: 14px;
    font-weight: 400;
    text-align: justify;
  }
  @media (max-width: 825px) {
    margin-left: 15px;
  }
`
export const Titulo = styled.h1`
  color: var(--primary);
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 15px;
`
