import styled, { keyframes } from 'styled-components'

const MoveUpDown = keyframes`
  0% { transform: translateY(0); }
  100% { transform: translateY(-15px); }
`

export const Container = styled.div`
  background-color: var(--black);
`
export const ContainerTitle = styled.div`
  justify-content: start;
  align-items: start;
  margin: 50px 0 10px;
  h1 {
    color: var(--primary);
    font-size: 48px;
    font-weight: 300;
  }
  @media (max-width: 825px) {
    h1 {
      font-size: 30px;
    }
  }
`
export const ContainerDetalheVideo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`
export const ContainerVideo = styled.div`
  flex: 1;
  position: relative;
  margin: 0 0 20px 0;
  @media (max-width: 825px) {
    width: 100%;
    margin: 0;
  }
  @media (max-width: 825px) {
    margin: 0 0 20px 0;
  }
`
export const VideoPlayer = styled.div`
  width: 100%;
  min-width: 0px;
  padding: 0 0 56.25% 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    margin: 20px 0 40px 0;
  }
`
export const DescricaoVideo = styled.div`
  width: 100%;
  > div.descricao_button {
    display: flex;
    border-bottom: 1px solid var(--hover-gray);
    > button {
      position: relative;
      padding-bottom: 16px;
      background: transparent;
      border: 0px;
      font-size: 16px;
      font-weight: 700;
      color: var(--primary);

      &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0px;
        width: 100%;
        height: 2px;
        background: var(--orange);
        opacity: 1;
        transition: opacity 0.2s ease 0s;
      }
    }
  }
  @media (max-width: 768px) {
    > div.descricao_button {
      flex-direction: column;
      border-bottom: 0px;
      border-left: 1px solid var(--hover-gray);
      > button {
        padding: 4px 0px 4px 16px;
        font-size: 15px;
        text-align: left;

        &::after {
          left: -1px;
          bottom: 0px;
          width: 2px;
          height: 100%;
        }
      }
    }
  }
`
export const DescricaoContainer = styled.div`
  margin-top: 32px;

  .sub_container {
    display: flex;
    align-items: flex-start;
  }
  .container_content {
    flex: 1 1 0%;
    width: 100%;
    min-width: 0px;
  }
  .content {
    flex: 1 1 0%;
    text-align: center;
  }

  .content > svg {
    margin: 0px auto 24px;
  }

  .content > span {
    display: block;
    color: var(--dark-text-color);
    font-size: 16px;
    font-weight: 300;
  }
  .content > span.active {
    text-align: left;
  }

  @media (max-width: 768px) {
    margin-top: 24px;
    .sub_container {
      display: block;
    }
  }
`
export const ContainerFilterVideo = styled.div`
  width: 100%;
  max-width: 50%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
  @media (max-width: 825px) {
    max-width: 100%;
  }
`
export const LoadingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  > div {
    -webkit-animation: ${MoveUpDown} 1s infinite alternate;
    animation: ${MoveUpDown} 1s infinite alternate;
  }
`
