export const Types = {
  PHASE_ONE: '@phases/PHASE_ONE',
  PHASE_TWO: '@phases/PHASE_TWO',
  PHASE_THREE: '@phases/PHASE_THREE',
  PHASE_FOUR: '@phases/PHASE_FOUR',
  PHASE_FIVE: '@phases/PHASE_FIVE'
}

const INITIAL_STATE = {
  phaseOne: true,
  phaseTwo: false,
  phaseThree: false,
  phaseFour: false,
  phaseFive: false
}

export default function phases(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.PHASE_ONE:
      return { ...state, phaseOne: action.phaseOne }
    case Types.PHASE_TWO:
      return { ...state, phaseTwo: action.phaseTwo }
    case Types.PHASE_THREE:
      return { ...state, phaseThree: action.phaseThree }
    case Types.PHASE_FOUR:
      return { ...state, phaseFour: action.phaseFour }
    case Types.PHASE_FIVE:
      return { ...state, phaseFive: action.phaseFive }
    default:
      return state
  }
}

export function phaseOne(phaseOne) {
  return {
    type: Types.PHASE_ONE,
    phaseOne
  }
}
export function phaseTwo(phaseTwo) {
  return {
    type: Types.PHASE_TWO,
    phaseTwo
  }
}
export function phaseThree(phaseThree) {
  return {
    type: Types.PHASE_THREE,
    phaseThree
  }
}
export function phaseFour(phaseFour) {
  return {
    type: Types.PHASE_FOUR,
    phaseFour
  }
}

export function phaseFive(phaseFive) {
  return {
    type: Types.PHASE_FIVE,
    phaseFive
  }
}
