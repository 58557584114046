import styled from 'styled-components'

export const Container = styled.div`
  background-color: var(--black);
`
export const ContainerTitleSeccao = styled.div`
  justify-content: start;
  align-items: start;
  margin-bottom: 30px;
  span {
    color: var(--primary);
    font-size: 20px;
    font-weight: 300;
  }
`
export const ContainerOpcoes = styled.div`
  background-color: var(--box-background);
  border-radius: 4px;
  padding: 30px;
  @media (max-width: 825px) {
    padding: 30px 15px 30px 15px;
  }
`
export const ContainerFormPerfil = styled.div`
  padding: 30px 0;
  @media (max-width: 825px) {
    margin: 0;
    form {
      margin-top: 50px;
    }
  }
`
export const InputGroup = styled.div`
  margin-bottom: 20px;
  position: relative;
  span {
    display: inline-block;
    color: var(--red);
    font-size: 13px;
    font-weight: 500;
    padding-top: 7px;
  }
  input {
    width: 100%;
    height: 50px;
    background-color: var(--black);
    border-radius: 5px;
    color: var(--primary);
    padding-left: 15px;
    font-weight: 400;
    font-size: 16px;
    &::placeholder {
      color: var(--primary);
    }
  }
`
export const LabelInput = styled.div`
  color: var(--dark-text-color);
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 5px;
`
export const ContainerOtherForm = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1154px) {
    flex-direction: column;
  }
`
export const InputGroupOther = styled(InputGroup)`
  width: 49%;
  @media (max-width: 1154px) {
    width: 100%;
  }
`
export const ContainerButton = styled.div`
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
`
export const ContainerButtonSaveBilling = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`
