import React from 'react'

import CookieBot from 'react-cookiebot/lib/CookieBot'

import { Provider } from 'react-redux'

import { Router } from 'react-router-dom'

import { ToastContainer } from 'react-toastify'

import * as Sentry from '@sentry/react'

import { PersistGate } from 'redux-persist/integration/react'

import Routes from './routes'

import history from './routes/history'

import { store, persistor } from './store'

import GlobalStyle from './styles/global'

const domainGroupId = '05b06929-918a-4121-b355-6ca6a22735e6'

function App() {
  // const [hasCookieBot, setHasCookieBot] = useState(undefined)

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={history}>
            <Routes />
            <GlobalStyle />
            <ToastContainer autoClose={3000} />
            <CookieBot domainGroupId={domainGroupId} />
          </Router>
        </PersistGate>
      </Provider>
    </>
  )
}

export default Sentry.withProfiler(App)
