import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { useParams } from 'react-router-dom'

import { parseISO } from 'date-fns'

import { Button } from '../../components/Buttons'

import Modal from '../../components/Modal'

import Pagination from '../../components/Pagination'

import LoadingCompras from '../../components/Shimmer/LoadingCompras'

import { Multibanco, Transfer } from '../../components/Terminals'

import history from '../../routes/history'

import api from '../../services/api'

import { requestCompras, comprasCurrentPage } from '../../store/ducks/compras'

import {
  Container,
  ContainerTitle,
  ContainerFormPerfil,
  Table,
  ContainerModal,
  ComprovativoContainer
} from './styles'

export default function Compras() {
  const [loadingSendComprovativo, setLoadingSendComprovativo] = useState(false)
  const [modalData, setModalData] = useState(null)
  const modalRef = useRef(null)
  const scrollRef = useRef(null)
  const dispatch = useDispatch()
  const { page } = useParams()

  const {
    data: compras,
    totalPaginas,
    currentPage,
    videosPerPage,
    videosLastPage,
    loading
  } = useSelector(state => state.compras)

  const getCompras = useCallback(() => {
    dispatch(requestCompras())
  }, [dispatch])

  const pageCurrent = useCallback(() => {
    if (page) {
      dispatch(comprasCurrentPage(page))
    }
  }, [dispatch, page])

  useEffect(() => {
    if (pageCurrent) {
      pageCurrent()
    }
  }, [dispatch, pageCurrent])

  useEffect(() => {
    return () => {
      dispatch(comprasCurrentPage(1))
    }
  }, [dispatch, page])

  useLayoutEffect(() => {
    if (page) return
    getCompras()
  }, [dispatch, getCompras, page])

  const handleOpenModal = useCallback(() => {
    modalRef.current.openModal()
  }, [])

  const handleCloseModal = useCallback(() => {
    modalRef.current.closeModal()
  }, [])

  const handlePaymentDetails = useCallback(
    compra => {
      setModalData(compra)
      handleOpenModal()
    },
    [handleOpenModal]
  )

  const handleUploadComprovativo = useCallback(
    async file => {
      const { id, total } = modalData

      setLoadingSendComprovativo(true)

      const dataComprovativo = new FormData()

      dataComprovativo.append('comprovativo', file[0])
      dataComprovativo.append('compra_id', id)
      dataComprovativo.append('total', total)

      await api.post('comprovativo', dataComprovativo)

      setLoadingSendComprovativo(false)
    },
    [modalData]
  )

  const handleCompraView = useCallback(item => {
    if (item.produto.professor) {
      history.push(`/curso/${item.produto.slug}`)
    } else {
      history.push(`/video/${item.produto.slug}`)
    }
  }, [])

  const contentModal = useMemo(() => {
    if (!modalData) return

    const { entidade, referencia, total, status } = modalData

    const payment = {
      entidade: entidade,
      referencia: referencia,
      valor: total
    }
    return (
      <ContainerModal>
        <h1>Dados de pagamento</h1>

        <div className="data_payment">
          {modalData?.payment.metodo === 'multibanco' ? (
            <Multibanco data={payment} />
          ) : (
            <Transfer data={payment}>
              {!status && (
                <ComprovativoContainer>
                  <div>Enviar comprovativo de pagamento</div>
                  {loadingSendComprovativo ? (
                    <div>A enviar...</div>
                  ) : (
                    <input
                      type="file"
                      name="comprovativo"
                      onChange={e => handleUploadComprovativo(e.target.files)}
                      accept="image/*"
                    />
                  )}
                </ComprovativoContainer>
              )}
            </Transfer>
          )}
        </div>

        <div className="modal_buttons">
          <Button
            width={180}
            type="button"
            onClick={handleCloseModal}
            title="Sair"
          />
        </div>
      </ContainerModal>
    )
  }, [
    handleCloseModal,
    handleUploadComprovativo,
    loadingSendComprovativo,
    modalData
  ])

  const scrollToRef = useCallback(() => {
    if (scrollRef.current) {
      window.scrollTo({
        top: 0
      })
    }
  }, [])

  const handleclickNumberPaginate = useCallback(
    number => {
      dispatch(comprasCurrentPage(number))
      history.push(`/account/compras/${number}`)
      scrollToRef()
    },
    [dispatch, scrollToRef]
  )

  const handleNextPage = useCallback(() => {
    history.push(`/account/compras/${currentPage + 1}`)
    scrollToRef()
  }, [currentPage, scrollToRef])

  const handleBackPage = useCallback(() => {
    history.push(`/account/compras/${currentPage - 1}`)
    scrollToRef()
  }, [currentPage, scrollToRef])

  if (loading) {
    return <LoadingCompras />
  }

  return (
    <>
      <Container>
        <ContainerTitle ref={scrollRef}>
          <h1>Compras</h1>
        </ContainerTitle>

        <ContainerFormPerfil>
          <Table>
            <thead>
              <tr>
                <th>Nº Pedido</th>
                <th>Produto</th>
                <th>Data</th>
                <th>Valor</th>
                <th>Forma de Pagamento</th>
                <th>Dados Pagamento</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {compras.map(compra => (
                <tr
                  className={`${compra.status === 0 && 'active'}`}
                  key={compra.id}
                >
                  <td data-label="Nº Pedido">
                    <div>{compra.id}</div>
                  </td>

                  <td data-label="Produto">
                    {compra.item.map(item => (
                      <div
                        onClick={
                          compra.status === 1
                            ? () => handleCompraView(item)
                            : null
                        }
                        key={item.produto.id}
                        className="produto_link"
                      >
                        {item?.modulo
                          ? `${item.produto.titulo} - ${item?.modulo?.nome}`
                          : `${item.produto.titulo}`}
                      </div>
                    ))}
                  </td>
                  <td data-label="Data">
                    {Intl.DateTimeFormat('pt-PT', {
                      year: 'numeric',
                      month: 'long',
                      day: '2-digit'
                    }).format(parseISO(compra.created_at))}
                  </td>
                  <td data-label="Valor">
                    {Intl.NumberFormat('pt-PT', {
                      style: 'currency',
                      currency: 'EUR'
                    }).format(compra.total)}
                  </td>
                  <td data-label="Forma de Pagamento">
                    <div>{compra.payment.titulo}</div>
                  </td>

                  <td data-label="Dados Pagamento">
                    {compra.payment.metodo === 'mbway' ? (
                      <span className="no-content">Sem dados</span>
                    ) : (
                      <button
                        onClick={() => handlePaymentDetails(compra)}
                        type="button"
                      >
                        Ver dados
                      </button>
                    )}
                  </td>

                  <td data-label="Status">
                    <>{compra.status ? 'Pago' : 'Pendente'}</>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ContainerFormPerfil>
        <Pagination
          currentPage={currentPage}
          perPageVideos={videosPerPage}
          totalPages={totalPaginas}
          lastPage={videosLastPage}
          handleclickNumberPaginate={handleclickNumberPaginate}
          handleNextPage={handleNextPage}
          handleBackPage={handleBackPage}
        />
      </Container>
      <Modal ref={modalRef} data={contentModal} />
    </>
  )
}
