import styled from 'styled-components'

export const Notification = styled.div`
  margin: 20px 0 20px 0;
  cursor: pointer;

  background: rgb(74, 74, 77, 0.5);
  border-radius: 5px;

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`
export const ContainerUserNotificacao = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 20px 20px 20px;

  .notification_data {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    position: relative;
  }

  .notification_data > span:nth-child(1) {
    width: 100%;
    max-width: 50px;
    margin-bottom: 10px;
  }
  .notification_data > span:nth-child(2) {
    width: 200px;
    margin-top: 10px;
  }

  .descricao {
    width: 100%;
    margin-top: 10px;
  }

  .descricao > span:nth-child(1),
  .descricao > span:nth-child(2),
  .descricao > span:nth-child(3) {
    margin-top: 10px;
  }
  @media (max-width: 825px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .notification_data {
      align-items: center;
    }
  }
`
export const Avatar = styled.div`
  width: 100%;
  max-width: 50px;
  height: 50px;
  border-radius: 50px;
  margin-right: 20px;

  span:nth-child(1) {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }

  @media (max-width: 825px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`
