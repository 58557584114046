import React from 'react'

import { useSelector } from 'react-redux'

import { Route, Redirect } from 'react-router-dom'

import Header from '../components/Header'

import Wrapper from '../components/Wrapper'

import { getToken } from '../services/auth'

const RouteWrapper = ({ component: Component, isPrivate, ...rest }) => {
  const { data: user } = useSelector(state => state.auth)

  const isAuthenticated = getToken() || user

  if (!isAuthenticated && isPrivate) {
    return <Redirect to="/" />
  }

  if (isAuthenticated && !isPrivate) {
    return <Redirect to="/dashboard" />
  }

  return (
    <Route
      {...rest}
      render={props => {
        return isAuthenticated ? (
          <>
            <Wrapper>
              <Header color="var(--primary)" />
              <Component {...props} />
            </Wrapper>
          </>
        ) : (
          <Component {...props} />
        )
      }}
    />
  )
}

export default RouteWrapper
