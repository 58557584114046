import styled from 'styled-components'

export const Container = styled.div`
  background-color: var(--black);
`
export const ContainerOpcoes = styled.div`
  background-color: var(--box-background);
  border-radius: 5px;
  padding: 30px 0 30px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  > div {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > p {
    width: 70%;
    color: var(--primary);
    font-weight: 400;
    font-size: 15px;
    margin: 20px;
    a {
      text-decoration: none;
      color: var(--orange);
    }
  }

  > span {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  @media (max-width: 825px) {
    > p {
      width: 100%;
      padding: 0 20px;
    }
  }
`
export const ContainerButton = styled.div`
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
`
