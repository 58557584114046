import styled, { css } from 'styled-components'

export const Container = styled.div`
  background-color: var(--black);
`
export const ContainerTitle = styled.div`
  margin-top: 8px;
  font-size: 12px;
  color: rgb(255, 255, 255);
`
export const ContainerTitlePage = styled.div`
  justify-content: start;
  align-items: start;
  margin: 50px 0 50px;
  h1 {
    color: var(--primary);
    font-size: 48px;
    font-weight: 300;
    margin-bottom: 15px;
  }
  span {
    color: var(--primary);
    font-size: 17px;
    font-weight: 300;
  }
  @media (max-width: 825px) {
    h1 {
      font-size: 35px;
    }
  }
`
export const ContainerPhases = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const ContainerOptions = styled.ul`
  width: 100%;
  max-width: 900px;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  margin: 50px 0;
  @media (max-width: 768px) {
    margin: 25px 0 35px;
  }
`

export const ContainerOption = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &:first-child::before {
    display: none;
  }

  &::before {
    content: '';
    position: absolute;
    top: 32px;
    right: 50%;
    width: calc(100% + 140px);
    height: 2px;

    background: ${props =>
      props.active ? css`var(--orange)` : css`var(--box-background)`};

    @media (max-width: 871px) {
      width: calc(100% + 80px);
    }

    @media (max-width: 609px) {
      width: calc(100% + 60px);
    }

    @media (max-width: 540px) {
      top: 24px;
      width: calc(100% + 20px);
    }
  }

  &::before {
    ${props =>
      props.active &&
      css`
        background: var(--orange);
      `};
  }
`
export const ContainerIcon = styled.i`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border: 2px solid var(--box-background);

  ${props =>
    props.active &&
    css`
      border-color: var(--orange);
    `};

  background-color: ${props =>
    props.active ? css`rgb(17, 37, 28)` : css`var(--box-background)`};

  border-radius: 50%;
  position: relative;
  z-index: 2;

  > svg {
    opacity: 1;
    color: #fff;
  }

  @media (max-width: 540px) {
    width: 45px;
    height: 45px;

    > svg {
      width: 20px;
    }
  }
`
