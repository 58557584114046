import styled from 'styled-components'

export const Container = styled.div``
export const ContainerTitle = styled.div`
  justify-content: start;
  align-items: start;
  margin: 50px 0 50px;

  span {
    display: flex;
  }

  > div {
    span:nth-child(1) {
      display: none;
    }
    span:nth-child(2) {
      width: 200px;
    }
    span:nth-child(3) {
      display: none;
    }
    span:nth-child(4) {
      display: none;
    }
    span:nth-child(5) {
      display: none;
    }
  }

  @media (max-width: 768px) {
    margin: 50px 0 30px;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span:nth-child(2) {
        display: block;
        width: 50%;
        margin-bottom: 15px;
      }
      span:nth-child(3) {
        display: block;
        width: 80%;
      }
      span:nth-child(4) {
        display: block;
        width: 80%;
      }
      span:nth-child(5) {
        display: block;
        width: 80%;
      }
    }
  }
`
export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 24px;
  justify-content: center;

  /* display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  @media (max-width: 1000px) {
    grid-template-rows: repeat(1);
    grid-template-columns: repeat(2, 2fr);
  }
  @media (max-width: 750px) {
    grid-template-rows: repeat(1);
    grid-template-columns: repeat(1, 1fr);
  } */
`
export const Item = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  span:nth-child(1) {
    margin: 20px 0;
  }
  span:nth-child(2) {
    margin: 20px 0;
  }
  span:nth-child(6) {
    margin: 20px 0;
  }
`
