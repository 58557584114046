import styled from 'styled-components'

export const Container = styled.div`
  background-color: var(--black);
`
export const Sidebar = styled.div`
  display: flex;
  max-width: 1336px;
  padding-top: 71px;
  flex: 1 1 0%;
  @media (max-width: 768px) {
    display: block;
    padding: 0;
    margin-top: 50px;
  }
`
export const Content = styled.div`
  position: sticky;
  top: 71px;
  width: 325px;
  height: calc(100vh - 80px);
  img {
    width: 100%;
    height: 100%;
    max-height: 100px;
    max-width: 100px;
    flex-shrink: 0;
    margin-bottom: 30px;
  }
  span {
    color: var(--primary);
    line-height: 1.5;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    > div {
      color: var(--orange);
      font-size: 16px;
      cursor: pointer;
    }
  }
  @media (max-width: 1130px) {
    width: 240px;
    padding-left: 0px;
    top: 50px;
  }
  @media (max-width: 768px) {
    top: auto;
    width: 100%;
    height: auto;
    padding-left: 0px;
    text-align: center;
    margin-bottom: 35px;
    img {
      margin-bottom: 15px;
    }
    span {
      justify-content: center;
    }
  }
`
export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 0%;
`
export const LinkContent = styled.a`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 699px;
  cursor: pointer;
`
export const ContentCurso = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 699px;
  border-radius: 5px;
  margin-bottom: 50px;
  height: 380px;
  position: relative;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  transition: all 0.2s ease 0s;
  border: 2px solid var(--box-background);
  h3 {
    color: rgb(255, 255, 255);
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
  }
  &:hover {
    border-color: var(--orange);
    transform: translateY(-5px);
  }
  @media (max-width: 1130px) {
    height: auto;
    min-height: 340px;
    padding: 30px;
    width: 100%;
    max-width: 699px;
    margin-bottom: 80px;
    h3 {
      font-size: 18px;
      margin-bottom: 16px;
    }
  }
  @media (max-width: 768px) {
    height: auto;
    /* min-height: auto; */
    padding: 25px 25px 30px;
    margin-bottom: 20px;
  }
`
export const Layer = styled.div`
  background-color: rgb(18, 18, 20, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`
export const ContainerDadosCurso = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  > div {
    display: flex;
    align-items: center;

    svg {
      position: relative;
      margin-right: 11px;
      fill: var(--orange);
    }
  }

  span {
    color: var(--primary);
    font-size: 14px;
    font-weight: 700;
  }

  .teste2 {
    margin: 0px 0px 0px 7px;
  }

  .teste1 {
    margin: 0px 7px;
  }

  @media (max-width: 1130px) {
    /* margin-bottom: 40px; */
  }

  @media (max-width: 825px) {
    width: 100%;
    height: auto;
  }
`
