import styled from 'styled-components'

export const Container = styled.div`
  background-color: var(--box-background);
  border-radius: 5px;
  padding: 32px;
  @media (max-width: 825px) {
    padding: 32px 15px 32px 15px;
  }
`

export const ContainerFormPerfil = styled.div`
  @media (max-width: 825px) {
    margin: 0;
  }
`
export const InputGroup = styled.div`
  input {
    width: 100%;
    height: 50px;
    background-color: var(--black);
    border-radius: 5px;
    color: var(--primary);
    padding-left: 15px;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 20px;
  }
`
export const LabelInput = styled.div`
  color: var(--dark-text-color);
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 5px;
`
export const ContainerOtherForm = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1154px) {
    flex-direction: column;
  }
`
export const InputGroupOther = styled(InputGroup)`
  width: 49%;
  @media (max-width: 1154px) {
    width: 100%;
  }
`
export const ContainerTitle = styled.div`
  margin: 0 0 20px 0;
  h1 {
    color: var(--primary);
    font-size: 28px;
    font-weight: 300;
  }
`
