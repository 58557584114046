import styled from 'styled-components'

export const Container = styled.div`
  padding: 75px 30px 30px 30px;
  @media (max-width: 1120px) {
    padding: 75px 30px 30px 30px;
  }
  @media (max-width: 768px) {
    padding: 75px 20px 20px 20px;
  }
`
