import React, { useEffect, useRef, useCallback } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { useHistory } from 'react-router-dom'

import { toast } from 'react-toastify'

import { Form } from '@unform/web'

import * as Yup from 'yup'

import { Button } from '../../../components/Buttons'

import { Input } from '../../../components/Form'

import Phases from '../../../components/Phases'

import api from '../../../services/api'

import { phaseOne } from '../../../store/ducks/phases'

import {
  Container,
  ContainerTitleSeccao,
  ContainerOpcoes,
  ContainerFormPerfil,
  InputGroup,
  LabelInput,
  ContainerOtherForm,
  InputGroupOther,
  ContainerButton
} from './styles'

function Conta() {
  const formRef = useRef(null)
  const history = useHistory()
  const dispatch = useDispatch()
  const { data: user } = useSelector(state => state.auth)

  const setFormDataUser = useCallback(async () => {
    formRef.current.setData(user)
  }, [user])

  useEffect(() => {
    setFormDataUser()
  }, [setFormDataUser])

  const handleNextPayament = useCallback(() => {
    dispatch(phaseOne(true))
    history.push('billing')
  }, [history, dispatch])

  const handleUpdateIdentificacao = useCallback(
    async data => {
      try {
        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .email('Email inválido')
            .required('Email obrigatório'),
          nif: Yup.string()
            .test('nif', 'NIF inválido', function (nif) {
              const nifValidate = typeof nif === 'string' ? nif : nif.toString()
              const validationSets = {
                one: ['1', '2', '3', '5', '6', '8'],
                two: [
                  '45',
                  '70',
                  '71',
                  '72',
                  '74',
                  '75',
                  '77',
                  '79',
                  '90',
                  '91',
                  '98',
                  '99'
                ]
              }

              if (nifValidate.length !== 9) {
                return false
              }

              if (
                !validationSets.one.includes(nifValidate.substr(0, 1)) &&
                !validationSets.two.includes(nifValidate.substr(0, 2))
              ) {
                return false
              }

              const total =
                nifValidate[0] * 9 +
                nifValidate[1] * 8 +
                nifValidate[2] * 7 +
                nifValidate[3] * 6 +
                nifValidate[4] * 5 +
                nifValidate[5] * 4 +
                nifValidate[6] * 3 +
                nifValidate[7] * 2
              const modulo11 = Number(total) % 11

              const checkDigit = modulo11 < 2 ? 0 : 11 - modulo11

              return checkDigit === Number(nifValidate[8])
            })
            .required('NIF obrigatório'),
          telefone: Yup.string().required('Telefone obrigatório')
        })

        await schema.validate(data, {
          abortEarly: false
        })

        formRef.current.setErrors({})

        const { nome, email, nif, telefone } = data

        const response = await api.put(`users/${user.id}`, {
          nome,
          email,
          nif,
          telefone
        })

        if (response.data) {
          handleNextPayament()
        }
      } catch (err) {
        if (err.response) {
          toast.error('Ocorreu um erro, tente novamente!', {
            position: toast.POSITION.TOP_RIGHT
          })
        }

        if (err instanceof Yup.ValidationError) {
          const validationErrors = {}
          if (err instanceof Yup.ValidationError) {
            err.inner.forEach(error => {
              validationErrors[error.path] = error.message
            })
            formRef.current.setErrors(validationErrors)
          }
        }
      }
    },
    [handleNextPayament, user.id]
  )

  return (
    <Container>
      <Phases activeMetodo="aluno" />
      <Form ref={formRef} onSubmit={handleUpdateIdentificacao}>
        <ContainerOpcoes>
          <ContainerTitleSeccao>
            <span>Dados do aluno:</span>
          </ContainerTitleSeccao>
          <ContainerFormPerfil>
            <InputGroup>
              <LabelInput>Nome completo</LabelInput>
              <Input type="text" name="nome" />
            </InputGroup>
            <InputGroup>
              <LabelInput>Email</LabelInput>
              <Input type="text" name="email" disabled={!!user?.email} />
            </InputGroup>
            <ContainerOtherForm>
              <InputGroupOther>
                <LabelInput>Número de Identificação Fiscal</LabelInput>
                <Input type="text" name="nif" disabled={!!user?.nif} />
              </InputGroupOther>
              <InputGroupOther>
                <LabelInput>Telefone</LabelInput>
                <Input type="text" name="telefone" />
              </InputGroupOther>
            </ContainerOtherForm>
          </ContainerFormPerfil>
        </ContainerOpcoes>
        <ContainerButton>
          <Button type="submit" width={263} title="Continuar" />
        </ContainerButton>
      </Form>
    </Container>
  )
}
export default Conta
