import { toast } from 'react-toastify'

import { eventChannel } from 'redux-saga'

import { all, takeLatest, take, call, put, select } from 'redux-saga/effects'

import ws from '../../services/websocket'

import { Types } from '../../store/ducks/auth'

import { Types as TypesNotifications } from '../ducks/notifications'

export function* closeChannel() {
  const id = yield select(state => state.auth.id)

  const logoutChannel = ws.getSubscription(`logout:${id}`)
  const notificationsChannel = ws.getSubscription(`notifications:${id}`)

  if (notificationsChannel) {
    notificationsChannel.close()
  }

  if (logoutChannel) {
    logoutChannel.close()
  }
}
export function subscribeUser({ id }) {
  return eventChannel(emitter => {
    const channel =
      ws.getSubscription(`logout:${id}`) || ws.subscribe(`logout:${id}`)

    const channelNotifications =
      ws.getSubscription(`notifications:${id}`) ||
      ws.subscribe(`notifications:${id}`)

    channel.on('logoutReady', ({ forced } = { forced: false }) => {
      if (!forced) {
        toast.error('Login realizado noutro navegador !', {
          position: toast.POSITION.TOP_RIGHT
        })
      }
      return emitter({ type: Types.LOGOUT_REQUEST })
    })

    channelNotifications.on('notificationsReady', data => {
      const { notifications } = data

      return emitter({
        type: TypesNotifications.ADD_NOTIFICATION,
        id,
        notifications
      })
    })

    return () => {
      channelNotifications.close()
      channel.close()
    }
  })
}

export function* watchUserSubscribtion({ id }) {
  const channel = yield call(subscribeUser, { id })

  while (true) {
    const action = yield take(channel)
    yield put(action)
  }
}

export function* connectToSocket({ data: { id } }) {
  const token = yield select(state => state.auth.token)

  ws.withJwtToken(token).connect()

  yield call(watchUserSubscribtion, { id })
}

export default all([
  takeLatest(Types.USER_DATA, connectToSocket),
  takeLatest(Types.LOGOUT_REQUEST, closeChannel)
])
