import React from 'react'

import { Container, Header, Body } from './styles'
function Transfer({ data, children }) {
  return (
    <Container>
      <Header>
        <h1>Pagamento por Multibanco ou Homebanking</h1>
      </Header>
      <Body>
        <img
          id="MainContent_ASPxCallbackPanel1_Image1"
          src="https://ifthenpay.com/bo/Images/mb.png"
          align="absmiddle"
          alt=""
        />
        <div>
          <div className="dados">
            <h1>NOME</h1>
            <span>Carlos Daniel Lima Lopes</span>
          </div>
          <div className="dados">
            <h1>IBAN</h1>
            <span>PT50003506360006456643005</span>
          </div>
          <div className="dados">
            <h1>SWIFT/BIC</h1>
            <span>CGDIPTPL</span>
          </div>
          <div className="dados">
            <h1>VALOR</h1>
            <span>
              {' '}
              {Intl.NumberFormat('pt-PT', {
                style: 'currency',
                currency: 'EUR'
              }).format(data?.valor)}
            </span>
          </div>
        </div>
      </Body>
      {children}
    </Container>
  )
}

export default Transfer
