import React, { memo } from 'react'

import { useScrollSection } from 'react-scroll-section'

import { SidebarContent, Menu } from './styles'

function Sidebar({ modal }) {
  const sectionIdentificacao = useScrollSection('identificacao')
  const sectionSenha = useScrollSection('senha')
  const sectionRedes = useScrollSection('redes')

  function stateButton(id) {
    if (id === 4) {
      modal()
    }
  }

  return (
    <SidebarContent>
      <Menu
        onClick={sectionIdentificacao.onClick}
        active={sectionIdentificacao.selected}
      >
        <div>Identificação</div>
      </Menu>
      <Menu onClick={sectionSenha.onClick} active={sectionSenha.selected}>
        <div>Senha</div>
      </Menu>
      <Menu onClick={sectionRedes.onClick} active={sectionRedes.selected}>
        <div>Redes Sociais</div>
      </Menu>
      <Menu onClick={() => stateButton(4)}>
        <div>Excluir conta</div>
      </Menu>
    </SidebarContent>
  )
}
export default memo(Sidebar)
