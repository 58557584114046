import styled from 'styled-components'

export const Container = styled.div`
  background-color: var(--black);
`

export const WrapperNotificacoes = styled.div`
  display: flex;
  justify-content: center;
`
export const ContainerNotificacoes = styled.div`
  width: 100%;
  max-width: 1180px;
  padding: 64px 30px 80px;

  @media (max-width: 1260px) {
    padding: 48px 30px 64px;
  }
  @media (max-width: 768px) {
    padding: 30px 20px 40px;
  }
`
export const MenuNotificacoes = styled.div`
  display: flex;
  border-bottom: 1px solid rgb(41, 41, 46);

  @media (max-width: 768px) {
    flex-direction: column;
    border-bottom: 0px;
    border-left: 1px solid rgb(41, 41, 46);
    margin-bottom: 32px;
  }
`
export const ButtonMenuNotificacao = styled.button`
  position: relative;
  background: none;
  border: none;
  outline: none;
  padding-bottom: 1em;
  font-size: 24px;
  text-align: left;
  transition: all 0.2s ease 0s;
  font-weight: 700;
  color: rgb(168, 168, 179);
  cursor: pointer;

  margin-left: 1.33em;

  &:first-child {
    margin-left: 0;
  }

  &:active {
    color: var(--primary);
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0px;
    width: 100%;
    height: 2px;
    background: var(--orange);
    opacity: ${props => (props.activeTab ? 1 : 0)};
    transition: opacity 0.2s ease 0s;
  }

  @media (max-width: 768px) {
    padding-left: 1em;
    padding-bottom: 0px;
    font-size: 16px;
    margin: 0.5em 0px 0px;

    &:first-child {
      margin: 0;
    }

    &::after {
      left: -1px;
      top: 0px;
      width: 2px;
      height: 100%;
    }
  }
`

export const ContainerButtonNotificacao = styled.div`
  flex: 0 0 180px;
  @media (max-width: 825px) {
    flex: 0 0 50px;
  }
`
export const ContainerFormModal = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px 40px;
  form {
    width: 100%;
  }
`
export const FormModalGroup = styled.div`
  margin-bottom: 20px;
  span {
    display: inline-block;
    color: var(--red);
    font-size: 13px;
    font-weight: 500;
    padding-top: 7px;
  }
  input {
    width: 100%;
    height: 60px;
    background-color: var(--black);
    border-radius: 5px;
    color: var(--primary);
    padding-left: 15px;
    font-weight: 400;
    font-size: 14px;
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    ::placeholder {
      color: var(--dark-text-color);
      font-size: 14px;
      font-weight: 400;
    }
  }
  textarea {
    width: 100%;
    background-color: var(--black);
    border-radius: 5px;
    color: var(--primary);
    padding-left: 15px;
    font-weight: 400;
    font-size: 14px;
    padding-top: 20px;
    ::placeholder {
      color: var(--dark-text-color);
      font-size: 14px;
      font-weight: 400;
    }
  }
`
export const ContainerButtonModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    width: 50%;
  }
`
