import React from 'react'

import Skeleton from '../../Skeleton'

import {
  Sidebar,
  Content,
  ContainerContent,
  LinkContent,
  ContentCurso,
  ContainerLogoCurso,
  ContainerDadosCurso
} from './styles'

function LoadingListCursos() {
  return (
    <Sidebar>
      <Content>
        <div>
          <Skeleton type="image" width="120px" height="120px" />
          <Skeleton type="title" width="300px" height="30px" />
          <Skeleton type="text" width="350px" height="13px" />
          <Skeleton type="text" width="350px" height="13px" />
          <Skeleton type="text" width="350px" height="13px" />
        </div>
      </Content>
      <ContainerContent>
        <LinkContent>
          <ContentCurso>
            <ContainerLogoCurso>
              <Skeleton type="image" width="100px" height="100px" />
            </ContainerLogoCurso>
            <Skeleton type="title" width="150px" height="30px" />
            <ContainerDadosCurso>
              <Skeleton type="text" width="400px" height="13px" />
            </ContainerDadosCurso>
          </ContentCurso>
        </LinkContent>
      </ContainerContent>
    </Sidebar>
  )
}

export default LoadingListCursos
