import { toast } from 'react-toastify'

import { all, call, delay, put, takeLatest, fork } from 'redux-saga/effects'

import api from '../../services/api'

import { getToken } from '../../services/auth'

import {
  Types,
  packsData,
  packsLoading,
  clearPacks
} from '../../store/ducks/packs'

export function* getPacks() {
  const token = getToken()

  if (!token) {
    return
  }

  try {
    const response = yield call(api.get, 'packs')

    yield put(packsData(response.data))
    yield delay(1000)
    yield put(packsLoading(false))
  } catch (err) {
    yield put(packsLoading(false))
    toast.error('Ocorreu um erro por favor tente novamente!', {
      position: toast.POSITION.TOP_RIGHT
    })
  }
}

export function* clearPacksState() {
  yield put(clearPacks())
  yield call(getPacks)
}

export default all([
  fork(getPacks),
  takeLatest(Types.PACKS_REQUEST, clearPacksState)
])
