import styled from 'styled-components'

export const ContainerHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  padding: 0px 35px;
  background-color: var(--box-background);
  z-index: 9998;
  transition: all 0.5s ease-in-out 0s;
  box-shadow: rgb(18, 18, 20) 0px 1rem 2rem;
  opacity: 1;
  transform: translateY(0px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    height: 60px;
    padding: 0px 20px;
    top: 0px;
  }
`
export const HeaderContainer = styled.div`
  display: flex;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 40px;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    p {
      font-size: 15px;
      color: var(--primary);
      font-weight: 400;
      margin-left: 5px;
    }
    @media (max-width: 825px) {
      margin-left: 30px;
      p {
        display: none;
      }
    }
  }
`
export const Logo = styled.img`
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 200px;
  max-height: 200px;
  @media (max-width: 768px) {
    width: 120px;
    height: auto;
  }
`
export const ContainerIconsUser = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const IconGroup = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  background: transparent;
  border: none;
  border-radius: 5px;
  transition: background 0.2s ease 0s;

  &:hover {
    width: 46px;
    height: 46px;
    background: var(--black);
  }
`
export const ButtonLogout = styled.div`
  background-color: transparent;
  border: none;
  color: var(--primary);
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
`
export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 40px;
  width: 100%;
  h1 {
    color: var(--primary);
    font-size: 25px;
    font-weight: 700;
    padding-bottom: 40px;
  }

  > div.modal_buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
export const Balloon = styled.div`
  position: absolute;
  top: -1px;
  right: 2px;
  width: 100%;
  height: 100%;
  max-width: 20px;
  max-height: 20px;
  background-color: var(--red);
  border-radius: 50px;
  margin-left: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const NumberNotification = styled.div`
  width: auto;
  height: 18px;
  color: var(--primary);
  font-size: 12px;
  font-weight: 700;
`
export const PerfilContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  @media (max-width: 520px) {
    position: static;
  }
`
export const Perfil = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  span {
    font-size: 16px;
    font-weight: 700;
    color: var(--primary);
    transition: color 0.2s ease 0s;
    margin-right: 12px;
    margin-left: 12px;
  }
  @media (max-width: 520px) {
    span {
      margin-right: 0px;
      margin-left: 0px;
      display: none;
    }
  }
`
export const Avatar = styled.div`
  position: relative;
  width: 55px;
  height: 55px;
  img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    padding: 2px;
    border: 2px solid rgb(225, 225, 230);
    border-radius: 100%;
  }
  @media (max-width: 520px) {
    margin-left: 12px;
  }
  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`
export const MenuContainerPerfil = styled.div`
  position: absolute;
  right: 0px;
  top: calc(100% + 24px);
  width: 256px;
  padding: 0px;
  background: rgb(32, 32, 36);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 5px 20px;
  transition: opacity 0.2s ease 0s, visibility 0.2s ease 0s;
  visibility: ${props => (props.menu ? 'visible' : 'hidden')};
  opacity: ${props => (props.menu ? 1 : 0)};
  z-index: 5;
  &::before {
    content: '';
    position: absolute;
    top: -8px;
    right: 19px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 8px 8px;
    border-color: transparent transparent rgb(32, 32, 36);
  }
  > a:first-child {
    padding-top: 16px;
  }
  > a {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    color: var(--primary);
    padding: 12px 24px;
    transition: background 0.2s ease 0s;
    cursor: pointer;
    background: transparent;
    border: none;
    width: 100%;
    &:hover {
      background: rgba(0, 0, 0, 0.125);
    }
  }
  > button {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    color: rgb(225, 225, 230);
    padding: 12px 24px;
    transition: background 0.2s ease 0s;
    cursor: pointer;
    background: transparent;
    border: none;
    width: 100%;
    &:last-child {
      padding-bottom: 16px;
    }
    &:hover {
      background: rgba(0, 0, 0, 0.125);
    }
  }
  span {
    margin-left: 24px;
  }
  @media (max-width: 520px) {
    width: 100%;
    top: 60px;
    border-radius: 0px;
    &::before {
      right: 32px;
    }
    > a {
      font-size: 15px;
      padding-right: 32px;
    }
  }
`
export const NotificacoesContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;

  @media (max-width: 768px) {
    position: unset;
  }
`
export const LinkNotification = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 46px;
  height: 46px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`
export const MenuContainerNotificacoes = styled.div`
  position: absolute;
  right: 0px;
  top: calc(100% + 24px);
  width: 448px;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 5px 20px;
  transition: opacity 0.2s ease 0s, visibility 0.2s ease 0s;
  visibility: ${props => (props.menu ? 'visible' : 'hidden')};
  opacity: ${props => (props.menu ? 1 : 0)};
  z-index: 5;

  &::before {
    content: '';
    position: absolute;
    top: -8px;
    right: 15px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 8px 8px;
    border-color: transparent transparent rgb(32, 32, 36);
  }

  @media (max-width: 768px) {
    width: 100%;
    top: 100%;
  }

  @media (max-width: 520px) {
    &::before {
      right: 132px;
    }
  }
`
export const HeaderNotificacao = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--hover-gray);
  height: 50px;
  padding: 0px 20px;
  border-radius: 5px 5px 0px 0px;
  span {
    font-size: 16px;
    font-weight: bold;
    color: var(--primary);
  }
  @media (max-width: 768px) {
    border-radius: 0px;
    span {
      font-size: 14px;
    }
  }
`
export const NotificacaoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--box-background);
  align-items: center;
  height: 100%;
  min-height: 160px;
  max-height: 400px;
  width: 100%;
  font-size: 14px;
  color: var(--dark-text-color);

  overflow-y: auto;

  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    background: var(--scroll-track);
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--gray-light);
  }
  .not_found_notification {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    color: var(--dark-text-color);
    font-size: 15px;
    font-weight: 400;
    text-align: justify;
  }
`
export const FooterNotificacao = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--hover-gray);
  height: 50px;
  padding: 0px 20px;
  border-radius: 0px 0px 5px 5px;
  cursor: pointer;
  span {
    font-size: 16px;
    font-weight: bold;
    color: var(--primary);
    margin-right: 5px;
  }
  @media (max-width: 768px) {
    border-radius: 0px;
    span {
      font-size: 14px;
    }
    svg {
      width: 15px;
      height: 15px;
    }
  }
`
export const Notification = styled.div`
  width: 100%;
  cursor: pointer;
  flex: 1;
  display: flex;
  align-items: center;

  &:hover {
    background: rgb(74, 74, 77, 0.5);
    transition: background-color 0.5s ease;
  }
`
export const ContainerUserNotificacao = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 20px 20px 20px;

  .notification_data .container_title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  .notification_data .container_title > p.info {
    background-color: var(--background-color-info);
    border-radius: 5px;
    margin-left: 15px;
    padding: 5px;
    color: var(--primary);
    font-size: 12px;
    font-weight: 400;
    text-align: justify;
  }

  .notification_data .container_title > p.updates {
    background-color: var(--background-color-success);
    border-radius: 5px;
    margin-left: 15px;
    padding: 5px;
    color: var(--primary);
    font-size: 12px;
    font-weight: 400;
    text-align: justify;
  }

  .notification_data {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    position: relative;
  }

  .notification_data h1 {
    color: var(--primary);
    font-size: 18px;
    font-weight: 400;
  }
  .notification_data h1::after {
    display: ${props => (props.unRead ? 'none' : 'block')};
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--orange);
    position: absolute;
    top: 7px;
    right: 0;
    margin-left: 10px;
  }
  .notification_data > p {
    color: var(--dark-text-color);
    font-size: 14px;
    font-weight: 400;
    text-align: justify;
    margin-top: 10px;
  }
  .notification_data strong {
    color: var(--dark-text-color);
    font-size: 14px;
    font-weight: 400;
    text-align: justify;
  }
  /* @media (max-width: 825px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .notification_data {
      align-items: center;
    }
  } */
`
export const AvatarNotification = styled.div`
  width: 100%;
  max-width: 50px;
  height: 50px;
  border-radius: 50px;
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 20px;
  /* @media (max-width: 825px) {
    margin-right: 0;
    margin-bottom: 20px;
  } */
`
