import React, { useCallback, useLayoutEffect, useEffect, useRef } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { useParams } from 'react-router-dom'

import { formatDistance, parseISO } from 'date-fns'

import pt from 'date-fns/locale/pt'

import logo from '../../../assets/logo.png'

// import history from '../../../routes/history'

import {
  notificationsCurrentPage,
  notificationsRequest
} from '../../../store/ducks/notifications'

// import Pagination from '../../Pagination'

import {
  Notification,
  ContainerUserNotificacao,
  Avatar,
  NotFoundNotification
} from '../styles'

function All() {
  const scrollRef = useRef(null)
  const dispatch = useDispatch()
  const { page } = useParams()

  const {
    data: notifications
    // totalPaginas,
    // currentPage,
    // notificationsPerPage,
    // notificationsLastPage
  } = useSelector(state => state.notifications)

  const getNotifications = useCallback(() => {
    dispatch(notificationsRequest())
  }, [dispatch])

  const pageCurrent = useCallback(() => {
    if (page) {
      dispatch(notificationsCurrentPage(page, 1))
    }
  }, [dispatch, page])

  useLayoutEffect(() => {
    getNotifications()
  }, [getNotifications])

  useEffect(() => {
    if (pageCurrent) {
      pageCurrent()
    }
  }, [pageCurrent])

  // const scrollToRef = useCallback(() => {
  //   if (scrollRef.current) {
  //     window.scrollTo({
  //       top: 0
  //     })
  //   }
  // }, [])

  // const handleclickNumberPaginate = useCallback(
  //   number => {
  //     dispatch(notificationsCurrentPage(number, 1))
  //     history.push(`/notifications/${number}`)
  //     scrollToRef()
  //   },
  //   [dispatch, scrollToRef]
  // )

  // const handleNextPage = useCallback(() => {
  //   history.push(`/notifications/${currentPage + 1}`)
  //   scrollToRef()
  // }, [currentPage, scrollToRef])

  // const handleBackPage = useCallback(() => {
  //   history.push(`/notifications/${currentPage - 1}`)
  //   scrollToRef()
  // }, [currentPage, scrollToRef])

  return (
    <>
      {notifications.length > 0 ? (
        notifications
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          .map(({ notification, read }) => (
            <Notification key={notification.id} ref={scrollRef}>
              <ContainerUserNotificacao unRead={read}>
                <Avatar src={logo}></Avatar>
                <div className="notification_data">
                  <div className="container_title">
                    <h1>Meu Fole</h1>
                    {notification.type === 'updates' ? (
                      <p className="updates">Actualização</p>
                    ) : (
                      <p className="info">Informação</p>
                    )}
                  </div>
                  <strong>
                    {formatDistance(
                      parseISO(notification.created_at),
                      new Date(),
                      {
                        locale: pt,
                        addSuffix: true
                      }
                    )}
                  </strong>
                  <p>{notification.mensagem}</p>
                </div>
              </ContainerUserNotificacao>
            </Notification>
          ))
      ) : (
        <NotFoundNotification>Não tem notificações</NotFoundNotification>
      )}
      {/* <Pagination
        currentPage={currentPage}
        perPageVideos={notificationsPerPage}
        totalPages={totalPaginas}
        lastPage={notificationsLastPage}
        handleclickNumberPaginate={handleclickNumberPaginate}
        handleNextPage={handleNextPage}
        handleBackPage={handleBackPage}
      /> */}
    </>
  )
}

export default All
