import React, { useRef, useState } from 'react'

import ReactLoading from 'react-loading'

import ReCAPTCHA from 'react-google-recaptcha'

import { useDispatch } from 'react-redux'

import { Link } from 'react-router-dom'

import { toast } from 'react-toastify'

import { Form } from '@unform/web'

import * as Yup from 'yup'

import FundoLogin from '../../assets/fundo_login.jpg'

import Button from '../../components/Buttons/Button'

import { Input } from '../../components/Form'

// import SliderShow from '../../components/SliderShow'

import api from '../../services/api'

import { setToken } from '../../store/ducks/auth'

import {
  Container,
  ContainerForm,
  ContainerRecuperar,
  RegistoContainer,
  SliderLogin,
  ContainerPoliticas
} from './styles'

export default function Registo() {
  const formRef = useRef(null)
  const recaptchaRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  async function handleRegister(data, { reset }) {
    const phoneRegExp =
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    setLoading(true)
    try {
      const schema = Yup.object().shape({
        nome: Yup.string().required('Nome obrigatório'),
        email: Yup.string()
          .email('Email inválido')
          .required('Email obrigatório')
          .trim(),
        telefone: Yup.string()
          .matches(phoneRegExp, 'Insira um número de telefone válido')
          .required('Telefone obrigatório'),
        password: Yup.string().required('Password obrigatório'),
        confirm_password: Yup.string()
          .required('Confirmar password obrigatório')
          .oneOf([Yup.ref('password'), null], 'Passwords não coincidem')
      })

      await schema.validate(data, {
        abortEarly: false
      })

      formRef.current.setErrors({})

      if (recaptchaRef.current && !recaptchaRef.current.getValue()) {
        toast.error('Aceite o captcha!', {
          position: toast.POSITION.TOP_RIGHT
        })

        setLoading(false)

        return
      }

      const {
        nome,
        email: register_user_email,
        telefone,
        password: register_password_user
      } = data

      const email = register_user_email.trim()
      const password = register_password_user.trim()

      const response = await api.post('/users', {
        nome,
        email,
        telefone,
        password
      })

      setLoading(false)

      if (response.data) {
        dispatch(setToken(response.data.token))

        toast.success('Conta criada com sucesso!', {
          position: toast.POSITION.TOP_RIGHT
        })
      }

      reset()
    } catch (err) {
      setLoading(false)

      if (err.response) {
        toast.error('Email já existe!', {
          position: toast.POSITION.TOP_RIGHT
        })
      }

      if (err instanceof Yup.ValidationError) {
        const validationErrors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
          setLoading(false)
        }
      }
    }
  }

  return (
    <Container>
      <RegistoContainer>
        <h1>Criar conta</h1>
        <ContainerForm>
          <Form ref={formRef} onSubmit={handleRegister}>
            <div className="form-group">
              <Input type="text" name="nome" placeholder="Nome" />
            </div>
            <div className="form-group">
              <Input type="text" name="email" placeholder="Email" />
            </div>
            <div className="form-group">
              <Input type="text" name="telefone" placeholder="Telefone" />
            </div>
            <div className="form-group">
              <Input type="password" name="password" placeholder="Password" />
            </div>
            <div className="form-group">
              <Input
                type="password"
                name="confirm_password"
                placeholder="Confirme Password"
              />
            </div>

            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              theme="dark"
            />

            <ContainerPoliticas>
              Ao se registar, aceita nossos&nbsp;
              <Link
                className="entrar_link"
                to="/termos-e-condicoes-de-utilizacao"
                target="_blank"
              >
                Termos e Condições de Utilização
              </Link>
              &nbsp;e a nossa&nbsp;
              <Link
                className="entrar_link"
                to="/politica-de-privacidade"
                target="_blank"
              >
                política de privacidade
              </Link>
            </ContainerPoliticas>

            <Button
              type="submit"
              transform="true"
              title="Criar conta"
              loading={
                loading && (
                  <ReactLoading
                    type="spin"
                    color="#fff"
                    height={20}
                    width={20}
                  />
                )
              }
            />

            <ContainerRecuperar>
              <Link className="entrar_link" to="/">
                Entrar na conta
              </Link>
            </ContainerRecuperar>
          </Form>
        </ContainerForm>
      </RegistoContainer>
      <SliderLogin src={FundoLogin} />
    </Container>
  )
}
