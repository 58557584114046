export const Types = {
  PRODUTO_DATA: '@produto/PRODUTO_DATA',
  PRODUTO_LOADING: '@produto/PRODUTO_LOADING',
  PRODUTO_REQUEST: '@produto/PRODUTO_REQUEST',
  CLEAR_PRODUTO: '@produto/CLEAR_PRODUTO'
}

const INITIAL_STATE = {
  data: [],
  loading: true
}

export default function produto(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.PRODUTO_LOADING:
      return { ...state, loading: action.loading }
    case Types.PRODUTO_DATA:
      return { ...state, data: action.data }
    case Types.CLEAR_PRODUTO:
      return INITIAL_STATE
    default:
      return state
  }
}

export function produtoData(data) {
  return {
    type: Types.PRODUTO_DATA,
    data
  }
}
export function produtoLoading(loading) {
  return {
    type: Types.PRODUTO_LOADING,
    loading
  }
}
export function produtoRequest(slug) {
  return {
    type: Types.PRODUTO_REQUEST,
    slug
  }
}
export function clearProduto() {
  return {
    type: Types.CLEAR_PRODUTO
  }
}
