import React, {
  useRef,
  useCallback,
  useState,
  useMemo,
  useLayoutEffect
} from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { ScrollingProvider, Section } from 'react-scroll-section'

import { toast } from 'react-toastify'

import logo from '../../assets/logo.png'

import { Button } from '../../components/Buttons'

import Modal from '../../components/Modal'

import { Senha, Identificacao, Redes } from '../../components/Perfil'

import Sidebar from '../../components/Sidebar'

import api from '../../services/api'

import { logoutRequest } from '../../store/ducks/auth'

import {
  Container,
  ContainerPerfil,
  ContainerUser,
  ContainerModal,
  TitleModal,
  ContainerDeleteAccount,
  LoadingContainer
} from './styles'

export default function Perfil() {
  const [loading, setLoading] = useState(true)
  const modalRef = useRef(null)
  const dispatch = useDispatch()

  const { data: user } = useSelector(state => state.auth)

  const handleOpenModal = useCallback(() => {
    modalRef.current.openModal()
  }, [])

  const handleCloseModal = useCallback(() => {
    modalRef.current.closeModal()
  }, [])

  const handleDeleteAccount = useCallback(async () => {
    const { id } = user

    await api.delete(`users/${id}`)

    toast.success('Conta removida com sucesso!', {
      position: toast.POSITION.TOP_RIGHT
    })

    dispatch(logoutRequest())
  }, [dispatch, user])

  const contentModal = useMemo(() => {
    return (
      <ContainerModal>
        <TitleModal>
          Tem certeza que quer
          <p>excluir sua conta?</p>
        </TitleModal>
        <ContainerDeleteAccount>
          <ul>
            <li>
              Você pode simplesmente parar de usar sua conta ao invés de
              excluí-la. Dessa forma quando você quiser retornar, todos os seus
              dados estarão aqui.
            </li>
            <li>
              Todos os dados relacionados a você serão excluídos e não será
              possível a restauração.
            </li>
            <li>
              A exclusão da sua conta não elimina o recebimento de e-mails por
              parte do Meu Fole. Entre em contato com o{' '}
              <a href="mailto:info@meufole.com">info@meufole.com</a> se pretende
              isso.
            </li>
          </ul>
        </ContainerDeleteAccount>
        <div>
          <Button onClick={handleCloseModal} title="Não" />
          <Button onClick={handleDeleteAccount} title="Sim" />
        </div>
      </ContainerModal>
    )
  }, [handleCloseModal, handleDeleteAccount])

  useLayoutEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  if (loading) {
    return (
      <LoadingContainer>
        <div>
          <img src={logo} alt="" />
        </div>
      </LoadingContainer>
    )
  }

  return (
    <>
      <Container>
        <ScrollingProvider offset={-120}>
          <ContainerPerfil>
            <Sidebar modal={handleOpenModal} />
            <ContainerUser>
              <div className="container_web">
                <Section id="identificacao">
                  <Identificacao />
                </Section>
                <Section id="senha">
                  <Senha />
                </Section>
                <Section id="redes">
                  <Redes />
                </Section>
              </div>
              <div className="container_mobile">
                <Identificacao />
                <Senha />
                <Redes />
              </div>
            </ContainerUser>
          </ContainerPerfil>
        </ScrollingProvider>
      </Container>
      <Modal ref={modalRef} data={contentModal} />
    </>
  )
}
