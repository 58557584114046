import styled, { keyframes } from 'styled-components'

const pulse = keyframes`
  0% {
      background-position: -200px 0px;
  }
  100% {
      background-position: calc(200px + 100%) 0px;
  }
`

export const Body = styled.span`
  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0),
    rgb(255, 255, 255),
    rgba(0, 0, 0, 0)
  );
  background-color: rgb(179, 179, 179);
  background-size: 200px 100%;
  display: flex;
  line-height: 1;
  opacity: 0.1;
  width: ${props => props.width};
  height: ${props => props.height};
  animation: 1.2s ease-in-out 0s infinite normal none running ${pulse};
  background-repeat: no-repeat;
  border-radius: 5px;
  margin: 3px 0px;
  @media (max-width: 825px) {
    max-width: 100%;
  }
`
export const Title = styled.span`
  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0),
    rgb(255, 255, 255),
    rgba(0, 0, 0, 0)
  );
  background-color: rgb(179, 179, 179);
  background-size: 200px 100%;
  display: inline-block;
  line-height: 1;
  opacity: 0.1;
  width: ${props => props.width};
  height: ${props => props.height};
  animation: 1.2s ease-in-out 0s infinite normal none running ${pulse};
  background-repeat: no-repeat;
  border-radius: 5px;
  /* margin: 20px 0px; */
  @media (max-width: 825px) {
    max-width: 100%;
  }
`
export const Avatar = styled.span`
  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0),
    rgb(255, 255, 255),
    rgba(0, 0, 0, 0)
  );
  background-color: rgb(179, 179, 179);
  background-size: 200px 100%;
  display: inline-block;
  line-height: 1;
  opacity: 0.1;
  width: ${props => props.width};
  height: ${props => props.height};
  animation: 1.2s ease-in-out 0s infinite normal none running ${pulse};
  background-repeat: no-repeat;
  border-radius: ${props => (props.border ? props.border : '150px')};
`
