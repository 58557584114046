import React, { useEffect, useRef } from 'react'

import ReactSelect from 'react-select'

import { useField } from '@unform/core'

export default function Select({ name, ...rest }) {
  const selectRef = useRef(null)
  const { fieldName, defaultValue, registerField, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: ref => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return []
          }
          return ref.state.value.map(option => option.value)
        }
        if (!ref.state.value) {
          return ''
        }
        return ref.state.value.value
      },
      setValue: (ref, value) => {
        ref.select.setValue(value)
      }
    })
  }, [fieldName, registerField, rest.isMulti])

  return (
    <>
      <ReactSelect
        defaultValue={defaultValue}
        ref={selectRef}
        classNamePrefix="react-select"
        {...rest}
      />
      {error && <span>{error}</span>}
    </>
  )
}
