import { toast } from 'react-toastify'

import { all, call, put, select, delay, takeLatest } from 'redux-saga/effects'

import api from '../../services/api'

import {
  Types,
  produtosData,
  produtosLoading
} from '../../store/ducks/produtos'

export function* getProdutos({ slug }) {
  const { currentPage } = yield select(state => state.produtos)

  yield put(produtosLoading(true))

  if (!slug) {
    return
  }

  try {
    const response = yield call(api.get, 'produtos', {
      params: {
        pack: slug,
        page: currentPage
      }
    })

    const {
      data,
      total: totalPages,
      perPage,
      page: pageActual,
      lastPage
    } = response.data

    yield put(produtosData(data, totalPages, perPage, pageActual, lastPage))
    yield delay(1000)
    yield put(produtosLoading(false))
  } catch (err) {
    yield put(produtosLoading(false))
    toast.error('Ocorreu um erro por favor tente novamente!', {
      position: toast.POSITION.TOP_RIGHT
    })
  }
}

export default all([
  takeLatest(Types.PRODUTOS_REQUEST, getProdutos),
  takeLatest(Types.PRODUTOS_CURRENT_PAGE, getProdutos)
])
