import React, { useEffect, useRef, useState, useCallback } from 'react'

import { MdCameraAlt } from 'react-icons/md'

import ReactLoading from 'react-loading'

import { useDispatch, useSelector } from 'react-redux'

import { toast } from 'react-toastify'

import { Form } from '@unform/web'

import * as Yup from 'yup'

import api from '../../../services/api'

import { userData } from '../../../store/ducks/auth'

import { Button } from '../../Buttons'

import { Input } from '../../Form'

import {
  Container,
  ContainerFormPerfil,
  InputGroup,
  LabelInput,
  ContainerOtherForm,
  InputGroupOther,
  UserInfo,
  IconUploadAvatar,
  IconUploadProgress,
  Avatar
} from './styles'

export default function Identificacao() {
  const [avatar, setAvatar] = useState(false)
  const formRef = useRef(null)
  const dispatch = useDispatch()

  const { data: user } = useSelector(state => state.auth)

  const setFormDataUser = useCallback(async () => {
    formRef.current.setData(user)
  }, [user])

  useEffect(() => {
    setFormDataUser()
  }, [setFormDataUser])

  async function handleUpdateIdentificacao(data) {
    try {
      const schema = Yup.object().shape({
        nome: Yup.string().required('Nome obrigatório'),
        morada: Yup.string().required('Morada obrigatório'),
        email: Yup.string()
          .email('Email inválido')
          .required('Email obrigatório'),
        codigo_postal: Yup.string().required('Codigo-postal obrigatório'),
        localidade: Yup.string().required('Localidade obrigatório'),
        nif: Yup.string()
          .test('nif', 'NIF inválido', function (nif) {
            const nifValidate = typeof nif === 'string' ? nif : nif.toString()
            const validationSets = {
              one: ['1', '2', '3', '5', '6', '8'],
              two: [
                '45',
                '70',
                '71',
                '72',
                '74',
                '75',
                '77',
                '79',
                '90',
                '91',
                '98',
                '99'
              ]
            }

            if (nifValidate.length !== 9) {
              return false
            }

            if (
              !validationSets.one.includes(nifValidate.substr(0, 1)) &&
              !validationSets.two.includes(nifValidate.substr(0, 2))
            ) {
              return false
            }

            const total =
              nifValidate[0] * 9 +
              nifValidate[1] * 8 +
              nifValidate[2] * 7 +
              nifValidate[3] * 6 +
              nifValidate[4] * 5 +
              nifValidate[5] * 4 +
              nifValidate[6] * 3 +
              nifValidate[7] * 2
            const modulo11 = Number(total) % 11

            const checkDigit = modulo11 < 2 ? 0 : 11 - modulo11

            return checkDigit === Number(nifValidate[8])
          })
          .required('NIF obrigatório'),
        telefone: Yup.string().required('Telefone obrigatório')
      })

      await schema.validate(data, {
        abortEarly: false
      })

      formRef.current.setErrors({})

      const {
        nome,
        morada,
        email,
        codigo_postal,
        localidade,
        nif,
        telefone
      } = data

      const response = await api.put(`users/${user.id}`, {
        nome,
        morada,
        email,
        codigo_postal,
        localidade,
        nif,
        telefone
      })

      if (response.data) {
        toast.success('Dados actualizados com sucesso!', {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    } catch (err) {
      if (err.response) {
        toast.error('Ocorreu um erro, tente novamente!', {
          position: toast.POSITION.TOP_RIGHT
        })
      }

      if (err instanceof Yup.ValidationError) {
        const validationErrors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
        }
      }
    }
  }

  async function handleUploadAvatar(file) {
    setAvatar(true)

    const data = new FormData()
    data.append('avatar', file[0])

    const response = await api.put(`perfil/${user.id}`, data)

    if (response.data) {
      dispatch(userData(response.data))
    }

    setAvatar(false)
  }

  return (
    <Container>
      <UserInfo>
        <div className="avatar">
          <input
            type="file"
            name="avatar"
            onChange={e => handleUploadAvatar(e.target.files)}
            accept="image/*"
          />
          {avatar && (
            <IconUploadProgress>
              <ReactLoading
                type="spin"
                color="var(--primary)"
                height={20}
                width={20}
              />
            </IconUploadProgress>
          )}
          <IconUploadAvatar>
            <MdCameraAlt color="var(--primary)" size={20} />
          </IconUploadAvatar>
          <Avatar>
            <img src={user?.avatar} alt="" />
          </Avatar>
          <div>
            <h1>{user?.nome}</h1>
            <p>Aluno</p>
          </div>
        </div>
      </UserInfo>
      <ContainerFormPerfil>
        <Form ref={formRef} onSubmit={handleUpdateIdentificacao}>
          <InputGroup>
            <LabelInput>Nome completo</LabelInput>
            <Input type="text" name="nome" />
          </InputGroup>
          <InputGroup>
            <LabelInput>Morada</LabelInput>
            <Input type="text" name="morada" />
          </InputGroup>
          <InputGroup>
            <LabelInput>Email</LabelInput>
            <Input type="text" name="email" disabled />
          </InputGroup>
          <ContainerOtherForm>
            <InputGroupOther>
              <LabelInput>Codigo-Postal</LabelInput>
              <Input type="text" name="codigo_postal" />
            </InputGroupOther>
            <InputGroupOther>
              <LabelInput>Localidade</LabelInput>
              <Input type="text" name="localidade" />
            </InputGroupOther>
          </ContainerOtherForm>
          <ContainerOtherForm>
            <InputGroupOther>
              <LabelInput>Número de Identificação Fiscal</LabelInput>
              <Input type="text" name="nif" disabled={!!user?.nif} />
            </InputGroupOther>
            <InputGroupOther>
              <LabelInput>Telefone</LabelInput>
              <Input type="text" name="telefone" />
            </InputGroupOther>
          </ContainerOtherForm>
          <Button
            width={73}
            height={35}
            radius={50}
            margin=" 0 5px 0 5px;"
            type="submit"
            title="Guardar"
          />
        </Form>
      </ContainerFormPerfil>
    </Container>
  )
}
