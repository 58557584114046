import React from 'react'

import Skeleton from '../../Skeleton'

import {
  Container,
  TitleContainer,
  ContainerBox,
  ButtonPackContainer
} from './styles'

function LoadingDashboard() {
  return (
    <Container>
      <TitleContainer>
        <Skeleton type="title" width="500px" height="30px" />
        <Skeleton type="text" width="400px" height="13px" />
      </TitleContainer>
      <ContainerBox>
        <div className="pack">
          <div className="title_pack">
            <Skeleton type="text" width="200px" height="45px" />
          </div>
          <div className="thumbnail_container">
            <Skeleton type="image" width="100px" height="100px" />
          </div>
          <ButtonPackContainer>
            <div className="button_pack">
              <Skeleton type="title" width="140px" height="50px" />
            </div>
          </ButtonPackContainer>
        </div>
        <div className="pack active">
          <div className="title_pack">
            <Skeleton type="text" width="200px" height="45px" />
          </div>
          <div className="thumbnail_container">
            <Skeleton type="image" width="100px" height="100px" />
          </div>
          <ButtonPackContainer>
            <div className="button_pack">
              <Skeleton type="title" width="140px" height="50px" />
            </div>
          </ButtonPackContainer>
        </div>
        <div className="pack">
          <div className="title_pack">
            <Skeleton type="text" width="200px" height="45px" />
          </div>
          <div className="thumbnail_container">
            <Skeleton type="image" width="100px" height="100px" />
          </div>
          <ButtonPackContainer>
            <div className="button_pack">
              <Skeleton type="title" width="140px" height="50px" />
            </div>
          </ButtonPackContainer>
        </div>
      </ContainerBox>
    </Container>
  )
}

export default LoadingDashboard
