import React, { useRef } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { toast } from 'react-toastify'

import { Form } from '@unform/web'

import * as Yup from 'yup'

import api from '../../../services/api'

import { logoutRequest } from '../../../store/ducks/auth'

import { Button } from '../../Buttons'

import { Input } from '../../Form'

import {
  Container,
  ContainerFormPerfil,
  InputGroup,
  LabelInput,
  ContainerTitle
} from './styles'

export default function Senha() {
  const formRef = useRef(null)
  const dispatch = useDispatch()
  const { data: user } = useSelector(state => state.auth)

  async function handleUpdateSenha(data, { reset }) {
    try {
      const schema = Yup.object().shape({
        password: Yup.string().required('Password actual obrigatório'),
        new_password: Yup.string().required('Nova password obrigatório'),
        confirm_password: Yup.string()
          .required('Confirmar password obrigatório')
          .oneOf([Yup.ref('new_password'), null], 'Passwords não coincidem')
      })

      await schema.validate(data, {
        abortEarly: false
      })

      formRef.current.setErrors({})

      const { password, new_password } = data

      const response = await api.put(`/change_password/${user.id}`, {
        password,
        new_password
      })

      if (response.data) {
        toast.success('Password actualiada com sucesso!', {
          position: toast.POSITION.TOP_RIGHT
        })
      }

      dispatch(logoutRequest())
    } catch (err) {
      if (err.response) {
        toast.error('Passoword actual incorrecta!', {
          position: toast.POSITION.TOP_RIGHT
        })
      }

      if (err instanceof Yup.ValidationError) {
        const validationErrors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
        }
      }
    }
  }

  return (
    <Container>
      <ContainerTitle>
        <h1>Senha</h1>
      </ContainerTitle>
      <ContainerFormPerfil>
        <Form ref={formRef} onSubmit={handleUpdateSenha}>
          <InputGroup>
            <LabelInput>Senha actual</LabelInput>
            <Input type="password" name="password" />
          </InputGroup>
          <InputGroup>
            <LabelInput>Nova senha</LabelInput>
            <Input type="password" name="new_password" />
          </InputGroup>
          <InputGroup>
            <LabelInput>Confirme nova senha</LabelInput>
            <Input type="password" name="confirm_password" />
          </InputGroup>
          <Button
            width={73}
            height={35}
            radius={50}
            margin=" 0 5px 0 5px;"
            type="submit"
            title="Guardar"
          />
        </Form>
      </ContainerFormPerfil>
    </Container>
  )
}
