import React from 'react'

import { Route as RouterRoute, Switch } from 'react-router-dom'

import NotFound from '../pages/404'

import CartPage from '../pages/CartPage'

import ChangePassword from '../pages/ChangePassword'

import Compras from '../pages/Compras'

import Curso from '../pages/Curso'

import Cursos from '../pages/Cursos'

import Dashboard from '../pages/Dashboard'

import Login from '../pages/Login'

import Notificacoes from '../pages/Notificacoes'

import { Conta, Billing, Metodos, Resumo, Finalizar } from '../pages/Payments'

import Perfil from '../pages/Perfil'

import Recuperar from '../pages/Recuperar'

import Registo from '../pages/Registo'

import { Politica, Termos } from '../pages/Terms'

import Video from '../pages/Video'

import Videos from '../pages/Videos'

import useGoogleAnalytics from '../utils/useGoogleAnalytics'

import Route from './Route'

export default function Routes() {
  useGoogleAnalytics()

  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/register" component={Registo} />
      <Route path="/recover" component={Recuperar} />
      <Route path="/change-password/:token" component={ChangePassword} />

      <Route path="/dashboard" exact component={Dashboard} isPrivate />

      <RouterRoute path="/politica-de-privacidade" exact component={Politica} />
      <RouterRoute
        path="/termos-e-condicoes-de-utilizacao"
        exact
        component={Termos}
      />

      <Route path="/notifications" component={Notificacoes} isPrivate />

      <Route path="/account" exact component={Perfil} isPrivate />
      <Route path="/account/compras/:page?" component={Compras} isPrivate />

      <Route path="/packs/:slug/cursos" component={Cursos} isPrivate />
      <Route path="/packs/:slug/videos/:page?" component={Videos} isPrivate />

      <Route path="/curso/:slug" exact component={Curso} isPrivate />

      <Route
        exact
        path="/curso/:slug/group/:modulo/lesson/:lesson"
        component={Curso}
        isPrivate
      />
      <Route
        path="/curso/:slug/group/:modulo/lesson/:lesson/sub-aula/:sub_aula"
        component={Curso}
        isPrivate
      />

      <Route path="/video/:slug" exact component={Video} isPrivate />
      <Route path="/video/:slug/:lesson" component={Video} isPrivate />

      <Route path="/cart" component={CartPage} isPrivate />
      <Route path="/payment/account" component={Conta} isPrivate />
      <Route path="/payment/billing" component={Billing} isPrivate />
      <Route path="/payment/methods" component={Metodos} isPrivate />
      <Route path="/payment/resume" component={Resumo} isPrivate />
      <Route path="/payment/confirmation" component={Finalizar} isPrivate />

      <RouterRoute path="*" component={NotFound} />
    </Switch>
  )
}
