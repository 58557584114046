import { toast } from 'react-toastify'

import { all, takeLatest, call, put, fork } from 'redux-saga/effects'

import history from '../../routes/history'

import api from '../../services/api'

import { login, getToken, logout } from '../../services/auth'

import { Types, userData, logoutUser, loadToken } from '../../store/ducks/auth'

import { getNotifications } from '../sagas/notifications'

export function* loadUserToken() {
  const token = getToken()

  if (!token) {
    return
  }

  yield put(loadToken(token))
}

export function* loadUser() {
  const token = getToken()

  if (!token) {
    return
  }

  try {
    const response = yield call(api.get, 'perfil')

    if (response.data.activo === 0) {
      yield put({ type: Types.LOGOUT_REQUEST })
      return
    }

    yield put(userData(response.data))
  } catch (err) {
    toast.error('Ocorreu um erro por favor tente novamente!', {
      position: toast.POSITION.TOP_RIGHT
    })
  }
}

export function* signInUser({ token }) {
  if (!token) {
    return
  }
  try {
    login(token)
    yield all([call(loadUser), call(getNotifications)])
    history.push('/dashboard')
  } catch (err) {
    toast.error('Ocorreu um erro por favor tente novamente!', {
      position: toast.POSITION.TOP_RIGHT
    })
  }
}

export function* signOut() {
  yield put(logoutUser())
  logout()
  history.push('/')
}

export default all([
  fork(loadUserToken),
  fork(loadUser),
  takeLatest(Types.SET_USER_TOKEN, signInUser),
  takeLatest(Types.LOGOUT_REQUEST, signOut)
])
