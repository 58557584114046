import { all, call, put, delay, takeLatest } from 'redux-saga/effects'

import history from '../../routes/history'

import api from '../../services/api'

import {
  Types,
  produtoData,
  produtoLoading,
  clearProduto
} from '../ducks/produto'

export function* getProduto(slug) {
  if (!slug) {
    return
  }

  try {
    const response = yield call(api.get, `produtos/${slug}`)

    yield put(produtoData(response.data))
    yield delay(2000)
    yield put(produtoLoading(false))
  } catch (err) {
    yield put(produtoLoading(false))
    if (err) {
      history.goBack()
    }
  }
}

export function* clearProdutoState({ slug }) {
  yield put(clearProduto())
  yield call(getProduto, slug)
}

export default all([takeLatest(Types.PRODUTO_REQUEST, clearProdutoState)])
