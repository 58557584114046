import styled, { css, keyframes } from 'styled-components'

const MoveUpDown = keyframes`
  0% { transform: translateY(0); }
  100% { transform: translateY(-15px); }
`

export const Container = styled.div`
  background-color: var(--black);
  margin-top: 50px;
`
export const ContainerDetalheCurso = styled.div`
  display: flex;
  @media (max-width: 825px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
export const Sidebar = styled.aside`
  position: relative;
  width: 350px;
  background: rgb(25, 25, 28);
  border-radius: 5px;
  overflow: hidden;
  display: initial;

  @media (max-width: 825px) {
    display: initial;
    width: 100%;
    margin: 0 0 20px 0;
    order: 1;
  }
`
export const ContainerSidebar = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  @media (max-width: 825px) {
    position: relative;
    height: 300px;
  }
`
export const Menu = styled.div`
  flex: 1 1 0%;
  overflow-y: auto;
  padding-top: 0px;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    background: var(--scroll-track);
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--gray-light);
  }

  div {
    color: ${props => (props.active ? '#fff' : '#595f6f')};
    font-size: 14px;
    font-weight: 400;
    padding: 10px 15px 10px 10px;
  }

  ${props =>
    props.active &&
    css`
      background-color: #123075;
    `};

  > ul {
    position: relative;
    margin: 30px;
    @media (max-width: 825px) {
      margin: 25px 30px;
    }
  }
  ul li + li {
    margin-top: 20px;
  }
  ul + div {
    margin-top: 2px;
  }
`
export const HeaderMenu = styled.div`
  display: flex;
  align-items: center;
  background: rgb(32, 32, 36);
  padding: 16px;
  cursor: pointer;
  > svg {
    flex-shrink: 0;
    margin-left: auto;
    color: var(--primary);
  }
`
export const NumberModulo = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  background: rgb(0, 0, 0);
  border-radius: 50%;
  flex-shrink: 0;
  span {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    font-size: 14px;
    font-weight: bold;
    color: rgb(225, 225, 230);
  }
`
export const MenuTitle = styled.div`
  margin: 0px 20px;
  span {
    display: block;
    font-size: 16px;
    font-weight: bold;
    color: var(--primary);
  }

  span + span {
    font-size: 12px;
    color: rgb(168, 168, 179);
    margin-top: 6px;
  }
`
export const NavbarDropdownContent = styled.ul`
  display: ${props => (props.open ? 'block' : 'none')};
  // transition: height 0.3s ease 0s;
`
export const Dropdown = styled.li`
  font-size: 16px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 5;

  @media (max-width: 825px) {
    font-size: 14px;
  }

  > button {
    position: relative;
    cursor: pointer;
    width: 10px;
    height: 10px;
    border: 0px;
    border-radius: 50%;
    margin-right: 30px;
    flex-shrink: 0;
    z-index: 2;
    transition: box-shadow 0.2s ease 0s;
    background: rgb(60, 60, 66);
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      transform: translate(-50%, -50%);
      background: ${props =>
        props.active ? 'var(--box-background)' : 'unset'};
      border: ${props => (props.active ? '2px solid var(--orange)' : 'unset')};
      border-radius: ${props => (props.active ? '50%' : 'unset')};
    }
    &:after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 2;
      border-radius: 50%;
      background: ${props => (props.active ? 'var(--primary)' : 'unset')};
    }
  }
  > span {
    color: ${props =>
      props.active ? 'var(--primary)' : 'var(--dark-text-color)'};
    font-size: 16px;
    cursor: pointer;
    &:hover {
      color: var(--primary);
    }
  }
  &:before {
    top: auto;
    bottom: 50%;
  }

  &::before {
    content: '';
    left: 4px;
    width: 2px;
    top: 50%;
    height: calc(100% + 20px);
    background: rgb(60, 60, 66);
    position: absolute;
  }

  &:last-child::before {
    display: none;
  }
`
export const SubDropdown = styled.li`
  font-size: 16px;
  display: ${props => (props.openSubMenu ? 'flex' : 'none')};
  align-items: center;
  position: relative;
  z-index: 2;

  > button {
    position: relative;
    cursor: pointer;
    flex-shrink: 0;
    transition: box-shadow 0.2s ease 0s;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      transform: translate(-50%, -50%);
      background: ${props =>
        props.active ? 'var(--box-background)' : 'unset'};
      border: ${props => (props.active ? '2px solid var(--orange)' : 'unset')};
      border-radius: ${props => (props.active ? '50%' : 'unset')};
    }
    &:after {
      content: '';
      position: absolute;
      width: 55px;
      height: 2px;
      left: 5px;
      top: -2px;
      z-index: 2;
      background: rgb(60, 60, 66);
    }

    &:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      left: 55px;
      top: -1px;
      border: 0px;
      border-radius: 50%;
      z-index: 10;
      background: ${props =>
        props.active ? 'var(--orange)' : 'rgb(60, 60, 66)'};
    }
  }

  > span {
    color: ${props =>
      props.active ? 'var(--primary)' : 'var(--dark-text-color)'};
    font-size: 16px;
    cursor: pointer;
    margin-left: 70px;

    &:hover {
      color: var(--primary);
    }
  }

  &:before {
    top: auto;
    bottom: 50%;
  }
  &::before {
    content: '';
    left: 4px;
    width: 2px;
    top: 50%;
    height: calc(100% + 20px);
    background: rgb(60, 60, 66);
    position: absolute;
  }

  &:last-child::before {
    display: none;
  }
`
export const ContainerVideo = styled.div`
  flex: 1;
  margin: 0 0 0 60px;
  position: relative;

  @media (max-width: 825px) {
    width: 100%;
    margin: 0;
  }
  @media (max-width: 825px) {
    margin: 0 0 20px 0;
  }
`
export const VideoPlayer = styled.div`
  width: 100%;
  min-width: 0px;
  padding: 0 0 56.25% 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
`
export const TituloVideo = styled.div`
  margin-top: 50px;
  h1 {
    color: var(--primary);
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 40px;
  }
  @media (max-width: 825px) {
    margin-top: 0;
    h1 {
      font-size: 18px;
    }
  }
`
export const ContainerModalSucess = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  > div.modal_buttons {
    display: flex;
  }
  @media (max-width: 560px) {
    div.modal_buttons {
      width: 100%;
    }
  }
`
export const ContainerModal = styled.div`
  padding: 40px 40px;
  div.modal_buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      width: 100%;
    }
  }
  @media (max-width: 825px) {
    padding: 20px 20px;
  }
`
export const TitleModal = styled.h1`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--primary);
  font-size: 20px;
  font-weight: 700;
`
export const TitleModalSucess = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-bottom: 30px;
  p {
    color: var(--primary);
    font-size: 17px;
    font-weight: 700;
    margin-left: 5px;
  }
  @media (max-width: 560px) {
    white-space: unset;
    p {
      font-size: 15px;
    }
  }
`
export const ContainerPaymentsCursos = styled.div`
  width: 100%;
  ul {
    list-style: none;
    padding: 10px 0 5px 0;
    li {
      color: var(--dark-text-color);
      font-size: 15px;
      font-weight: 400;
      text-align: justify;
      padding: 10px 0 10px 0;
    }
  }
`
export const PrecoCurso = styled.h1`
  color: var(--primary);
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 15px 0;
`
export const LoadingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  > div {
    -webkit-animation: ${MoveUpDown} 1s infinite alternate;
    animation: ${MoveUpDown} 1s infinite alternate;
  }
`
export const DescricaoVideo = styled.div`
  width: 100%;
  > div.descricao_button {
    display: flex;
    border-bottom: 1px solid var(--hover-gray);
    > button {
      position: relative;
      padding-bottom: 16px;
      background: transparent;
      border: 0px;
      font-size: 16px;
      font-weight: 700;
      color: var(--primary);

      &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0px;
        width: 100%;
        height: 2px;
        background: var(--orange);
        opacity: 1;
        transition: opacity 0.2s ease 0s;
      }
    }
  }
  @media (max-width: 768px) {
    > div.descricao_button {
      flex-direction: column;
      border-bottom: 0px;
      border-left: 1px solid var(--hover-gray);
      > button {
        padding: 4px 0px 4px 16px;
        font-size: 15px;
        text-align: left;

        &::after {
          left: -1px;
          bottom: 0px;
          width: 2px;
          height: 100%;
        }
      }
    }
  }
`
export const DescricaoContainer = styled.div`
  margin-top: 32px;

  .sub_container {
    display: flex;
    align-items: flex-start;
  }
  .container_content {
    flex: 1 1 0%;
    width: 100%;
    min-width: 0px;
  }
  .content {
    flex: 1 1 0%;
    text-align: center;
  }

  .content > svg {
    margin: 0px auto 24px;
  }

  .content > span {
    display: block;
    color: var(--dark-text-color);
    font-size: 16px;
    font-weight: 300;
  }
  .content > span.active {
    text-align: left;
  }

  @media (max-width: 768px) {
    margin-top: 24px;
    .sub_container {
      display: block;
    }
  }
`
