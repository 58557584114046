import React from 'react'

import {
  MdPerson,
  MdCheckBox,
  MdPayment,
  MdCheck,
  MdAssignment
} from 'react-icons/md'

import { useSelector } from 'react-redux'

import {
  Container,
  ContainerPhases,
  ContainerOptions,
  ContainerOption,
  ContainerIcon,
  ContainerTitle
} from './styles'

function Phases({ activeMetodo }) {
  const { phaseOne, phaseTwo, phaseThree, phaseFour, phaseFive } = useSelector(
    state => state.phases
  )

  return (
    <Container>
      {/* <ContainerTitlePage>
        <h1>Pagamentos</h1>
        <span>Finalizaçao de compra</span>
      </ContainerTitlePage> */}

      <ContainerPhases>
        <ContainerOptions>
          <ContainerOption active={activeMetodo === 'aluno' || phaseOne}>
            <ContainerIcon active={activeMetodo === 'aluno' || phaseOne}>
              <MdPerson size={25} />
            </ContainerIcon>

            <ContainerTitle active={activeMetodo === 'aluno' || phaseOne}>
              <span>Identificação</span>
            </ContainerTitle>
          </ContainerOption>

          <ContainerOption active={activeMetodo === 'opcoes' || phaseTwo}>
            <ContainerIcon active={activeMetodo === 'opcoes' || phaseTwo}>
              <MdCheckBox size={25} />
            </ContainerIcon>
            <ContainerTitle active={activeMetodo === 'opcoes' || phaseTwo}>
              <span>Facturação</span>
            </ContainerTitle>
          </ContainerOption>

          <ContainerOption active={activeMetodo === 'metodos' || phaseThree}>
            <ContainerIcon active={activeMetodo === 'metodos' || phaseThree}>
              <MdPayment size={25} />
            </ContainerIcon>
            <ContainerTitle active={activeMetodo === 'metodos' || phaseThree}>
              <span>Pagamento</span>
            </ContainerTitle>
          </ContainerOption>

          <ContainerOption active={activeMetodo === 'resumo' || phaseFour}>
            <ContainerIcon active={activeMetodo === 'resumo' || phaseFour}>
              <MdAssignment size={25} />
            </ContainerIcon>
            <ContainerTitle active={activeMetodo === 'resumo' || phaseFour}>
              <span>Resumo</span>
            </ContainerTitle>
          </ContainerOption>

          <ContainerOption active={activeMetodo === 'confirmacao' || phaseFive}>
            <ContainerIcon active={activeMetodo === 'confirmacao' || phaseFive}>
              <MdCheck size={25} />
            </ContainerIcon>
            <ContainerTitle
              active={activeMetodo === 'confirmacao' || phaseFive}
            >
              <span>Finalização</span>
            </ContainerTitle>
          </ContainerOption>
        </ContainerOptions>
      </ContainerPhases>
    </Container>
  )
}

export default Phases
