import React from 'react'

import { Container, Header, Body } from './styles'

function Multibanco({ data }) {
  return (
    <Container>
      <Header>
        <h1>Pagamento por Multibanco ou Homebanking</h1>
      </Header>
      <Body>
        <img
          id="MainContent_ASPxCallbackPanel1_Image1"
          src="https://ifthenpay.com/bo/Images/mb.png"
          align="absmiddle"
          alt=""
        />
        <div>
          <div className="dados">
            <h1>ENTIDADE</h1>
            <span>{data?.entidade}</span>
          </div>
          <div className="dados">
            <h1>REFERÊNCIA</h1>
            <span>{data?.referencia}</span>
          </div>
          <div className="dados">
            <h1>VALOR</h1>
            <span>
              {Intl.NumberFormat('pt-PT', {
                style: 'currency',
                currency: 'EUR'
              }).format(data?.valor)}
            </span>
          </div>
        </div>
      </Body>
    </Container>
  )
}

export default Multibanco
