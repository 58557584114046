import React, { useEffect, useRef, useCallback } from 'react'

import { useSelector } from 'react-redux'

import { toast } from 'react-toastify'

import { Form } from '@unform/web'

import api from '../../../services/api'

import { Button } from '../../Buttons'

import { Input } from '../../Form'

import {
  Container,
  ContainerFormPerfil,
  InputGroup,
  LabelInput,
  ContainerTitle
} from './styles'

export default function Redes() {
  const formRef = useRef(null)

  const { data: user } = useSelector(state => state.auth)

  const loadSocial = useCallback(async () => {
    const socialList = user?.social

    if (socialList) {
      socialList.map(social => {
        return formRef.current.setData(social)
      })
    }
  }, [user])

  useEffect(() => {
    loadSocial()
  }, [loadSocial])

  const handleSocial = useCallback(async data => {
    try {
      formRef.current.setErrors({})

      const { facebook, instagram, linkedin, tic_toc, snapch } = data

      const response = await api.post('/redes-sociais', {
        facebook,
        instagram,
        linkedin,
        tic_toc,
        snapch
      })

      if (response.data) {
        toast.success('Dados guardados com sucesso!', {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    } catch (err) {
      if (err.response) {
        toast.error('Ocorreu um erro, por favor tente novamente!', {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }
  }, [])

  return (
    <Container>
      <ContainerTitle>
        <h1>Redes Sociais</h1>
      </ContainerTitle>
      <ContainerFormPerfil>
        <Form ref={formRef} onSubmit={handleSocial}>
          <InputGroup>
            <LabelInput>Facebook</LabelInput>
            <Input type="text" name="facebook" />
          </InputGroup>
          <InputGroup>
            <LabelInput>Instagram</LabelInput>
            <Input type="text" name="instagram" />
          </InputGroup>
          <InputGroup>
            <LabelInput>Linkedin</LabelInput>
            <Input type="text" name="linkedin" />
          </InputGroup>
          <InputGroup>
            <LabelInput>Tic Toc</LabelInput>
            <Input type="text" name="tic_toc" />
          </InputGroup>
          <InputGroup>
            <LabelInput>Snapch</LabelInput>
            <Input type="text" name="snapch" />
          </InputGroup>
          <Button
            width={73}
            height={35}
            radius={50}
            margin=" 0 5px 0 5px;"
            type="submit"
            title="Guardar"
          />
        </Form>
      </ContainerFormPerfil>
    </Container>
  )
}
