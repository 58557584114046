import React from 'react'

import Skeleton from '../../Skeleton'

import { Container, ContainerTitle, Table } from './styles'

function LoadingCompras() {
  const Compras = () => (
    <tr>
      <td data-label="Nº Pedido">
        <Skeleton type="title" width="100%" height="13px" />
      </td>
      <td data-label="Produto">
        <Skeleton type="title" width="100%" height="13px" />
      </td>
      <td data-label="Data">
        <Skeleton type="title" width="100%" height="13px" />
      </td>
      <td data-label="Valor">
        <Skeleton type="title" width="100%" height="13px" />
      </td>
      <td data-label="Forma de Pagamento">
        <Skeleton type="title" width="100%" height="13px" />
      </td>
      <td data-label="Dados Pagamento">
        <Skeleton type="title" width="100%" height="13px" />
      </td>
      <td data-label="Status">
        <Skeleton type="title" width="100%" height="13px" />
      </td>
    </tr>
  )

  return (
    <Container>
      <ContainerTitle>
        <Skeleton type="title" width="250px" height="30px" />
      </ContainerTitle>

      <Table>
        <thead>
          <tr>
            <th>
              <Skeleton type="text" width="100%" height="13px" />
            </th>
            <th>
              <Skeleton type="text" width="100%" height="13px" />
            </th>
            <th>
              <Skeleton type="text" width="100%" height="13px" />
            </th>
            <th>
              <Skeleton type="text" width="100%" height="13px" />
            </th>
            <th>
              <Skeleton type="text" width="100%" height="13px" />
            </th>
            <th>
              <Skeleton type="text" width="100%" height="13px" />
            </th>
            <th>
              <Skeleton type="text" width="100%" height="13px" />
            </th>
          </tr>
        </thead>
        <tbody>
          <Compras />
          <Compras />
          <Compras />
          <Compras />
          <Compras />
          <Compras />
          <Compras />
        </tbody>
      </Table>
    </Container>
  )
}

export default LoadingCompras
