import React, {
  useCallback,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  memo
} from 'react'

import useOnclickOutside from 'react-cool-onclickoutside'

import { Container } from './styles'

function Modal({ data, setDisabled }, ref) {
  const [open, setOpen] = useState(false)
  const modalRef = useRef(null)

  const openModal = useCallback(() => {
    setOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setOpen(false)
  }, [])

  useImperativeHandle(ref, () => ({
    openModal,
    closeModal
  }))

  useOnclickOutside(
    () => {
      closeModal()
      if (setDisabled) {
        setDisabled(false)
      }
    },
    { refs: [modalRef] }
  )

  return (
    <Container open={open}>
      <div ref={modalRef}>{data}</div>
    </Container>
  )
}

export default memo(forwardRef(Modal))
