import styled from 'styled-components'

export const Container = styled.div`
  background-color: var(--black);
`
export const ContainerTitle = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 50px 0 50px;

  > img {
    width: 100%;
    height: 100%;
    max-height: 100px;
    max-width: 100px;
    flex-shrink: 0;
  }
  span {
    color: var(--primary);
    line-height: 1.5;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    margin-left: 30px;

    > div {
      color: var(--orange);
      font-size: 16px;
      cursor: pointer;
    }
  }
  @media (max-width: 768px) {
    display: block;
    text-align: center;
    img {
      margin-bottom: 15px;
    }
    span {
      margin-left: 0;
    }
  }
`
export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 24px;
  justify-content: center;

  /* display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-shrink: 0;
  @media (max-width: 1000px) {
    grid-template-rows: repeat(1);
    grid-template-columns: repeat(2, 2fr);
  }
  @media (max-width: 750px) {
    grid-template-rows: repeat(1);
    grid-template-columns: repeat(1, 1fr);
  } */
`
export const Item = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  h1 {
    color: var(--primary);
    font-size: 18px;
    font-weight: 700;
    margin: 10px 0 5px;
  }
  p {
    color: var(--dark-text-color);
    font-size: 14px;
    font-weight: 400;
    text-align: justify;
  }
  strong {
    color: var(--primary);
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 10px;
  }
  audio {
    display: none;
  }
`
export const Thumbnail = styled.img`
  width: 100%;
  height: 300px;
  min-height: 300px;
  border-radius: 5px;
  transition: transform 0.2s;
  object-fit: cover;
  object-position: 0 50%;
`
export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  > div.modal_buttons {
    display: flex;
  }
  @media (max-width: 560px) {
    div.modal_buttons {
      width: 100%;
    }
  }
`
export const TitleModal = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-bottom: 30px;
  p {
    color: var(--primary);
    font-size: 17px;
    font-weight: 700;
    margin-left: 5px;
  }
  @media (max-width: 560px) {
    white-space: unset;
    p {
      font-size: 15px;
    }
  }
`
