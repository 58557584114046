import styled from 'styled-components'

export const Sidebar = styled.div`
  display: flex;
  max-width: 1336px;
  padding-top: 71px;
  flex: 1 1 0%;
  @media (max-width: 768px) {
    display: block;
    padding: 0;
    margin-top: 50px;
  }
`
export const Content = styled.div`
  position: sticky;
  top: 71px;
  width: 325px;
  height: calc(100vh - 80px);

  span {
    display: flex;
  }

  span:nth-child(1) {
    margin: 20px 0;
  }
  span:nth-child(2) {
    margin: 20px 0;
  }
  span:nth-child(3) {
    margin: 5px 0;
  }
  span:nth-child(4) {
    margin: 5px 0;
  }

  @media (max-width: 1130px) {
    width: 240px;
    padding-left: 0px;
    top: 50px;

    span:nth-child(2) {
      width: 200px;
    }
    span:nth-child(3) {
      width: 200px;
    }
    span:nth-child(4) {
      width: 200px;
    }
    span:nth-child(5) {
      width: 200px;
    }
  }
  @media (max-width: 768px) {
    top: auto;
    width: 100%;
    height: auto;
    padding-left: 0px;
    text-align: center;
    margin-bottom: 35px;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span:nth-child(1) {
        display: none;
      }
      span:nth-child(2) {
        width: 50%;
      }
      span:nth-child(3) {
        width: 80%;
      }
      span:nth-child(4) {
        width: 80%;
      }
      span:nth-child(5) {
        width: 80%;
      }
    }
  }
`
export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 0%;
`
export const LinkContent = styled.a`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 699px;
`
export const ContentCurso = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 699px;
  border-radius: 5px;
  margin-bottom: 50px;
  height: 380px;
  position: relative;
  background-color: var(--box-background);

  @media (max-width: 1130px) {
    height: auto;
    min-height: 340px;
    padding: 30px;
    width: 100%;
    max-width: 699px;
  }

  @media (max-width: 768px) {
    height: auto;
    min-height: auto;
    padding: 25px 25px 30px;
  }
`
export const ContainerLogoCurso = styled.div`
  display: flex;
  width: 200px;
  height: 158px;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  @media (max-width: 1130px) {
    width: 145px;
    height: 114px;
    flex-shrink: 0;
    background-size: cover;
  }
`
export const ContainerDadosCurso = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span:nth-child(1) {
    margin: 20px 0;
  }

  @media (max-width: 825px) {
    width: 100%;
    height: auto;
  }
`
