import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: ${props => (props.open ? 99999 : -10)};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  visibility: ${props => (props.open ? css`visible` : css`hidden`)};
  opacity: ${props => (props.open ? 1 : 0)};
  background: rgba(0, 0, 0, 0.9);
  transition: all 0.3s ease 0s;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 480px;
    height: auto;
    transform: ${props =>
      props.open ? css`translateY(0px)` : css`translateY(-100px)`};
    opacity: ${props => (props.open ? 1 : 0)};
    cursor: default;
    background-color: var(--box-background);
    border-radius: 5px;
    overflow: hidden;
    transition: transform 0.3s ease 0s, opacity 0.4s ease 0s;
  }
  @media (max-width: 825px) {
    padding: 20px;
  }
`
