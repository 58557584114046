import React from 'react'

import { FiX } from 'react-icons/fi'

import { useDispatch, useSelector } from 'react-redux'

import imgCartEmpty from '../../assets/cart/carrinho2.png'

import { removeFromCart } from '../../store/ducks/cart'

import {
  Table,
  Produto,
  Preco,
  Quantidade,
  TotalPreco,
  DeleteProduto,
  CartEmpty,
  TotalCart,
  ResumeTotalCart,
  Total,
  TotalPrice
} from './styles'

function Cart({ shadow, quantity, delete: deleteButton }) {
  const dispatch = useDispatch()

  const { items, total } = useSelector(state => state.cart)

  // function handleAddQuantity(id) {
  //   dispatch(AddQuantity(id));
  // }

  // function handleSubtractQuantity(id) {
  //   dispatch(SubtractQuantity(id));
  // }

  function handleRemove(id) {
    dispatch(removeFromCart(id))
  }

  return (
    <>
      {items.length ? (
        <Table shadow={shadow}>
          <thead>
            <tr>
              <th>Items</th>
              <th>Preço</th>
              <th>Quantidade</th>
              <th>Subtotal (C/IVA)</th>
              {deleteButton && <th></th>}
            </tr>
          </thead>
          <tbody>
            {items.map(item => (
              <tr key={item.id}>
                <td width="150">
                  <Produto>
                    <img src={item.thumbnail} alt="" />
                    <h1>{item.titulo}</h1>
                  </Produto>
                </td>
                <td width="150">
                  <Preco>
                    <span>
                      {Intl.NumberFormat('pt-PT', {
                        style: 'currency',
                        currency: 'EUR'
                      }).format(item.preco)}
                    </span>
                  </Preco>
                </td>
                <td width="150">
                  <Quantidade quantity={quantity}>
                    {quantity ? (
                      <>
                        {/* <span onClick={() => handleSubtractQuantity(item.id)}>
                          <div>
                            <FiMinus size={10} color="#000" />
                          </div>
                        </span> */}
                        <p>{item.amount}</p>
                        {/* <span onClick={() => handleAddQuantity(item.id)}>
                          <div>
                            <FiPlus size={10} color="#000" />
                          </div>
                        </span> */}
                      </>
                    ) : (
                      <p>{item.amount}</p>
                    )}
                  </Quantidade>
                </td>
                <td width="150">
                  <TotalPreco>
                    <span>
                      {Intl.NumberFormat('pt-PT', {
                        style: 'currency',
                        currency: 'EUR'
                      }).format(item.subtotal)}
                    </span>
                  </TotalPreco>
                </td>
                {deleteButton && (
                  <td width="73">
                    <DeleteProduto onClick={() => handleRemove(item.id)}>
                      <FiX size={20} color="#BFC4CE" />
                    </DeleteProduto>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <CartEmpty>
          <img src={imgCartEmpty} alt="ewf" />
          <span>Carrinho Vazio</span>
        </CartEmpty>
      )}
      {items.length > 0 && (
        <TotalCart shadow={shadow}>
          {/* <ResumeTotalCart>
            <Total>Subtotal:</Total>
            <TotalPrice>
              <span>
                {Intl.NumberFormat('pt-PT', {
                  style: 'currency',
                  currency: 'EUR'
                }).format(subtotal)}
              </span>
            </TotalPrice>
          </ResumeTotalCart> */}
          {/* <ResumeTotalCart>
            <Total>IVA (23%):</Total>
            <TotalPrice>
              <span>
                {Intl.NumberFormat('pt-PT', {
                  style: 'currency',
                  currency: 'EUR'
                }).format(iva)}
              </span>
            </TotalPrice>
          </ResumeTotalCart> */}
          <ResumeTotalCart>
            <Total>Total:</Total>
            <TotalPrice>
              <span>
                {Intl.NumberFormat('pt-PT', {
                  style: 'currency',
                  currency: 'EUR'
                }).format(total)}
              </span>
            </TotalPrice>
          </ResumeTotalCart>
        </TotalCart>
      )}
    </>
  )
}

export default Cart
