import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  max-width: 360px;
`
export const Header = styled.div`
  display: flex;
  background: var(--orange);
  padding: 10px 0px;
  align-items: center;
  justify-content: center;
  > h1 {
    color: var(--primary);
    font-size: 13px;
    font-weight: 400;
  }
  @media (max-width: 320px) {
    > h1 {
      font-size: 10px;
    }
  }
`
export const Body = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--primary);
  padding: 10px;
  > img {
    width: 85px;
    height: 80px;
    padding: 5px 0px;
    padding-right: 15px;
  }

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;

    > div.dados {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > h1 {
        padding: 5px 0px;
        color: var(--white-text-color);
        font-size: 15px;
        font-weight: 400;
      }
      > span {
        color: var(--white-text-color);
        font-size: 15px;
        font-weight: 700;
      }
    }
  }
  @media (max-width: 825px) {
    > img {
      display: none;
    }
  }
  @media (max-width: 320px) {
    > div {
      > div.dados {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        > h1 {
          font-size: 14px;
        }
        > span {
          font-size: 14px;
        }
      }
    }
  }
`
