import { toast } from 'react-toastify'

import { all, call, put, delay, select, takeLatest } from 'redux-saga/effects'

import api from '../../services/api'

import { getToken } from '../../services/auth'

import { Types, comprasUser, comprasLoading } from '../../store/ducks/compras'

export function* getCompras() {
  const { currentPage } = yield select(state => state.compras)

  const token = getToken()

  if (!token) {
    return
  }

  yield put(comprasLoading(true))

  try {
    const response = yield call(api.get, 'compras', {
      params: {
        page: currentPage
      }
    })

    const {
      data,
      total: totalPages,
      perPage,
      page: pageActual,
      lastPage
    } = response.data

    yield put(comprasUser(data, totalPages, perPage, pageActual, lastPage))
    yield delay(1000)
    yield put(comprasLoading(false))
  } catch (err) {
    yield put(comprasLoading(false))
    toast.error('Ocorreu um erro por favor tente novamente!', {
      position: toast.POSITION.TOP_RIGHT
    })
  }
}

export default all([
  takeLatest(Types.REQUEST_COMPRAS, getCompras),
  takeLatest(Types.COMPRAS_CURRENT_PAGE, getCompras)
])
