import React, {
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useState,
  useLayoutEffect
} from 'react'

import {
  MdLocalGroceryStore,
  MdDone,
  MdPlayCircleOutline,
  MdPauseCircleOutline
} from 'react-icons/md'

import { useDispatch, useSelector } from 'react-redux'

import { useHistory, useParams } from 'react-router-dom'

import ReadMoreReact from 'read-more-react'

import { Button } from '../../components/Buttons'

import Modal from '../../components/Modal'

import Pagination from '../../components/Pagination'

import LoadingVideos from '../../components/Shimmer/LoadingVideos'

import api from '../../services/api'

import { addToCart } from '../../store/ducks/cart'

import { requestCompras } from '../../store/ducks/compras'

import {
  produtosRequest,
  produtosCurrentPage,
  clearProdutos
} from '../../store/ducks/produtos'

import {
  Container,
  ContainerTitle,
  Grid,
  Item,
  Thumbnail,
  ContainerModal,
  TitleModal
} from './styles'

export default function Videos() {
  const [pack, setPack] = useState({})
  const [videosData, setVideosData] = useState([])
  const [videoPlaying, setVideoPlaying] = useState({})
  const modalRef = useRef(null)
  const scrollRef = useRef(null)
  const history = useHistory()
  const dispatch = useDispatch()
  const { slug, page } = useParams()

  const {
    data: videos,
    totalPaginas,
    currentPage,
    videosPerPage,
    videosLastPage,
    loading
  } = useSelector(state => state.produtos)

  const { data: compras } = useSelector(state => state.compras)

  const { data: packs } = useSelector(state => state.packs)

  const { data: user } = useSelector(state => state.auth)

  const getCompras = useCallback(() => {
    dispatch(requestCompras())
  }, [dispatch])

  const getPack = useCallback(() => {
    const pack = packs?.find(pack => pack.slug === slug)
    setPack(pack)
  }, [packs, slug])

  const pageCurrent = useCallback(() => {
    if (page) {
      dispatch(produtosCurrentPage(page, slug))
    }
  }, [dispatch, page, slug])

  useEffect(() => {
    if (pageCurrent) {
      pageCurrent()
    }
  }, [dispatch, page, pageCurrent, slug])

  useEffect(() => {
    return () => {
      dispatch(clearProdutos())
    }
  }, [dispatch])

  useEffect(() => {
    getPack()
  }, [getPack])

  useEffect(() => {
    getCompras()
  }, [getCompras])

  useLayoutEffect(() => {
    if (page) return
    dispatch(produtosRequest(slug))
  }, [dispatch, page, slug])

  const handleOpenModal = useCallback(() => {
    modalRef.current.openModal()
  }, [])

  const handleCloseModal = useCallback(() => {
    modalRef.current.closeModal()
  }, [])

  const reduceCompras = useCallback((compras, videoId) => {
    return compras?.reduce((accumulator, currentCompra) => {
      if (!currentCompra.status) {
        return accumulator
      }
      return accumulator.concat(
        currentCompra.item.filter(item => item.produto_id === videoId)
      )
    }, [])
  }, [])

  const addCart = useCallback(
    item => {
      const comprasResult = compras?.reduce((accumulator, currentCompra) => {
        if (currentCompra.status) {
          return accumulator
        }
        return accumulator.concat(
          currentCompra.item.filter(
            itemComprado => itemComprado.produto_id === item.id
          )
        )
      }, [])

      comprasResult.map(compraItem => {
        const { compra_id } = compraItem

        return api.delete(`compras/${compra_id}`)
      })

      dispatch(addToCart(item))
      handleOpenModal()
    },
    [dispatch, handleOpenModal, compras]
  )

  const handleVideoView = useCallback(
    item => {
      history.push(`/video/${item.slug}`)
    },
    [history]
  )

  const contentModal = useMemo(() => {
    return (
      <ContainerModal>
        <TitleModal>
          <MdDone size={30} color="green" />
          <p>Poduto adicionado ao carrinho de compras</p>
        </TitleModal>

        <div className="modal_buttons">
          <Button
            onClick={() => history.push('/cart')}
            title="Finalizar compra"
          />
          <Button onClick={handleCloseModal} title="Continuar a comprar" />
        </div>
      </ContainerModal>
    )
  }, [handleCloseModal, history])

  const scrollToRef = useCallback(() => {
    if (scrollRef.current) {
      window.scrollTo({
        top: 0
      })
    }
  }, [])

  const handleclickNumberPaginate = useCallback(
    number => {
      dispatch(produtosCurrentPage(number, slug))
      history.push(`/packs/${slug}/videos/${number}`)
      scrollToRef()
    },
    [dispatch, history, scrollToRef, slug]
  )

  const handleNextPage = useCallback(() => {
    history.push(`/packs/${slug}/videos/${currentPage + 1}`)
    scrollToRef()
  }, [currentPage, history, scrollToRef, slug])

  const handleBackPage = useCallback(() => {
    history.push(`/packs/${slug}/videos/${currentPage - 1}`)
    scrollToRef()
  }, [currentPage, history, scrollToRef, slug])

  useEffect(() => {
    const musicData = videos?.map(video => {
      return { ...video, audio: new Audio(video.audio_demo), play: false }
    })

    setVideosData(musicData)
  }, [videos])

  useEffect(() => {
    videoPlaying?.audio?.addEventListener('ended', () => {
      const musicData = videos?.map(video => {
        return { ...video, audio: new Audio(video.audio_demo), play: false }
      })

      setVideosData(musicData)
    })
    return () => {
      videoPlaying?.audio?.removeEventListener('ended', () => {
        const musicData = videos?.map(video => {
          return { ...video, audio: new Audio(video.audio_demo), play: false }
        })

        setVideosData(musicData)
      })
    }
  }, [videoPlaying, videos])

  useEffect(() => {
    return () => {
      videoPlaying?.audio?.pause()
    }
  }, [videoPlaying])

  function handlePlayAudio(video, index) {
    if (video.play) {
      setVideosData(arr =>
        arr.map((sound, i) => {
          if (i === index) {
            sound.audio.pause()
            return { ...sound, play: false }
          }
          return { ...sound, play: false }
        })
      )
    } else {
      setVideosData(arr =>
        arr.map((sound, i) => {
          if (i === index) {
            sound.audio.play()
            return { ...sound, play: true }
          }
          sound.audio.pause()
          return { ...sound, play: false }
        })
      )
    }

    setVideoPlaying(video)
  }

  if (loading) {
    return <LoadingVideos />
  }

  return (
    <div>
      <Container ref={scrollRef}>
        <ContainerTitle>
          <img src={pack.thumbnail} alt="" />
          {pack.descricao && (
            <ReadMoreReact
              text={pack.descricao}
              min={90}
              ideal={200}
              max={300}
              readMoreText="Ler mais"
            />
          )}
        </ContainerTitle>
        <Grid>
          {videosData.map((video, index) => (
            <Item key={video.id}>
              <Thumbnail src={video.thumbnail} />
              <h1>{video.titulo}</h1>
              {video.descricao && <p>{video.descricao}</p>}
              {parseInt(video.preco) === 0 ? (
                <strong>Gratuito</strong>
              ) : (
                <strong>
                  {Intl.NumberFormat('pt-PT', {
                    style: 'currency',
                    currency: 'EUR'
                  }).format(video.preco)}
                </strong>
              )}
              {video.audio_demo && (
                <Button
                  onClick={() => handlePlayAudio(video, index)}
                  title=" Ouvir primeiro"
                  margin="20px 0px 0px 0px"
                  transform="true"
                  background="#0784b5"
                  color="var(--primary)"
                  icon={
                    video.play ? (
                      <MdPauseCircleOutline size={20} color="var(--primary)" />
                    ) : (
                      <MdPlayCircleOutline size={20} color="var(--primary)" />
                    )
                  }
                />
              )}
              {parseInt(video.preco) === 0 ||
              reduceCompras(compras, video.id).length > 0 ||
              !user.roles.findIndex(role => role.slug === 'admin') ? (
                <Button
                  onClick={() => handleVideoView(video)}
                  title=" Assistir agora"
                  margin={`${
                    video.audio_demo ? '10px 0px 0px 0px' : '20px 0px 0px 0px'
                  }`}
                  transform="true"
                  background="var(--green-dark-mode)"
                  color="var(--primary)"
                  icon={
                    <MdPlayCircleOutline size={20} color="var(--primary)" />
                  }
                />
              ) : (
                <Button
                  onClick={() => addCart(video)}
                  title=" Adicionar ao carrinho"
                  margin={`${
                    video.audio_demo ? '10px 0px 0px 0px' : '20px 0px 0px 0px'
                  }`}
                  transform="true"
                  background="var(--red-dark-mode)"
                  color="var(--primary)"
                  icon={
                    <MdLocalGroceryStore size={20} color="var(--primary)" />
                  }
                />
              )}
            </Item>
          ))}
        </Grid>
        <Pagination
          currentPage={currentPage}
          perPageVideos={videosPerPage}
          totalPages={totalPaginas}
          lastPage={videosLastPage}
          handleclickNumberPaginate={handleclickNumberPaginate}
          handleNextPage={handleNextPage}
          handleBackPage={handleBackPage}
        />
      </Container>
      <Modal ref={modalRef} data={contentModal} />
    </div>
  )
}
