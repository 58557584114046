import React, { useEffect, useRef } from 'react'

import { useField } from '@unform/core'

import {
  InputGroup,
  ContainerIconPayment,
  ImageMetodo,
  Descricao,
  Titulo
} from './styles'

export default function Radio({ name, options }) {
  const inputRefs = useRef([])
  const { fieldName, registerField, defaultValue } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRefs.current,
      getValue(refs) {
        const checked = refs.find(ref => ref.checked)

        return checked ? checked.value : null
      },
      setValue(refs, value) {
        const item = refs.find(ref => ref.value === value)

        if (item) {
          item.checked = true
        }
      }
    })
  }, [fieldName, registerField])

  return (
    <>
      {options.map((option, index) => (
        <InputGroup key={option.id}>
          <ContainerIconPayment>
            <input
              ref={elRef => (inputRefs.current[index] = elRef)}
              type="radio"
              name={fieldName}
              value={option.id}
              defaultChecked={defaultValue === option.id}
            />
            <ImageMetodo>
              <img src={option.thumbnail} alt={option.label} />
            </ImageMetodo>
          </ContainerIconPayment>
          <Descricao>
            <Titulo>{option.label}</Titulo>
            <p>{option.descricao}</p>
          </Descricao>
        </InputGroup>
      ))}
    </>
  )
}
