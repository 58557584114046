import React from 'react'

import { useSelector } from 'react-redux'

import { Button } from '../Buttons'

import { BillingsContainer, BillingTitle, ChangeButtonBilling } from './styles'

function Billings({ update, updateButton, margin, background, color }) {
  const { data: user } = useSelector(state => state.auth)
  const { billing } = useSelector(state => state.checkout)

  return (
    <BillingsContainer margin={margin} background={background} color={color}>
      <BillingTitle color={color}>Facturar a:</BillingTitle>
      <ul>
        <>
          <li>
            {billing?.nome} (NIF: {user?.nif})
          </li>
          <li>
            {billing?.morada} - {billing?.codigo_postal} {billing?.cidade},{' '}
            {billing?.pais}
          </li>
        </>
      </ul>
      {updateButton && (
        <ChangeButtonBilling>
          <Button width={150} onClick={update} title="Alterar" />
        </ChangeButtonBilling>
      )}
    </BillingsContainer>
  )
}

export default Billings
