import axios from 'axios'

import md5 from 'js-md5'

import moment from 'moment'

import { getToken } from './auth'

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`
})

api.interceptors.request.use(async config => {
  const now = new Date()

  const validToken = md5('aplicacao' + moment(now).format('YYYY-MM-DD HH:mm'))

  const token = await getToken()

  if (token || validToken) {
    config.headers.Authorization = `Bearer ${token}`
    config.headers.tokenApp = validToken
  }

  return config
})

export default api
