export const Types = {
  COMPRAS_DATA: '@compras/COMPRAS_DATA',
  REQUEST_COMPRAS: '@compras/REQUEST_COMPRAS',
  ITEMS_USER: '@compras/ITEMS_USER',
  COMPRAS_CURRENT_PAGE: '@compras/COMPRAS_CURRENT_PAGE',
  LOADING_COMPRAS: '@compras/LOADING_COMPRAS'
}

const INITIAL_STATE = {
  data: [],
  items: null,
  totalPaginas: '',
  currentPage: 1,
  videosPerPage: '',
  videosLastPage: '',
  loading: false
}

export default function comprasPerfil(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LOADING_COMPRAS:
      return { ...state, loading: action.loading }
    case Types.COMPRAS_DATA:
      return {
        ...state,
        data: action.data,
        totalPaginas: action.totalPages,
        currentPage: action.pageActual,
        videosPerPage: action.perPage,
        videosLastPage: action.lastPage
      }
    case Types.ITEMS_USER:
      return { ...state, items: action.data }
    case Types.COMPRAS_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.page
      }
    default:
      return state
  }
}

export function comprasUser(data, totalPages, perPage, pageActual, lastPage) {
  return {
    type: Types.COMPRAS_DATA,
    data,
    totalPages,
    perPage,
    pageActual,
    lastPage
  }
}
export function itemsUser(data) {
  return {
    type: Types.ITEMS_USER,
    data
  }
}
export function requestCompras() {
  return {
    type: Types.REQUEST_COMPRAS
  }
}
export function comprasCurrentPage(page) {
  return {
    type: Types.COMPRAS_CURRENT_PAGE,
    page
  }
}
export function comprasLoading(loading) {
  return {
    type: Types.LOADING_COMPRAS,
    loading
  }
}
