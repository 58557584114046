import React, { useRef } from 'react'

import { useSelector } from 'react-redux'

// import { useParams } from 'react-router-dom'

import { formatDistance, parseISO } from 'date-fns'

import pt from 'date-fns/locale/pt'

import logo from '../../../assets/logo.png'

import {
  Notification,
  ContainerUserNotificacao,
  Avatar,
  NotFoundNotification
} from '../styles'

function Updates() {
  const scrollRef = useRef(null)
  // const dispatch = useDispatch()
  // const { page } = useParams()

  const {
    data: notifications
    // totalPaginas,
    // currentPage,
    // notificationsPerPage,
    // notificationsLastPage
  } = useSelector(state => state.notifications)

  return (
    <>
      {notifications.filter(
        ({ notification }) => notification.type === 'updates'
      ).length > 0 ? (
        notifications
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          .filter(({ notification }) => notification.type === 'updates')
          .map(({ notification, read }) => (
            <Notification key={notification.id} ref={scrollRef}>
              <ContainerUserNotificacao unRead={read}>
                <Avatar src={logo}></Avatar>
                <div className="notification_data">
                  <div className="container_title">
                    <h1>Meu Fole</h1>
                    {notification.type === 'updates' ? (
                      <p className="updates">Actualização</p>
                    ) : (
                      <p className="info">Informação</p>
                    )}
                  </div>
                  <strong>
                    {formatDistance(
                      parseISO(notification.created_at),
                      new Date(),
                      {
                        locale: pt,
                        addSuffix: true
                      }
                    )}
                  </strong>
                  <p>{notification.mensagem}</p>
                </div>
              </ContainerUserNotificacao>
            </Notification>
          ))
      ) : (
        <NotFoundNotification>Não tem notificações</NotFoundNotification>
      )}
    </>
  )
}

export default Updates
