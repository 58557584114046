import React, { memo, forwardRef } from 'react'

import { MdArrowForward, MdArrowBack } from 'react-icons/md'

import { Paginate, ArrowLeft, Page, ArrowRight, Link } from './styles'

function Pagination(
  {
    currentPage,
    totalPages,
    perPageVideos,
    lastPage,
    handleclickNumberPaginate,
    handleNextPage,
    handleBackPage
  },
  ref
) {
  const pageLinks = []

  for (let i = 1; i <= Math.ceil(totalPages / perPageVideos); i++) {
    pageLinks.push(i)
  }

  return (
    <Paginate>
      {currentPage > 1 && (
        <Link onClick={handleBackPage}>
          <ArrowLeft>
            <MdArrowBack size={20} color="var(--primary)" />
          </ArrowLeft>
        </Link>
      )}
      {pageLinks.map(number => (
        <Link key={number} onClick={() => handleclickNumberPaginate(number)}>
          <Page active={currentPage === number}>
            <div>{number}</div>
          </Page>
        </Link>
      ))}
      {currentPage <= lastPage - 1 && (
        <Link onClick={handleNextPage}>
          <ArrowRight>
            <MdArrowForward size={20} color="var(--primary)" />
          </ArrowRight>
        </Link>
      )}
    </Paginate>
  )
}

export default memo(forwardRef(Pagination))
