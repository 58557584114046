import styled from 'styled-components'

export const Container = styled.div`
  padding:40px;
  @media (max-width: 825px) {
    padding:20px;
  }
  > h2 {
    font-weight: 700;
    font-size: 20px;
    color: var(--primary);
    margin-bottom: 20px;
  }
  > h3 {
    font-weight: 500;
    font-size: 16px;
    color: var(--primary);
    margin-bottom: 20px;
  }
  > p {
    font-weight: 400;
    font-size: 16px;
    color: var(--dark-text-color);
    margin-bottom: 15px;
    > a {
      font-weight: 400;
      font-size: 16px;
      color: var(--orange);
      margin-bottom: 15px;
    }
  }

  > ul {
    margin-top: 0;
    margin-bottom: 1rem;
    padding-inline-start: 40px;
    > li {
      font-weight: 400;
      font-size: 16px;
      color: var(--dark-text-color);
      margin-bottom: 15px;
      > a {
        font-weight: 400;
        font-size: 16px;
        color: var(--orange);
        margin-bottom: 15px;
      }
    }
  }
`
