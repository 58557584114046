import React from 'react'

import Skeleton from '../../Skeleton'

import { Container, ContainerTitle, Grid, Item } from './styles'

function LoadingVideos() {
  const LoadingItemVideos = () => (
    <Item>
      <Skeleton type="image" border="5px" width="100%" height="300px" />
      <Skeleton type="title" width="200px" height="30px" />
      <Skeleton type="text" width="100%" height="13px" />
      <Skeleton type="text" width="100%" height="13px" />
      <Skeleton type="text" width="100%" height="13px" />

      <Skeleton type="title" width="100px" height="30px" />

      <Skeleton type="title" width="100%" height="50px" />
    </Item>
  )

  return (
    <Container>
      <ContainerTitle>
        <div>
          <Skeleton type="image" width="120px" height="120px" />
          <Skeleton type="title" width="300px" height="30px" />
          <Skeleton type="text" width="350px" height="13px" />
          <Skeleton type="text" width="350px" height="13px" />
          <Skeleton type="text" width="350px" height="13px" />
        </div>
      </ContainerTitle>
      <Grid>
        <LoadingItemVideos />
        <LoadingItemVideos />
        <LoadingItemVideos />
        <LoadingItemVideos />
        <LoadingItemVideos />
        <LoadingItemVideos />
      </Grid>
    </Container>
  )
}

export default LoadingVideos
