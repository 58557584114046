import React, { useLayoutEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { useHistory, Link } from 'react-router-dom'

import LoadingDashboard from '../../components/Shimmer/LoadingDashboard'

import { packsRequest } from '../../store/ducks/packs'

import {
  Container,
  TitleContainer,
  ContainerBox,
  Thumbnail,
  ButtonPackContainer,
  ContainerTerms
} from './styles'

export default function Dashboard() {
  const history = useHistory()
  const dispatch = useDispatch()

  const { data, loading } = useSelector(state => state.packs)

  useLayoutEffect(() => {
    dispatch(packsRequest())
  }, [dispatch])

  if (loading) {
    return <LoadingDashboard />
  }
  return (
    <Container>
      <TitleContainer>
        <h1>Modos de aprendizagem</h1>
        <div>Escolhe o teu modo de aprendizagem</div>
      </TitleContainer>
      <ContainerBox>
        {data.map(pack => (
          <div
            className={`pack ${pack.active ? 'active' : ''}`}
            onClick={() => history.push(`packs/${pack.slug}/${pack.tipo}`)}
            key={pack.id}
          >
            <div className="title_pack">{pack.nome}</div>
            <div className="thumbnail_container">
              <Thumbnail src={pack.thumbnail} />
            </div>
            <ButtonPackContainer>
              <div className="button_pack_container">Aprender agora</div>
            </ButtonPackContainer>
          </div>
        ))}
      </ContainerBox>
      <ContainerTerms>
        <ul>
          <li>
            <Link to="/politica-de-privacidade" target="_blank">Política de Privacidade</Link>
          </li>
          <li>
            <Link to="/termos-e-condicoes-de-utilizacao" target="_blank">
              Termos e Condições de Utilização
            </Link>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.livroreclamacoes.pt/inicio"
            >
              Livro de Reclamações
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.ciab.pt/pt/contactos"
            >
              CIAB
            </a>
          </li>
        </ul>
      </ContainerTerms>
    </Container>
  )
}
