import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1366px;
  margin: 0px auto;
  min-height: 100%;
  justify-content: center;
`
export const ContainerContent = styled.div`
  padding: 0px 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    margin-top: 2rem;
    padding: 2rem;
    flex-direction: column-reverse;
  }
`
export const ContainerNotFoundImage = styled.div`
  margin-top: 4rem;
  display: flex;
  flex: 1 1 0%;
  max-width: 500px;

  > img {
    width: 100%;
    height: max-content;
  }
`
export const ContainerNotFoundContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem 0px 3rem 12.8rem;

  > h1 {
    font-size: 120px;
    line-height: 88%;
    font-weight: 700;
    color: var(--orange);
  }

  > h2 {
    margin-top: 10px;
    font-size: 40px;
    line-height: 177%;
    font-weight: 700;
    color: rgb(225, 225, 230);
  }
  > span {
    margin-top: 50px;
    font-size: 15px;
    line-height: 163%;
    font-weight: 700;
    text-transform: uppercase;
    color: rgb(4, 211, 97);
    position: relative;
  }

  p {
    margin-top: 2.4rem;
    font-size: 18px;
    line-height: 155%;
    color: rgb(168, 168, 179);
  }
  > button {
    margin-top: 3.2rem;
    padding: 15px 48px;
    line-height: 2.6rem;
    width: max-content;
    text-transform: uppercase;
  }
  @media (max-width: 768px) {
    margin: 0px;

    > h1 {
      font-size: 100px;
    }
    > h2 {
      font-size: 30px;
    }
    > p {
      font-size: 15px;
    }
  }
`
