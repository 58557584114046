export const Types = {
  PAYMENT_DATA: '@payment/PAYMENT_DATA',
  PAYMENT_COMPRA_DATA: '@payment/PAYMENT_COMPRA_DATA'
}

const INITIAL_STATE = {
  payment: null,
  compra: null
}

export default function payments(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.PAYMENT_DATA:
      return { ...state, payment: action.data }
    case Types.PAYMENT_COMPRA_DATA:
      return { ...state, compra: action.data }
    default:
      return state
  }
}

export function paymentData(data) {
  return {
    type: Types.PAYMENT_DATA,
    data
  }
}
export function compraData(data) {
  return {
    type: Types.PAYMENT_COMPRA_DATA,
    data
  }
}
