import React, { memo } from 'react'

import { Body, Avatar, Title } from './styles'

function Skeleton({ type, width, height, border }) {
  return (
    <>
      {type === 'text' && <Body width={width} height={height} />}
      {type === 'image' && (
        <Avatar width={width} border={border} height={height} />
      )}
      {type === 'title' && <Title width={width} height={height} />}
    </>
  )
}

export default memo(Skeleton)
