import { all, put, call, takeLatest, select } from 'redux-saga/effects'

import history from '../../routes/history'

import api from '../../services/api'

import { purchaseConfirmationLoading, Types } from '../ducks/checkout'

import { paymentData, compraData } from '../ducks/payments'

import { phaseFour } from '../ducks/phases'

export function* paymentConfirmation() {
  const { paymentOptionSelected, billing } = yield select(
    state => state.checkout
  )
  const { items, total, subtotal } = yield select(state => state.cart)

  yield put(purchaseConfirmationLoading(true))

  try {
    const response = yield call(api.post, 'compras', {
      items,
      total,
      subtotal,
      paymentOptionSelected,
      billing
    })

    yield put(paymentData(response.data.payment))
    yield put(compraData(response.data.compras))

    yield put(purchaseConfirmationLoading(false))

    yield put(phaseFour(true))

    history.push('confirmation')
  } catch (err) {
    yield put(purchaseConfirmationLoading(false))
    console.log(err)
  }
}

export default all([
  takeLatest(Types.PAYMENT_DATA_REQUEST, paymentConfirmation)
])
