export const Types = {
  NOTIFICATIONS_DATA: '@notifications/NOTIFICATIONS_DATA',
  NOTIFICATIONS_REQUEST: '@notifications/NOTIFICATIONS_REQUEST',
  NOTIFICATIONS_LOADING: '@notifications/NOTIFICATIONS_LOADING',
  NOTIFICATIONS_CURRENT_PAGE: '@notifications/NOTIFICATIONS_CURRENT_PAGE',
  ADD_NOTIFICATION: '@notifications/ADD_NOTIFICATION',
  UPDATE_NOTIFICATION_REQUEST: '@notifications/UPDATE_NOTIFICATION_REQUEST'
}

const INITIAL_STATE = {
  data: [],
  notificationsUnread: 0,
  totalPaginas: '',
  currentPage: 1,
  notificationsPerPage: '',
  notificationsLastPage: '',
  loading: true
}

export default function notifications(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.NOTIFICATIONS_DATA:
      return {
        ...state,
        data: action.data,
        totalPaginas: action.totalPages,
        notificationsPerPage: action.perPage,
        currentPage: action.pageActual,
        notificationsLastPage: action.lastPage,
        notificationsUnread: action.countUnread
      }
    case Types.NOTIFICATIONS_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.page
      }
    case Types.ADD_NOTIFICATION:
      return {
        ...state,
        data: [...state.data, action.notifications],
        notificationsUnread: state.notificationsUnread + 1
      }
    case Types.NOTIFICATIONS_LOADING:
      return { ...state, loading: action.loading }
    default:
      return state
  }
}

export function notificationsData(
  data,
  totalPages,
  perPage,
  pageActual,
  lastPage,
  countUnread
) {
  return {
    type: Types.NOTIFICATIONS_DATA,
    data,
    totalPages,
    perPage,
    pageActual,
    lastPage,
    countUnread
  }
}
export function notificationsCurrentPage(page, id) {
  return {
    type: Types.NOTIFICATIONS_CURRENT_PAGE,
    page,
    id
  }
}
export function notificationsRequest(id) {
  return {
    type: Types.NOTIFICATIONS_REQUEST,
    id
  }
}
export function addNotification(notifications) {
  return {
    type: Types.ADD_NOTIFICATION,
    notifications
  }
}
export function notificationsLoading(loading) {
  return {
    type: Types.NOTIFICATIONS_LOADING,
    loading
  }
}
export function updateNotificationRequest() {
  return {
    type: Types.UPDATE_NOTIFICATION_REQUEST
  }
}
