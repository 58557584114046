import React from 'react'

import ReactDOM from 'react-dom'

import * as Sentry from '@sentry/react'

import { Integrations } from '@sentry/tracing'

import App from './App'

Sentry.init({
  dsn:
    'https://f647330cda9241c6a6538d6deed78b6a@o493933.ingest.sentry.io/5564023',
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
