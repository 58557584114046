import styled from 'styled-components'

export const BillingsContainer = styled.div`
  background-color: ${props => props.background};
  border-radius: 5px;
  margin: ${props => (props.margin ? '0 30px 0 30px' : '0')};
  padding: 20px;
  ul {
    padding-bottom: 15px;
    list-style: none;
    li {
      font-size: 16px;
      color: ${props => props.color};
      font-weight: 400;
      margin: 0 0 10px 0;
    }
  }
`
export const BillingTitle = styled.h1`
  font-size: 18px;
  color: ${props => props.color};
  font-weight: 500;
  margin-bottom: 15px;
`
export const ChangeButtonBilling = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`
