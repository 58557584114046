import React from 'react'

import { useSelector } from 'react-redux'

import { useHistory } from 'react-router-dom'

import Cart from '../../components/Cart'

import { Container, ContainerTitle, ContainerButton } from './styles'

function CartPage() {
  const history = useHistory()
  const { items } = useSelector(state => state.cart)
  const { data: user } = useSelector(state => state.auth)

  function handleCheckout() {
    const { nome, email, nif, telefone } = user

    if (nome && email && nif && telefone) {
      history.push('payment/billing')
    } else {
      history.push('payment/account')
    }
  }

  return (
    <Container>
      <ContainerTitle>
        <h1>Carrinho</h1>
      </ContainerTitle>
      <Cart shadow={true} quantity={true} delete={true} />
      {items.length > 0 && (
        <ContainerButton>
          <button onClick={handleCheckout}>Checkout</button>
        </ContainerButton>
      )}
    </Container>
  )
}

export default CartPage
