import styled from 'styled-components'

export const Container = styled.div`
  background-color: var(--box-background);
  border-radius: 5px;
  padding: 32px;
  @media (max-width: 825px) {
    padding: 32px 15px 32px 15px;
  }
`
export const ContainerFormPerfil = styled.div`
  @media (max-width: 825px) {
    margin: 0;
    form {
      margin-top: 50px;
    }
  }
`
export const Table = styled.table`
  width: 100%;
  border-spacing: 0px 8px;
  margin-top: -8px;
  thead th {
    color: var(--dark-text-color);
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    padding-left: 15px;
    padding-bottom: 5px;
  }
  thead th:first-child {
    padding-left: 0;
  }
  tbody tr {
    border-radius: 5px;
    background-color: var(--hover-gray);
  }
  tbody tr td:first-child {
    border-radius: 5px 0px 0px 5px;
  }
  tbody tr td:last-child {
    border-radius: 0px 5px 5px 0px;
  }
  tbody tr td {
    color: var(--primary);
    font-size: 16px;
    padding: 16px;
  }
  @media (max-width: 825px) {
    border-spacing: 0px;
    thead {
      display: none;
    }
    tbody tr {
      display: flex;
      flex-direction: column;
      padding: 16px;
      border-radius: 5px;
    }
    tbody tr + tr {
      margin-top: 8px;
    }
    tbody tr td + td {
      margin-top: 0.75em;
    }
    tbody tr td {
      padding: 0px;
    }
    tbody tr td:first-child {
      border-radius: 5px 0px 0px 5px;
    }
    tbody tr td:last-child {
      border-radius: 0px 5px 5px 0px;
    }
    tbody tr td::before {
      content: attr(data-label);
      display: block;
      font-size: 13px;
      color: rgb(115, 115, 128);
      margin-bottom: 0.25em;
    }
  }
`
export const ContainerTitle = styled.div`
  margin: 0 0 50px 0;
  h1 {
    color: var(--primary);
    font-size: 28px;
    font-weight: 300;
  }
  @media (max-width: 825px) {
    h1 {
      margin-bottom: 50px;
    }
  }
`
export const ContainerOtherForm = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1154px) {
    flex-direction: column;
  }
`
