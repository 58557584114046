import styled from 'styled-components'

export const Container = styled.div`
  background-color: var(--black);
`

export const ContainerTitle = styled.div`
  justify-content: start;
  align-items: start;
  margin: 50px 0 50px;

  > h1 {
    color: var(--primary);
    font-size: 48px;
    font-weight: 300;
  }
  @media (max-width: 825px) {
    h1 {
      font-size: 35px;
    }
  }
`

export const ContainerFormPerfil = styled.div`
  @media (max-width: 825px) {
    margin: 0;
    form {
      margin-top: 50px;
    }
  }
`
export const Table = styled.table`
  width: 100%;
  border-spacing: 0px 8px;
  margin-top: -8px;

  .active {
    background-color: var(--red-dark-mode);
    div.produto_link {
      transition: opacity 0.3s ease-in-out;
    }
    div.produto_link:hover {
      color: var(--primary);
      cursor: pointer;
      opacity: 0.8;
    }
    button {
      transition: opacity 0.3s ease-in-out;
    }
    button:hover {
      color: var(--primary);
      cursor: pointer;
      opacity: 0.8;
    }
  }

  @media (min-width: 825px) {
    tbody {
      background-color: var(--box-background);
      border-radius: 5px;
      padding: 32px 32px 24px 32px;
    }
  }
  thead th {
    color: var(--dark-text-color);
    font-size: 15px;
    font-weight: 400;
    text-align: left;
    padding-left: 15px;
    padding-bottom: 5px;
  }
  thead th:first-child {
    padding-left: 15px;
  }
  tbody tr {
    border-radius: 5px;
    background-color: var(--hover-gray);
  }
  tbody tr td:first-child {
    border-radius: 5px 0px 0px 5px;
  }
  tbody tr td:last-child {
    border-radius: 0px 5px 5px 0px;
    padding-bottom: 16px;
  }
  tbody tr td,
  button {
    color: var(--primary);
    font-size: 16px;
    padding: 16px;
    transition: color 0.3s ease-in-out;
    button:hover {
      color: var(--orange);
      cursor: pointer;
    }
    div.produto_link {
      color: var(--primary);
      font-size: 16px;
      padding: 0px 0px 10px 0px;
      transition: color 0.3s ease-in-out;
    }
    div.produto_link:last-child {
      padding: 0;
    }
    div.produto_link:hover {
      color: var(--orange);
      cursor: pointer;
    }
  }
  span.no-content {
    color: var(--primary);
    font-size: 16px;
    padding: 16px;
  }
  @media (max-width: 825px) {
    border-spacing: 0px;
    thead {
      display: none;
    }
    tbody tr {
      display: flex;
      flex-direction: column;
      padding: 16px;
      border-radius: 5px;
    }
    tbody tr + tr {
      margin-top: 8px;
    }
    tbody tr td + td {
      margin-top: 0.75em;
    }
    tbody tr td,
    button {
      padding: 0px;
    }
    tbody tr td:first-child {
      border-radius: 5px 0px 0px 5px;
    }
    tbody tr td:last-child {
      border-radius: 0px 5px 5px 0px;
    }
    tbody tr td::before {
      content: attr(data-label);
      display: block;
      font-size: 13px;
      color: rgb(115, 115, 128);
      margin-bottom: 0.25em;
    }
    tbody tr.active td::before {
      content: attr(data-label);
      display: block;
      font-size: 13px;
      color: #bdbebd;
      font-weight: 500;
      margin-bottom: 0.25em;
    }
    span.no-content {
      padding: 0;
    }
  }
`
export const ContainerModal = styled.div`
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  width: 100%;
  max-height: calc(100vh - 200px);
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    background: var(--scroll-track);
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--gray-light);
  }

  > h1 {
    color: var(--primary);
    font-size: 25px;
    font-weight: 700;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
  }
  > div.modal_buttons {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  > div.data_payment {
    display: flex;
    justify-content: center;
    color: var(--primary);
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
  }
  @media (max-width: 825px) {
    padding: 15px;
    > h1 {
      font-size: 20px;
    }
  }
`
export const ComprovativoContainer = styled.div`
  width: 100%;
  div {
    color: var(--primary);
    font-size: 18px;
    font-weight: 400;
    padding-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
  }
  input[type='file'] {
    width: 100%;
    margin-bottom: 15px;
  }
`
