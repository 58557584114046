import styled from 'styled-components'

export const Notification = styled.div`
  margin: 20px 0 20px 0;

  background: rgb(74, 74, 77, 0.5);
  transition: background-color 0.5s ease;
  border-radius: 5px;

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`
export const ContainerUserNotificacao = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 20px 20px 20px;

  .notification_data .container_title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  .notification_data .container_title > p.info {
    background-color: var(--background-color-info);
    border-radius: 5px;
    margin-left: 15px;
    padding: 5px;
    color: var(--primary);
    font-size: 12px;
    font-weight: 400;
    text-align: justify;
  }

  .notification_data .container_title > p.updates {
    background-color: var(--background-color-success);
    border-radius: 5px;
    margin-left: 15px;
    padding: 5px;
    color: var(--primary);
    font-size: 12px;
    font-weight: 400;
    text-align: justify;
  }

  .notification_data {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    position: relative;
  }

  .notification_data h1 {
    color: var(--primary);
    font-size: 18px;
    font-weight: 400;
  }
  .notification_data h1::after {
    display: ${props => (props.unRead ? 'none' : 'block')};
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--orange);
    position: absolute;
    top: 7px;
    right: 0;
    margin-left: 10px;
  }
  .notification_data > p {
    color: var(--dark-text-color);
    font-size: 14px;
    font-weight: 400;
    text-align: justify;
    margin-top: 10px;
  }
  .notification_data strong {
    color: var(--dark-text-color);
    font-size: 14px;
    font-weight: 400;
    text-align: justify;
  }
  @media (max-width: 825px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .notification_data {
      align-items: center;
    }
    .notification_data .container_title {
      flex-direction: column;
    }
    .notification_data .container_title > p {
      margin-left: 0;
      margin-top: 5px;
    }
  }
`
export const Avatar = styled.div`
  width: 100%;
  max-width: 50px;
  height: 50px;
  border-radius: 50px;
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 20px;
  @media (max-width: 825px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`
export const NotFoundNotification = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  color: var(--dark-text-color);
  font-size: 15px;
  font-weight: 400;
  text-align: justify;
  margin-top: 50px;
`
