export const Types = {
  USER_DATA: '@auth/USER_DATA',
  SET_USER_TOKEN: '@auth/SET_USER_TOKEN',
  LOAD_USER_TOKEN: '@auth/LOAD_USER_TOKEN',
  LOGOUT_USER: '@auth/LOGOUT_USER',
  LOGOUT_REQUEST: '@auth/LOGOUT_REQUEST'
}

const INITIAL_STATE = {
  data: null,
  token: null
}

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.USER_DATA:
      return { ...state, data: action.data }
    case Types.SET_USER_TOKEN:
      return { ...state, token: action.token }
    case Types.LOAD_USER_TOKEN:
      return { ...state, token: action.token }
    case Types.LOGOUT_USER:
      return INITIAL_STATE
    default:
      return state
  }
}

export function userData(data) {
  return {
    type: Types.USER_DATA,
    data
  }
}
export function setToken(token) {
  return {
    type: Types.SET_USER_TOKEN,
    token
  }
}
export function loadToken(token) {
  return {
    type: Types.LOAD_USER_TOKEN,
    token
  }
}
export function logoutUser() {
  return {
    type: Types.LOGOUT_USER
  }
}
export function logoutRequest() {
  return {
    type: Types.LOGOUT_REQUEST
  }
}
