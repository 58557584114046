import React, {
  useEffect,
  useState,
  useCallback,
  Fragment,
  useRef,
  useMemo,
  useLayoutEffect
} from 'react'

import { MdDone, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'

import { useDispatch, useSelector } from 'react-redux'

import { useParams } from 'react-router-dom'

import Vimeo from '@u-wave/react-vimeo'

import logo from '../../assets/logo.png'

import { Button } from '../../components/Buttons'

import Modal from '../../components/Modal'

import history from '../../routes/history'

import api from '../../services/api'

import { addToCart } from '../../store/ducks/cart'

import { requestCompras } from '../../store/ducks/compras'

import { produtoRequest } from '../../store/ducks/produto'

import {
  Container,
  ContainerDetalheCurso,
  Sidebar,
  ContainerSidebar,
  Menu,
  HeaderMenu,
  NumberModulo,
  MenuTitle,
  NavbarDropdownContent,
  Dropdown,
  SubDropdown,
  ContainerVideo,
  TituloVideo,
  VideoPlayer,
  ContainerModal,
  ContainerModalSucess,
  TitleModalSucess,
  TitleModal,
  ContainerPaymentsCursos,
  PrecoCurso,
  LoadingContainer,
  DescricaoVideo,
  DescricaoContainer
} from './styles'

export default function Curso() {
  const [sources, setSources] = useState('')
  const [open, setOpen] = useState({})
  const [isActiveAula, setIsActiveAula] = useState({})
  const [aula, setAula] = useState({})
  const [changeModal, setChangeModal] = useState(false)
  const [moduloCurso, setModuloCurso] = useState({})
  const dispatch = useDispatch()
  const { slug, modulo, lesson, sub_aula } = useParams()
  const modalRef = useRef()

  const { data: curso, loading } = useSelector(state => state.produto)
  const { data: compras } = useSelector(state => state.compras)
  const { data: user } = useSelector(state => state.auth)

  const scrollRef = curso?.modulos?.reduce((acc, value) => {
    acc[value.slug] = React.createRef()
    return acc
  }, {})

  const scrollToRef = useCallback(
    id => {
      window.scrollTo(0, scrollRef[id].current.offsetTop)
    },
    [scrollRef]
  )

  const getProduct = useCallback(() => {
    dispatch(produtoRequest(slug))
  }, [dispatch, slug])

  const handleLesson = useCallback(
    (aula, modulo) => {
      const { slug: aulaSlug } = aula
      const { slug: moduloSlug } = modulo

      history.push(`/curso/${slug}/group/${moduloSlug}/lesson/${aulaSlug}`)

      if (aula.video) {
        const aulaLink = aula.video

        setIsActiveAula({ [aulaSlug]: !isActiveAula[aulaSlug] })
        setSources(aulaLink)
        setAula(aula)
      } else {
        const aulaLink = [aula.sub_aulas[0].video]

        history.push(
          `/curso/${slug}/group/${moduloSlug}/lesson/${aulaSlug}/sub-aula/${aula.sub_aulas[0].slug}`
        )
        setIsActiveAula({
          [aula.sub_aulas[0].slug]: !isActiveAula[aula.sub_aulas[0].slug]
        })
        setSources(aulaLink)
        setAula(aula)
      }
    },
    [isActiveAula, slug]
  )

  const handleSubLesson = useCallback(
    (aula, sub_aula, modulo) => {
      const { slug: aulaSlug } = aula
      const { slug: moduloSlug } = modulo
      const { slug: subAulaSlug } = sub_aula

      history.push(
        `/curso/${slug}/group/${moduloSlug}/lesson/${aulaSlug}/sub-aula/${subAulaSlug}`
      )

      const aulaLink = sub_aula.video

      setIsActiveAula({
        [aulaSlug]: isActiveAula[aulaSlug],
        [subAulaSlug]: !isActiveAula[subAulaSlug]
      })
      setSources(aulaLink)
      setAula(aula)
    },
    [isActiveAula, slug]
  )

  const getAula = useCallback(async () => {
    const response = await api.get(`/aulas/${lesson}`)

    const { video } = response.data
    setSources(video)
    setAula(response.data)
  }, [lesson])

  const getSubAula = useCallback(async () => {
    const response = await api.get(`/sub_aulas/${sub_aula}`)

    const { video, aula } = response.data
    setSources(video)
    setAula(aula)
  }, [sub_aula])

  const handleOpenModal = useCallback(() => {
    modalRef.current.openModal()
  }, [])

  const handleCloseModal = useCallback(() => {
    modalRef.current.closeModal()
  }, [])

  const reduceCompras = useCallback((compras, moduloId) => {
    return compras?.reduce((accumulator, currentCompra) => {
      if (!currentCompra.status) {
        return accumulator
      }
      return accumulator.concat(
        currentCompra.item.filter(item => item.modulo_id === moduloId)
      )
    }, [])
  }, [])

  const dropdownOpen = useCallback(
    modulo => {
      const { id } = modulo

      if (!user.roles.findIndex(role => role.slug === 'admin')) {
        setOpen({ [modulo.slug]: !open[modulo.slug] })
        scrollToRef(modulo.slug)
        return
      }

      setModuloCurso(modulo)

      if (!modulo.pago) {
        setOpen({ [modulo.slug]: !open[modulo.slug] })
        scrollToRef(modulo.slug)
      }

      if (reduceCompras(compras, id).length > 0) {
        setOpen({ [modulo.slug]: !open[modulo.slug] })
        scrollToRef(modulo.slug)
        return
      }

      if (modulo.pago) {
        handleOpenModal()
      }
    },
    [compras, handleOpenModal, open, reduceCompras, scrollToRef, user]
  )

  const addCart = useCallback(
    item => {
      const { thumbnail, titulo, id } = curso
      const { id: modulo_id, nome, preco } = item

      const itemCart = {
        id: id,
        modulo_id: modulo_id,
        thumbnail: thumbnail,
        titulo: `${titulo} ${nome}`,
        preco: preco,
        tipo: 'modulos'
      }

      dispatch(addToCart(itemCart))
      setChangeModal(true)
    },
    [curso, dispatch]
  )

  const getCompras = useCallback(() => {
    dispatch(requestCompras())
  }, [dispatch])

  useEffect(() => {
    if (lesson && modulo && sub_aula) {
      setOpen({ [modulo]: true })
      setIsActiveAula({ [lesson]: true, [sub_aula]: true })
      getSubAula()
    }
  }, [getAula, getSubAula, lesson, modulo, sub_aula])

  useEffect(() => {
    if (lesson && modulo && !sub_aula) {
      setOpen({ [modulo]: true })
      setIsActiveAula({ [lesson]: true })
      getAula()
    }
  }, [getAula, lesson, modulo, sub_aula])

  useEffect(() => {
    getCompras()
  }, [getCompras])

  useLayoutEffect(() => {
    getProduct()
  }, [dispatch, getProduct])

  useEffect(() => {
    const { modulos } = curso

    if (modulos && !lesson && !modulo) {
      setOpen({ [modulos[0]?.slug]: true })
      setIsActiveAula({ [modulos[0]?.aulas[0]?.slug]: true })
      setSources(modulos[0]?.aulas[0]?.video)
      setAula(modulos[0]?.aulas[0])
    }
  }, [curso, lesson, modulo])

  const contentModal = useMemo(() => {
    return (
      <>
        {changeModal ? (
          <ContainerModalSucess>
            <TitleModalSucess>
              <MdDone size={30} color="green" />
              <p>Poduto adicionado ao carrinho de compras</p>
            </TitleModalSucess>

            <div className="modal_buttons">
              <Button
                onClick={() => history.push('/cart')}
                title="Finalizar compra"
              />
              <Button onClick={handleCloseModal} title="Continuar a comprar" />
            </div>
          </ContainerModalSucess>
        ) : (
          <ContainerModal>
            <TitleModal>{curso.titulo}</TitleModal>
            <ContainerPaymentsCursos>
              <ul>
                <li>{moduloCurso.nome}</li>
              </ul>
            </ContainerPaymentsCursos>
            <PrecoCurso>
              {Intl.NumberFormat('pt-PT', {
                style: 'currency',
                currency: 'EUR'
              }).format(moduloCurso.preco)}
            </PrecoCurso>
            <div className="modal_buttons">
              <Button onClick={handleCloseModal} title="Sair" />
              <Button onClick={() => addCart(moduloCurso)} title="Comprar" />
            </div>
          </ContainerModal>
        )}
      </>
    )
  }, [addCart, changeModal, handleCloseModal, moduloCurso, curso])

  if (loading) {
    return (
      <LoadingContainer>
        <div>
          <img src={logo} alt="" />
        </div>
      </LoadingContainer>
    )
  }

  return (
    <>
      <Container>
        <ContainerDetalheCurso>
          <Sidebar>
            <ContainerSidebar>
              <Menu>
                {curso?.modulos?.map((modulo, index) => (
                  <Fragment key={modulo.id}>
                    <HeaderMenu
                      ref={scrollRef[modulo.slug]}
                      onClick={() => dropdownOpen(modulo)}
                    >
                      <NumberModulo>
                        <span>{index + 1}</span>
                      </NumberModulo>
                      <MenuTitle>
                        <span>{modulo.nome}</span>
                        <span>{modulo.aulas.length} aulas</span>
                      </MenuTitle>
                      {open[modulo.slug] ? (
                        <MdKeyboardArrowUp size={24} />
                      ) : (
                        <MdKeyboardArrowDown size={24} />
                      )}
                    </HeaderMenu>
                    <NavbarDropdownContent open={open[modulo.slug]}>
                      {modulo.aulas.map(aula => (
                        <Fragment key={aula.id}>
                          <Dropdown active={isActiveAula[aula.slug]}>
                            <button type="button"></button>
                            <span onClick={() => handleLesson(aula, modulo)}>
                              {aula.nome}
                            </span>
                          </Dropdown>
                          {aula.sub_aulas.map(sub_aula => (
                            <Fragment key={sub_aula.id}>
                              {isActiveAula[aula.slug] && (
                                <SubDropdown
                                  openSubMenu={isActiveAula[aula.slug]}
                                  active={isActiveAula[sub_aula.slug]}
                                >
                                  <button type="button"></button>
                                  <span
                                    onClick={() =>
                                      handleSubLesson(aula, sub_aula, modulo)
                                    }
                                  >
                                    {sub_aula.nome}
                                  </span>
                                </SubDropdown>
                              )}
                            </Fragment>
                          ))}
                        </Fragment>
                      ))}
                    </NavbarDropdownContent>
                  </Fragment>
                ))}
              </Menu>
            </ContainerSidebar>
          </Sidebar>
          <ContainerVideo>
            <VideoPlayer>
              {sources && <Vimeo video={sources} autoplay={false} />}
            </VideoPlayer>
          </ContainerVideo>
        </ContainerDetalheCurso>
        <TituloVideo>
          <h1>{aula?.nome}</h1>
        </TituloVideo>
        <DescricaoVideo>
          <div className="descricao_button">
            <button>Descrição</button>
          </div>
          <DescricaoContainer>
            <div className="sub_container">
              <div className="container_content">
                <div className="content">
                  {aula?.descricao ? (
                    <span className="active">{aula?.descricao}</span>
                  ) : (
                    <span>Este vídeo ainda não tem descrição</span>
                  )}
                </div>
              </div>
            </div>
          </DescricaoContainer>
        </DescricaoVideo>
      </Container>
      <Modal ref={modalRef} data={contentModal} />
    </>
  )
}
