import React, { useRef, useState } from 'react'

import ReactLoading from 'react-loading'

import { useDispatch } from 'react-redux'

import { Link } from 'react-router-dom'

import { toast } from 'react-toastify'

import { Form } from '@unform/web'

import * as Yup from 'yup'

import Button from '../../components/Buttons/Button'

import { Input } from '../../components/Form'

import api from '../../services/api'

import { setToken } from '../../store/ducks/auth'

import { Container, ContainerForm, ContainerRecuperar } from './styles'

export default function ChangePassword(props) {
  const formRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  async function handleForgotPassword(data, { reset }) {
    setLoading(true)
    try {
      const schema = Yup.object().shape({
        new_password: Yup.string().required('Nova password obrigatório'),
        confirm_password: Yup.string()
          .required('Confirmar password obrigatório')
          .oneOf([Yup.ref('new_password'), null], 'Passwords não coincidem')
      })

      await schema.validate(data, {
        abortEarly: false
      })

      formRef.current.setErrors({})

      const { new_password } = data

      const { token } = props.match.params

      const response = await api.put(`/users/forgot-password/${token}`, {
        newPassword: new_password
      })

      setLoading(false)

      dispatch(setToken(response.data.token))

      toast.success('Password guardada com sucesso!', {
        position: toast.POSITION.TOP_RIGHT
      })

      reset()
    } catch (err) {
      setLoading(false)

      if (err.response) {
        toast.error(err.response.data.message.error, {
          position: toast.POSITION.TOP_RIGHT
        })
      }

      if (err instanceof Yup.ValidationError) {
        const validationErrors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
          setLoading(false)
        }
      }
    }
  }

  return (
    <Container>
      <h1>Recuperar</h1>
      <ContainerForm>
        <Form ref={formRef} onSubmit={handleForgotPassword}>
          <div className="form-group">
            <Input
              type="password"
              name="new_password"
              placeholder="Nova password"
            />
          </div>
          <div className="form-group">
            <Input
              type="password"
              name="confirm_password"
              placeholder="Confirmar password"
            />
          </div>

          <Button
            type="submit"
            transform="true"
            title="Guardar"
            loading={
              loading && (
                <ReactLoading
                  type="spin"
                  color="var(--primary)"
                  height={20}
                  width={20}
                />
              )
            }
          />

          <ContainerRecuperar>
            <Link className="entrar_link" to="/">
              Entrar na conta
            </Link>
          </ContainerRecuperar>
        </Form>
      </ContainerForm>
    </Container>
  )
}
