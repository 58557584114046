import React, { useCallback, useEffect } from 'react'

import { MdCheck } from 'react-icons/md'

import { useDispatch, useSelector } from 'react-redux'

import { useHistory } from 'react-router-dom'

import { Button } from '../../../components/Buttons'

import Phases from '../../../components/Phases'

import { Multibanco, Transfer } from '../../../components/Terminals'

import { resetCart } from '../../../store/ducks/cart'

import { Container, ContainerOpcoes, ContainerButton } from './styles'

function Finalizar() {
  const history = useHistory()
  const dispatch = useDispatch()

  const { payment, compra } = useSelector(state => state.payments)
  const { paymentOptionSelected } = useSelector(state => state.checkout)
  const { data: user } = useSelector(state => state.auth)

  useEffect(() => {
    return () => {
      dispatch(resetCart())
    }
  }, [dispatch])

  const closeCheckout = useCallback(() => {
    history.push('/dashboard')
  }, [history])

  const ConfirmationPaymentDetails = useCallback(() => {
    if (paymentOptionSelected.metodo === 'multibanco') {
      return <Multibanco data={payment} />
    } else if (paymentOptionSelected.metodo === 'transferencia') {
      return <Transfer data={payment} />
    } else {
      return null
    }
  }, [payment, paymentOptionSelected.metodo])

  return (
    <Container>
      <Phases activeMetodo="confirmacao" />
      <ContainerOpcoes>
        <div>
          <MdCheck size={20} color="var(--primary)" />
        </div>

        <p>
          Caro {user?.nome}: Seu pedido com Nº {compra?.id} foi concluído com
          sucesso. Iremos enviar também por email uma fatura pró-forma com todas
          as instruções necessárias para formalizar o pagamento da encomenda.
        </p>
        <p>
          Finalize agora o pagamento do seu pedido pelo método de pagamento
          anterioramente escolhido por si para usufruir já do seu conteúdo. Caso
          por algum motivo exista algum problema, entre em contacto connosco via{' '}
          <a href="mailto:info@meufole.com">info@meufole.com.</a>
        </p>
        <p>
          O resumo da sua encomenda foi enviado para o seu email ({user?.email}.
          Lembre-se de que você pode verificar todos os seus pedidos no seu
          perfil da sua conta de utilizador. Muito obrigado por comprar no Meu
          Fole.
        </p>
        <span>
          <ConfirmationPaymentDetails />
        </span>
      </ContainerOpcoes>

      <ContainerButton>
        <div>
          <Button
            onClick={closeCheckout}
            type="button"
            width={150}
            title="Sair"
          />
        </div>
      </ContainerButton>
    </Container>
  )
}

export default Finalizar
