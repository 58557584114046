import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  align-items: stretch;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`
export const RegistoContainer = styled.div`
  background-color: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  flex: 1 1 0%;
  padding: 32px 0;
  h1 {
    color: var(--primary);
    font-size: 48px;
    font-weight: 700;
  }
  @media (max-width: 1105px) {
    padding: 15px 0 30px 0px;
  }
  @media (max-width: 900px) {
    flex: 1 1 100%;
    h1 {
      font-size: 28px;
    }
  }
`
export const SliderLogin = styled.div`
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  flex: 1 1 0%;
  @media (max-width: 900px) {
    background-image: unset;
    background-repeat: unset;
    background-position: unset;
    background-size: unset;
  }
`
export const ContainerForm = styled.div`
  background-color: var(--box-background);
  border-radius: 4px;
  margin-top: 40px;
  form {
    margin: 30px;
    display: flex;
    flex-direction: column;
  }
  form .form-group {
    position: relative;
    margin-bottom: 20px;
    span {
      display: inline-block;
      color: var(--red);
      font-size: 13px;
      font-weight: 500;
      padding-top: 7px;
    }
  }
  form .form-group input {
    width: 300px;
    height: 50px;
    background-color: var(--black);
    border-radius: 4px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    color: var(--primary);
    &::placeholder {
      color: var(--dark-text-color);
    }
  }
`
export const ContainerRecuperar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  .entrar_link {
    color: var(--primary);
    font-size: 15px;
    font-weight: 400;
    transition: opacity 0.3s ease-in-out;
    opacity: 0.8;
  }
  .entrar_link:hover {
    opacity: 1;
  }
`
export const ContainerPoliticas = styled.div`
  width: 300px;
  font-size: 14px;
  color: var(--dark-text-color);
  text-align: center;
  line-height: 26px;
  margin-bottom: 20px;
  margin-top: 10px;

  > a {
    font-size: 14px;
    font-weight: 600;
    color: var(--orange);
    margin: 40px 0px 24px;
    opacity: 0.8;
    transition: opacity 0.2s ease 0s;
  }
`
