import React from 'react'

import { Container, Button1 } from './styles'

function Button({
  title,
  radius,
  width,
  height,
  margin,
  transform,
  icon,
  background,
  border,
  color,
  loading,
  ...rest
}) {
  return (
    <Container
      radius={radius}
      width={width}
      height={height}
      margin={margin}
      background={background}
      border={border}
    >
      {loading ? (
        <Button1 transform={transform} color={color} {...rest}>
          {loading}
        </Button1>
      ) : (
        <Button1 transform={transform} color={color} {...rest}>
          {icon} {'  '}
          {title}
        </Button1>
      )}
    </Container>
  )
}

export default Button
