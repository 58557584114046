import styled from 'styled-components'

export const Container = styled.div`
  background-color: var(--box-background);
  border-radius: 5px;
  padding: 32px;
  @media (max-width: 825px) {
    padding: 32px 15px 32px 15px;
  }
`
export const ContainerFormPerfil = styled.div`
  @media (max-width: 825px) {
    margin: 0;
    form {
      margin-top: 50px;
    }
  }
`
export const InputGroup = styled.div`
  margin-bottom: 20px;
  position: relative;
  span {
    display: inline-block;
    color: #fa2b56;
    font-size: 13px;
    font-weight: 500;
    padding-top: 7px;
  }
  input {
    width: 100%;
    height: 50px;
    background-color: var(--black);
    border-radius: 5px;
    color: var(--primary);
    padding-left: 15px;
    font-weight: 400;
    font-size: 14px;
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`
export const LabelInput = styled.div`
  color: var(--dark-text-color);
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 5px;
`
export const ContainerOtherForm = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1154px) {
    flex-direction: column;
  }
`
export const InputGroupOther = styled(InputGroup)`
  width: 49%;
  @media (max-width: 1154px) {
    width: 100%;
  }
`
export const IconUploadAvatar = styled.div`
  width: 100%;
  max-width: 80px;
  height: 80px;
  position: absolute;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
  background: rgb(0, 0, 0, 0.4);
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s linear, opacity 0.3s linear;

  @media (max-width: 825px) {
    max-width: 60px;
    height: 60px;
  }
`
export const IconUploadProgress = styled(IconUploadAvatar)``
export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 27px 0;
  position: relative;
  div.avatar {
    display: flex;
    flex: 1;
    align-items: center;
    h1 {
      color: var(--primary);
      font-size: 20px;
      font-weight: 300;
      margin-bottom: 5px;
    }
    p {
      color: var(--dark-text-color);
      font-size: 14px;
      font-weight: 400;
    }
    input[type='file'] {
      display: block;
      font-size: 999px;
      width: 100%;
      max-width: 80px;
      height: 100%;
      opacity: 0;
      position: absolute;
      text-align: right;
      z-index: 555;
      cursor: pointer;
    }
  }
  div.avatar input[type='file'] {
    &:hover + ${IconUploadAvatar} {
      visibility: visible;
      opacity: 1;
    }
  }
  div.avatar {
    ${IconUploadProgress} {
      visibility: visible;
      opacity: 1;
    }
  }
`
export const Avatar = styled.div`
  width: 80px;
  height: 80px;
  margin-right: 25px;
  flex-shrink: 0;
  img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    padding: 2px;
    border: 2px solid rgb(225, 225, 230);
    border-radius: 100%;
  }

  @media (max-width: 825px) {
    width: 60px;
    height: 60px;
    margin-right: 20px;
  }
`
export const ContainerSaldoUser = styled.div`
  display: flex;
  div {
    justify-content: flex-end;
    align-items: flex-end;
  }
  h1 {
    color: #1a47b0;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  p {
    color: #373c48;
    font-size: 14px;
    font-weight: 400;
  }
`
