import React from 'react'

import Skeleton from '../../Skeleton'

import { Notification, ContainerUserNotificacao, Avatar } from './styles'

function LoadingNotifications() {
  const Notifications = () => (
    <Notification>
      <ContainerUserNotificacao>
        <Avatar>
          <Skeleton type="image" width="120px" height="120px" />
        </Avatar>
        <div className="notification_data">
          <Skeleton type="title" width="100%" height="13px" />
          <Skeleton type="title" width="100%" height="13px" />

          <p className="descricao">
            <Skeleton type="title" width="100%" height="13px" />
            <Skeleton type="title" width="100%" height="13px" />
            <Skeleton type="title" width="100%" height="13px" />
          </p>
        </div>
      </ContainerUserNotificacao>
    </Notification>
  )

  return (
    <>
      <Notifications />
      <Notifications />
      <Notifications />
      <Notifications />
      <Notifications />
      <Notifications />
      <Notifications />
      <Notifications />
    </>
  )
}

export default LoadingNotifications
