import 'react-toastify/dist/ReactToastify.css'
import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }

  html,
  body,
  #root {
    height: 100%;
    scroll-behavior: smooth;
  }

  body {
    font: 400 14px Roboto, sans-serif;
    font-size: 14px;
    background: var(--black);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased !important;
    > #CybotCookiebotDialog {
      background: #202024;
      > #CybotCookiebotDialogBody {
        > #CybotCookiebotDialogPoweredbyLink {
          > #CybotCookiebotDialogPoweredbyImage {
            content:url("https://i.ibb.co/LxqP4gq/logo.png");
          }
        }
        > #CybotCookiebotDialogBodyContent {
          > #CybotCookiebotDialogBodyContentTitle {
            color: #a8a8b3;
          }
          > #CybotCookiebotDialogBodyContentText {
            color: #a8a8b3;
          }
        }
        > #CybotCookiebotDialogBodyContentControls[style] {
          display: none !important;
        }
        > #CybotCookiebotDialogBodyButtons {
          > #CybotCookiebotDialogBodyButtonAccept {
            background-color: var(--orange);
            border: 1px solid var(--orange);
          }
          > #CybotCookiebotDialogBodyButtonDetails {
            color: #a8a8b3 !important;
          }
        }
      }
      > #CybotCookiebotDialogDetail[style] {
        background: #202024 !important;
        > #CybotCookiebotDialogDetailBody {
          > #CybotCookiebotDialogDetailFooter {
            color: #a8a8b3;
            background-color: #202024;
            > a {
              color: #a8a8b3;
            }
          }
          > #CybotCookiebotDialogDetailBodyContent {
            background-color: #202024;
            color: #a8a8b3;
            border: 1px solid #323238;
            border-bottom: 4px solid #323238;
          }
        }
      }
    }
    .CybotCookiebotDialogDetailBodyContentCookieTypeTable thead td, .CybotCookiebotDialogDetailBodyContentCookieTypeTable thead th {
      background-color: #242428!important;
      color: #a8a8b3!important;
      text-align: left;
      vertical-align: top;
      padding: 2px;
      border-bottom: 1px solid #323238;
      font-weight: 400;
    }
    .CybotCookiebotDialogDetailBodyContentCookieTypeTable tbody td {
      border-bottom: 1px solid #323238;
      border-right: 1px solid #242428;
      text-align: left;
      vertical-align: top;
      padding: 4px;
      max-width: 72px;
      overflow: hidden;
      font-size: 9pt;
      color: #a8a8b3!important;
    }
    .CybotCookiebotDialogDetailBodyContentTabsItem {
      background: #242428;
      color: #a8a8b3!important;
      opacity: .85;
      font-size: 10.5pt;
      display: inline-block;
      margin: 1px 0 0;
      cursor: pointer;
      padding: 7px 18px 5px;
    }
    .CybotCookiebotDialogDetailBodyContentTab:first-child {
      border-left: 1px solid #323238;
      -webkit-border-radius: 4px 0 0 0;
      -moz-border-radius: 4px 0 0;
      border-radius: 4px 0 0;
    }
    a.CybotCookiebotDialogDetailBodyContentTabsItemSelected {
      margin: 0;
      text-decoration: none!important;
      color: #a8a8b3!important;
      opacity: 1;
    }
    .CybotCookiebotDialogDetailBodyContentTabsItemSelected {
      background: #202024;
      color: #a8a8b3;
      font-size: 10.5pt;
      display: inline-block;
      opacity: 1;
      padding: 8px 18px 6px;
    }
    .CybotCookiebotDialogDetailBodyContentTabsItem, .CybotCookiebotDialogDetailBodyContentTabsItemSelected {
      border-right: 1px solid #323238;
      position: relative;
      top: 1px;
      z-index: 10;
      white-space: normal;
      line-height: 100%;
      border-top: 1px solid #323238;
      font-weight: 400;
    }
    #CybotCookiebotDialog a, #CybotCookiebotDialog a:hover {
      text-decoration: underline;
      color: #a8a8b3;
    }
    a.CybotCookiebotDialogDetailBodyContentTabsItemSelected:focus, a.CybotCookiebotDialogDetailBodyContentTabsItemSelected:hover {
      text-decoration: none!important;
      color: #a8a8b3!important;
      cursor: default;
      border-top: 1px solid #323238;
      border-right: 1px solid #323238;
    }
    a.CybotCookiebotDialogDetailBodyContentTabsItem:focus, a.CybotCookiebotDialogDetailBodyContentTabsItem:hover {
      text-decoration: none!important;
      background-color: #202024!important;
      color: #a8a8b3!important;
      opacity: .9;
      border-top: 1px solid #323238;
      border-right: 1px solid #323238;
    }
    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes {
      background-color: rgb(32, 32, 36);
      padding-top: 21px;
    }
    .CybotCookiebotDialogDetailBodyContentCookieContainerTypesSelected:first-child {
      border-top: 1px solid #202024;
    }
    .CybotCookiebotDialogDetailBodyContentCookieContainerTypesSelected, .CybotCookiebotDialogDetailBodyContentIABv2TabSelected {
      padding: 8px;
      background-color: #202024;
      border-bottom: 1px solid #323238;
      border-left: none;
      border-right: 1px solid #202024;
      display: block;
      text-decoration: none!important;
      color: #a8a8b3!important;
    }
    a.CybotCookiebotDialogDetailBodyContentCookieContainerTypesSelected:focus, a.CybotCookiebotDialogDetailBodyContentCookieContainerTypesSelected:hover, a.CybotCookiebotDialogDetailBodyContentIABv2TabSelected:focus, a.CybotCookiebotDialogDetailBodyContentIABv2TabSelected:hover {
      text-decoration: none!important;
      color: #a8a8b3!important;
      cursor: default;
      border-bottom: 1px solid #323238;
      border-left: none;
      border-right: 1px solid #202024;
    }
    .CybotCookiebotDialogDetailBodyContentCookieContainerTypes, .CybotCookiebotDialogDetailBodyContentIABv2Tab {
      padding: 8px;
      cursor: pointer;
      background-color: #242428;
      border-bottom: 1px solid #323238;
      border-right: 1px solid #323238;
      border-left: none;
      display: block;
      text-decoration: none!important;
      color: #a8a8b3!important;
      opacity: 1;
    }
    a.CybotCookiebotDialogDetailBodyContentCookieContainerTypes:focus, a.CybotCookiebotDialogDetailBodyContentCookieContainerTypes:hover, a.CybotCookiebotDialogDetailBodyContentIABv2Tab:focus, a.CybotCookiebotDialogDetailBodyContentIABv2Tab:hover {
      text-decoration: none!important;
      background: #202024!important;
      color: #a8a8b3!important;
      opacity: 1;
      border-bottom: 1px solid #323238;
      border-right: 1px solid #323238;
      border-left: none;
    }
  }

  a {
    text-decoration: none;
  }

  input,
  button,
  textarea {
    font: 400 14px Roboto, sans-serif;
    background-color:transparent;
    border:none;
    border: 1px solid var(--black);
    transition: border 0.2s ease 0s;
  }

  input:focus{
    border-color: var(--orange);
  }

  textarea:focus{
    border-color: var(--orange);
  }

  button {
    cursor: pointer;
    border:none;
    outline:none
  }

  :root {
    --primary: #ffffff;
    --black: rgb(18, 18, 20);
    --red: #d80001;
    --red-dark-mode: #c5211c;
    --green-dark-mode: #075E54;
    --golden: #997018;
    --orange: #f26910;
    --border-gray-light: #d9dde5;
    --background-blue-light: #4b69b7;

    --box-background: rgb(32, 32, 36);

    --shadow-black-02: rgba(2, 8, 22, 0.2);
    --shadow-black-19: rgba(2, 8, 22, 0.19);

    --shadow-white-02:  rgba(255, 255, 255, 0.2);
    --shadow-white-19:  rgba(255, 255, 255, 0.19);

    --dark-text-color:  rgb(168, 168, 179);

    --white-text-color: #595f6f;

    --hover-gray: rgb(41, 41, 46);

    --gray-light: #4a4a4d;

    --scroll-track: rgb(41, 41, 46);

    --background-color-info: #3498db;
    --background-color-success: #07bc0c;
    --background-color-warning: #f1c40f;
    --background-color-error: #e74c3c;
  }
`
