import React, { useEffect, useCallback, useLayoutEffect, useState } from 'react'

import { Switch, Route } from 'react-router-dom'

import { All, Informations, Updates } from '../../components/Notifications'

import LoadingNotifications from '../../components/Shimmer/LoadingNotifications'

import history from '../../routes/history'

import {
  Container,
  WrapperNotificacoes,
  ContainerNotificacoes,
  MenuNotificacoes,
  ButtonMenuNotificacao
} from './styles'

export default function Notificacoes(props) {
  const [activeTab, setActiveTab] = useState('')
  const [loading, setLoading] = useState(false)
  const { path } = props.match

  const { pathname } = props.location

  useLayoutEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [activeTab])

  useEffect(() => {
    setActiveTab(pathname)
  }, [pathname])

  const handleTabChange = useCallback((url, e) => {
    const { id } = e.target

    setActiveTab(id)
    history.push(`${url}`)
  }, [])

  return (
    <>
      <Container>
        <WrapperNotificacoes>
          <ContainerNotificacoes>
            <MenuNotificacoes>
              <ButtonMenuNotificacao
                id="/notifications"
                activeTab={activeTab === '/notifications'}
                onClick={e => handleTabChange(path, e)}
              >
                Todas
              </ButtonMenuNotificacao>
              <ButtonMenuNotificacao
                id="/notifications/updates"
                activeTab={activeTab === '/notifications/updates'}
                onClick={e => handleTabChange(`${path}/updates`, e)}
              >
                Actualizações
              </ButtonMenuNotificacao>
              <ButtonMenuNotificacao
                id="/notifications/informations"
                activeTab={activeTab === '/notifications/informations'}
                onClick={e => handleTabChange(`${path}/informations`, e)}
              >
                Informações
              </ButtonMenuNotificacao>
            </MenuNotificacoes>

            {loading ? (
              <LoadingNotifications />
            ) : (
              <Switch>
                <Route exact path={`${path}`} component={All} isPrivate />
                <Route
                  path={`${path}/informations`}
                  component={Informations}
                  isPrivate
                />
                <Route path={`${path}/updates`} component={Updates} isPrivate />
              </Switch>
            )}
          </ContainerNotificacoes>
        </WrapperNotificacoes>
      </Container>
    </>
  )
}
