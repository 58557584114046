import React, { useEffect, useCallback, useState, useLayoutEffect } from 'react'

import { MdPerson, MdPlayCircleFilled } from 'react-icons/md'

import { useSelector, useDispatch } from 'react-redux'

import { useHistory, useParams } from 'react-router-dom'

import ReadMoreReact from 'read-more-react'

import LoadingListCursos from '../../components/Shimmer/LoadingListCursos'

import { clearProdutos, produtosRequest } from '../../store/ducks/produtos'

import {
  Container,
  Sidebar,
  Content,
  ContainerContent,
  LinkContent,
  ContentCurso,
  Layer,
  ContainerDadosCurso
} from './styles'

function Cursos() {
  const [pack, setPack] = useState({})
  const history = useHistory()
  const dispatch = useDispatch()
  const { slug } = useParams()

  const { data: cursos, loading } = useSelector(state => state.produtos)
  const { data: packs } = useSelector(state => state.packs)

  const getPack = useCallback(() => {
    const pack = packs.find(pack => pack.slug === slug)
    setPack(pack)
  }, [packs, slug])

  useEffect(() => {
    getPack()
  }, [getPack])

  useLayoutEffect(() => {
    dispatch(produtosRequest(slug))
    return () => {
      dispatch(clearProdutos())
    }
  }, [dispatch, slug])

  const getTotalAulas = useCallback(modulos => {
    return modulos.reduce((acc, modulo) => {
      return (acc += modulo.aulas.length)
    }, 0)
  }, [])

  const handleViewCurso = useCallback(
    item => {
      history.push(`/curso/${item.slug}`)
    },
    [history]
  )

  if (loading) {
    return <LoadingListCursos />
  }

  return (
    <Container>
      <>
        <Sidebar>
          <Content>
            <img src={pack.thumbnail} alt="" />
            {pack.descricao && (
              <ReadMoreReact
                text={pack.descricao}
                min={90}
                ideal={100}
                max={200}
                readMoreText="Ler mais"
              />
            )}
          </Content>
          <ContainerContent>
            {cursos.map(curso => (
              <LinkContent
                key={curso.id}
                onClick={() => handleViewCurso(curso)}
              >
                <ContentCurso src={curso.thumbnail}>
                  <Layer>
                    <h3>{curso.titulo}</h3>
                    <ContainerDadosCurso>
                      <>
                        <div>
                          <MdPlayCircleFilled
                            size={17}
                            color="var(--primary)"
                          />
                        </div>
                        <span>
                          {curso.modulos.length} módulos
                          <span className="teste2">|</span>
                          <span className="teste1">
                            {' '}
                            {getTotalAulas(curso.modulos)} aulas
                          </span>
                        </span>
                        <div>
                          <MdPerson size={20} color="var(--primary)" />
                          <span>{curso.professor}</span>
                        </div>
                      </>
                    </ContainerDadosCurso>
                  </Layer>
                </ContentCurso>
              </LinkContent>
            ))}
          </ContainerContent>
        </Sidebar>
      </>
    </Container>
  )
}

export default Cursos
