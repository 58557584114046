export const Types = {
  BILLING_DATA: '@checkout/BILLING_DATA',
  BILLINGS_REQUEST: '@checkout/BILLINGS_REQUEST',
  PAYMENT_SELECTED: '@checkout/PAYMENT_SELECTED',
  PHONE_MBWAY: '@checkout/PHONE_MBWAY',
  PAYMENT_DATA: '@checkout/PAYMENT_DATA',
  PAYMENT_DATA_REQUEST: '@checkout/PAYMENT_DATA_REQUEST',
  PAYMENT_SELECTED_REQUEST: '@checkout/PAYMENT_SELECTED_REQUEST',
  LOADING_PURCHASE_CONFIRMATION: '@checkout/LOADING_PURCHASE_CONFIRMATION'
}

const INITIAL_STATE = {
  billing: null,
  paymentMethods: [],
  paymentOptionSelected: null,
  loading: false
}

export default function checkout(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.BILLING_DATA:
      return { ...state, billing: action.data }
    case Types.PAYMENT_DATA:
      return { ...state, paymentMethods: action.data }
    case Types.PAYMENT_SELECTED:
      return { ...state, paymentOptionSelected: action.option }
    case Types.PHONE_MBWAY:
      return {
        ...state,
        paymentOptionSelected: {
          ...state.paymentOptionSelected,
          phone: action.phone
        }
      }
    case Types.LOADING_PURCHASE_CONFIRMATION:
      return { ...state, loading: action.loading }
    default:
      return state
  }
}

export function billingData(data) {
  return {
    type: Types.BILLING_DATA,
    data
  }
}
export function billingRequest() {
  return {
    type: Types.BILLINGS_REQUEST
  }
}
export function paymentSelected(option) {
  return {
    type: Types.PAYMENT_SELECTED,
    option
  }
}
export function phoneMbway(phone) {
  return {
    type: Types.PHONE_MBWAY,
    phone
  }
}
export function paymentsData(data) {
  return {
    type: Types.PAYMENT_DATA,
    data
  }
}
export function paymentDataRequest() {
  return {
    type: Types.PAYMENT_DATA_REQUEST
  }
}
export function paymentSelectedResquest(option) {
  return {
    type: Types.PAYMENT_SELECTED_REQUEST,
    option
  }
}
export function purchaseConfirmationLoading(loading) {
  return {
    type: Types.LOADING_PURCHASE_CONFIRMATION,
    loading
  }
}
