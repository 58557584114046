import styled, { keyframes } from 'styled-components'

const MoveUpDown = keyframes`
  0% { transform: translateY(0); }
  100% { transform: translateY(-15px); }
`

export const Container = styled.div`
  background-color: var(--black);
`
export const ContainerPerfil = styled.div`
  width: 100%;
  padding-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 147.09375px;

  @media (max-width: 1920px) {
    padding-bottom: 300.09375px;
  }

  @media (max-width: 960px) {
    padding: 30px 0 30px;
  }

  @media (max-width: 825px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
export const ContainerTitle = styled.div`
  justify-content: start;
  align-items: start;
  margin: 50px 0 50px;
  h1 {
    color: var(--primary);
    font-size: 48px;
    font-weight: 300;
  }
  @media (max-width: 825px) {
    h1 {
      font-size: 35px;
    }
  }
`
export const ContainerUser = styled.div`
  flex: 1;
  .container_mobile {
    display: none;
  }

  .container_web > section {
    margin-bottom: 20px;
    &:first-child {
      margin-top: 20px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  .container_mobile > div {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 960px) {
    width: 100%;
    > div {
      display: none;
    }
    .container_mobile {
      display: block;
    }
  }

  @media (max-width: 825px) {
    width: 100%;
  }
`
export const ContainerModal = styled.div`
  padding: 40px 40px;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      width: 100%;
    }
  }
`
export const TitleModal = styled.h1`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--primary);
  font-size: 25px;
  font-weight: 700;
  p {
    color: var(--red);
  }
`
export const ContainerDeleteAccount = styled.div`
  width: 100%;
  ul {
    list-style: none;
    padding: 10px 0 30px 0;
    li {
      color: var(--dark-text-color);
      font-size: 15px;
      font-weight: 400;
      text-align: justify;
      padding: 10px 0 10px 0;

      a {
        text-decoration: none;
        color: var(--orange);
      }

      &::before {
        content: '';
        width: 5px;
        height: 5px;
        position: absolute;
        left: 23px;
        border-radius: 100%;
        background: var(--red);
        margin-top: 9px;
      }
    }
  }
`
export const LoadingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  > div {
    -webkit-animation: ${MoveUpDown} 1s infinite alternate;
    animation: ${MoveUpDown} 1s infinite alternate;
  }
`
