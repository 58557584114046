export const Types = {
  PRODUTOS_DATA: '@produtos/PRODUTOS_DATA',
  PRODUTOS_LOADING: '@produtos/PRODUTOS_LOADING',
  PRODUTOS_REQUEST: '@produtos/PRODUTOS_REQUEST',
  PRODUTOS_CURRENT_PAGE: '@produtos/PRODUTOS_CURRENT_PAGE',
  CLEAR_PRODUTOS: '@produtos/CLEAR_PRODUTOS'
}

const INITIAL_STATE = {
  data: [],
  totalPaginas: '',
  currentPage: 1,
  videosPerPage: '',
  videosLastPage: '',
  loading: false
}

export default function produtos(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.PRODUTOS_LOADING:
      return { ...state, loading: action.loading }
    case Types.PRODUTOS_DATA:
      return {
        ...state,
        data: action.data,
        totalPaginas: action.totalPages,
        currentPage: action.pageActual,
        videosPerPage: action.perPage,
        videosLastPage: action.lastPage
      }
    case Types.PRODUTOS_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.page
      }
    case Types.CLEAR_PRODUTOS:
      return INITIAL_STATE
    default:
      return state
  }
}

export function produtosData(data, totalPages, perPage, pageActual, lastPage) {
  return {
    type: Types.PRODUTOS_DATA,
    data,
    totalPages,
    perPage,
    pageActual,
    lastPage
  }
}
export function produtosLoading(loading) {
  return {
    type: Types.PRODUTOS_LOADING,
    loading
  }
}
export function produtosRequest(slug) {
  return {
    type: Types.PRODUTOS_REQUEST,
    slug
  }
}
export function produtosCurrentPage(page, slug) {
  return {
    type: Types.PRODUTOS_CURRENT_PAGE,
    page,
    slug
  }
}
export function clearProdutos() {
  return {
    type: Types.CLEAR_PRODUTOS
  }
}
