export const Types = {
  PACKS_DATA: '@packs/PACKS_DATA',
  PACKS_REQUEST: '@packs/PACKS_REQUEST',
  LOADING_PACKS: '@packs/LOADING_PACKS',
  CLEAR_PACKS: '@packs/CLEAR_PACKS'
}

const INITIAL_STATE = {
  data: [],
  loading: true
}

export default function packs(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.PACKS_DATA:
      return { ...state, data: action.pack }
    case Types.LOADING_PACKS:
      return { ...state, loading: action.loading }
    case Types.CLEAR_PACKS:
      return INITIAL_STATE
    default:
      return state
  }
}

export function packsData(pack) {
  return {
    type: Types.PACKS_DATA,
    pack
  }
}
export function packsRequest() {
  return {
    type: Types.PACKS_REQUEST
  }
}
export function packsLoading(loading) {
  return {
    type: Types.LOADING_PACKS,
    loading
  }
}
export function clearPacks() {
  return {
    type: Types.CLEAR_PACKS
  }
}
