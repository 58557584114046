import React, { useRef, useState } from 'react'

import FacebookLoginWithButton from 'react-facebook-login'

import ReactLoading from 'react-loading'

import { useDispatch } from 'react-redux'

import { Link } from 'react-router-dom'

import { toast } from 'react-toastify'

import { Form } from '@unform/web'

import * as Yup from 'yup'

import FundoLogin from '../../assets/fundo_login.jpg'

import Button from '../../components/Buttons/Button'

import { Input } from '../../components/Form'

// import SliderShow from '../../components/SliderShow'

import history from '../../routes/history'

import api from '../../services/api'

import { setToken } from '../../store/ducks/auth'

import {
  Container,
  ContainerForm,
  Recuperar,
  CriarContaContainer,
  LoginContainer,
  SliderLogin
} from './styles'

export default function Login() {
  const [loading, setLoading] = useState(false)
  const formRef = useRef(null)
  const dispatch = useDispatch()

  async function handleSignIn(data, { reset }) {
    setLoading(true)
    try {
      const schema = Yup.object().shape({
        email: Yup.string()
          .email('Email inválido')
          .required('Email obrigatório')
          .trim(),
        password: Yup.string().required('Password obrigatório')
      })

      await schema.validate(data, {
        abortEarly: false
      })

      formRef.current.setErrors({})

      const { email: user_email, password: password_user } = data

      const email = user_email.trim()
      const password = password_user.trim()

      const response = await api.post('/sessions', { email, password })

      setLoading(false)

      dispatch(setToken(response.data.token))

      reset()
    } catch (err) {
      setLoading(false)

      if (err.response.data.user) {
        toast.error(err.response.data.user.errorMessage, {
          position: toast.POSITION.TOP_RIGHT
        })
      } else {
        toast.error('Dados incorrectos!', {
          position: toast.POSITION.TOP_RIGHT
        })
      }

      if (err instanceof Yup.ValidationError) {
        const validationErrors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
          setLoading(false)
        }
      }
    }
  }

  async function handleSignInFacebook(response) {
    try {
      const { name, email } = response
      const { url: avatar } = response.picture.data

      const responseApi = await api.post('/users', {
        nome: name,
        email,
        avatar
      })

      const { id } = responseApi.data

      const responseSessionFace = await api.post('/sessions-facebook', { id })

      dispatch(setToken(responseSessionFace.data.token))
    } catch (error) {
      history.push('/')
    }
  }

  return (
    <Container>
      <LoginContainer>
        <h1>Entrar</h1>
        <ContainerForm>
          <Form ref={formRef} onSubmit={handleSignIn}>
            <div className="form-group">
              <Input
                autocomplete="off"
                autocorrect="off"
                autocapitalize="none"
                type="text"
                name="email"
                placeholder="Email"
                spellcheck="false"
              />
            </div>
            <div className="form-group">
              <Input type="password" name="password" placeholder="Senha" />
            </div>

            <Button
              type="submit"
              transform="true"
              title="Entrar"
              loading={
                loading && (
                  <ReactLoading
                    type="spin"
                    color="#fff"
                    height={20}
                    width={20}
                  />
                )
              }
            />

            <FacebookLoginWithButton
              appId="1515215971995285"
              autoLoad={false}
              fields="name,email,picture"
              callback={handleSignInFacebook}
              icon="fa-facebook"
              cssClass="button_login_facebook"
              textButton="Login facebook"
              disableMobileRedirect={true}
            />
            <CriarContaContainer>
              <Link className="criar_conta" to="register">
                Criar conta
              </Link>
            </CriarContaContainer>
            <Recuperar>
              <Link className="recuperar_conta" to="recover">
                Recuperar password
              </Link>
            </Recuperar>
          </Form>
        </ContainerForm>
      </LoginContainer>
      <SliderLogin src={FundoLogin} />
      {/* <SliderShow  /> */}
    </Container>
  )
}
