import {
  all,
  takeLatest,
  call,
  put,
  select,
  delay,
  fork
} from 'redux-saga/effects'

import api from '../../services/api'

import { getToken } from '../../services/auth'

import {
  notificationsData,
  notificationsLoading,
  Types
} from '../../store/ducks/notifications'

export function* getNotifications() {
  const token = getToken()

  if (!token) {
    return
  }

  const { currentPage } = yield select(state => state.notifications)

  yield put(notificationsLoading(true))

  try {
    const response = yield call(api.get, 'notifications', {
      params: {
        page: currentPage
      }
    })

    const {
      data,
      total: totalPages,
      perPage,
      page: pageActual,
      lastPage,
      countUnread
    } = response.data

    yield put(
      notificationsData(
        data,
        totalPages,
        perPage,
        pageActual,
        lastPage,
        countUnread
      )
    )

    yield delay(1000)
    yield put(notificationsLoading(false))
  } catch (error) {
    yield put(notificationsLoading(false))
  }
}

export function* updateNotifications() {
  try {
    yield call(api.put, `notifications/${1}`)
    yield call(getNotifications)
  } catch (error) {}
}

export default all([
  fork(getNotifications),
  takeLatest(Types.NOTIFICATIONS_REQUEST, getNotifications),
  takeLatest(Types.NOTIFICATIONS_CURRENT_PAGE, getNotifications),
  takeLatest(Types.UPDATE_NOTIFICATION_REQUEST, updateNotifications)
])
