import React, { useEffect, useRef, useCallback } from 'react'

import { MdArrowBack } from 'react-icons/md'

import { useDispatch, useSelector } from 'react-redux'

import { useHistory } from 'react-router-dom'

import { toast } from 'react-toastify'

import { Form } from '@unform/web'

import * as Yup from 'yup'

import Billings from '../../../components/Billings'

import { Button } from '../../../components/Buttons'

import { Input, Select } from '../../../components/Form'

import Phases from '../../../components/Phases'

import api from '../../../services/api'

import { billingRequest } from '../../../store/ducks/checkout'

import { phaseTwo } from '../../../store/ducks/phases'

import {
  Container,
  ContainerTitleSeccao,
  ContainerOpcoes,
  ContainerButton,
  ContainerFormPerfil,
  InputGroup,
  LabelInput,
  ContainerOtherForm,
  InputGroupOther,
  ContainerButtonSaveBilling
} from './styles'

function Billing() {
  const showForm = useRef(null)
  const formRef = useRef(null)
  const history = useHistory()
  const dispatch = useDispatch()

  const { data: user } = useSelector(state => state.auth)
  const { billing } = useSelector(state => state.checkout)

  const billingsRequest = useCallback(() => {
    dispatch(billingRequest())
  }, [dispatch])

  const showFormAddBilling = useCallback(() => {
    if (billing) {
      showForm.current.style.display = 'none'
    }
  }, [billing])

  useEffect(() => {
    showFormAddBilling()
  }, [showFormAddBilling])

  useEffect(() => {
    billingsRequest()
  }, [billingsRequest])

  const handleSaveBillingUser = useCallback(
    async (data, { reset }) => {
      const { id } = billing
      const { id: user_id } = user
      try {
        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório'),
          pais: Yup.string().required('País obrigatório'),
          cidade: Yup.string().required('Cidade obrigatório'),
          codigo_postal: Yup.string().required('Código Postal obrigatório'),
          morada: Yup.string().required('Morada obrigatório')
        })

        await schema.validate(data, {
          abortEarly: false
        })

        formRef.current.setErrors({})

        const { nome, pais, cidade, codigo_postal, morada } = data

        if (id) {
          await api.put(`billing/${id}`, {
            nome,
            pais,
            cidade,
            codigo_postal,
            morada
          })
        } else {
          await api.post('billing', {
            nome,
            pais,
            cidade,
            codigo_postal,
            morada
          })
        }

        await api.put(`users/${user_id}`, {
          nome,
          pais,
          localidade: cidade,
          codigo_postal,
          morada
        })

        reset()

        dispatch(billingRequest())

        toast.success('Dados guardados com sucesso!', {
          position: toast.POSITION.TOP_RIGHT
        })
      } catch (err) {
        if (err.response) {
          toast.error('Ocorreu um erro, tente novamente!', {
            position: toast.POSITION.TOP_RIGHT
          })
        }

        if (err instanceof Yup.ValidationError) {
          const validationErrors = {}
          if (err instanceof Yup.ValidationError) {
            err.inner.forEach(error => {
              validationErrors[error.path] = error.message
            })
            formRef.current.setErrors(validationErrors)
          }
        }
      }
    },
    [billing, dispatch, user]
  )

  const scrollToRef = useCallback(ref => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
  }, [])

  const handleUpdateBilling = useCallback(
    (ref, e) => {
      e.preventDefault()

      ref.current.style.display = 'block'

      formRef.current.setData(billing)

      formRef.current.setFieldValue('pais', {
        label: billing.pais,
        value: billing.pais
      })
      scrollToRef(ref)
    },
    [billing, scrollToRef]
  )

  const handleNextPayament = useCallback(() => {
    dispatch(phaseTwo(true))

    history.push('methods')
  }, [history, dispatch])

  const backPage = useCallback(() => {
    dispatch(phaseTwo(false))
    history.goBack()
  }, [history, dispatch])

  const options = [{ value: 'Portugal', label: 'Portugal' }]

  const customStyles = {
    control: base => ({
      ...base,
      height: '100%',
      maxHeight: 50,
      background: 'var(--black)',
      borderColor: 'transparent',
      borderRadius: 5,
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'none'
      }
    }),
    valueContainer: base => ({
      ...base,
      height: '100%',
      maxHeight: 50,
      color: 'var(--primary)'
    }),
    placeholder: base => ({
      ...base,
      color: 'var(--primary)'
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? 'var(--black)' : 'var(--black)',
      color: 'var(--primary)'
    }),
    singleValue: base => ({
      ...base,
      color: 'var(--primary)'
    }),
    menuList: base => ({
      ...base,
      backgroundColor: 'var(--black)',
      opacity: '0.5'
    })
  }

  return (
    <Container>
      <Phases activeMetodo="opcoes" />
      <Form ref={formRef} onSubmit={handleSaveBillingUser}>
        <ContainerOpcoes>
          <ContainerTitleSeccao>
            <span>Dados de faturação:</span>
          </ContainerTitleSeccao>
          {billing && (
            <Billings
              update={e => handleUpdateBilling(showForm, e)}
              updateButton={true}
              margin={false}
              background="var(--gray-light)"
              color="var(--primary)"
            />
          )}
          <ContainerFormPerfil ref={showForm}>
            <InputGroup>
              <LabelInput>Nome Completo</LabelInput>
              <Input type="text" name="nome" />
            </InputGroup>
            <ContainerOtherForm>
              <InputGroupOther>
                <LabelInput>País</LabelInput>
                <Select
                  name="pais"
                  options={options}
                  placeholder="Selecione o seu país"
                  styles={customStyles}
                />
              </InputGroupOther>
              <InputGroupOther>
                <LabelInput>Cidade</LabelInput>
                <Input type="text" name="cidade" />
              </InputGroupOther>
            </ContainerOtherForm>
            <ContainerOtherForm>
              <InputGroupOther>
                <LabelInput>Código Postal</LabelInput>
                <Input type="text" name="codigo_postal" />
              </InputGroupOther>
              <InputGroupOther>
                <LabelInput>Morada</LabelInput>
                <Input type="text" name="morada" />
              </InputGroupOther>
            </ContainerOtherForm>
            <ContainerButtonSaveBilling>
              <Button width={150} type="submit" title="Guardar" />
            </ContainerButtonSaveBilling>
          </ContainerFormPerfil>
        </ContainerOpcoes>
      </Form>
      <ContainerButton>
        <div>
          <Button
            onClick={backPage}
            title="Voltar"
            width={150}
            icon={<MdArrowBack size={20} color="var(--primary)" />}
          />
          <Button
            onClick={handleNextPayament}
            type="submit"
            width={150}
            title="Continuar"
          />
        </div>
      </ContainerButton>
    </Container>
  )
}

export default Billing
