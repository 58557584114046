import styled from 'styled-components'

export const Container = styled.div`
  background-color: var(--black);
`
export const ContainerTitle = styled.div`
  justify-content: start;
  align-items: start;
  margin: 50px 0 50px;
  h1 {
    color: var(--primary);
    font-size: 48px;
    font-weight: 300;
  }
  @media (max-width: 825px) {
    h1 {
      font-size: 35px;
    }
  }
`
export const ContainerItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 25px 0;
`
export const ContainerButton = styled.div`
  width: 263px;
  background-color: var(--orange);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 50px auto auto auto;
  button {
    width: 100%;
    background-color: transparent;
    padding: 15px;
    color: var(--primary);
    font-size: 16px;
    font-weight: 500;
  }
`
