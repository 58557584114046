import styled from 'styled-components'

export const Container = styled.div`
  background-color: var(--black);
`
export const ContainerTitleSeccao = styled.div`
  justify-content: start;
  align-items: start;
  margin-bottom: 30px;
  span {
    color: var(--primary);
    font-size: 20px;
    font-weight: 300;
  }
`
export const ContainerOpcoes = styled.div`
  background-color: var(--box-background);
  border-radius: 5px;
  padding: 30px;
  @media (max-width: 825px) {
    padding: 30px 15px 30px 15px;
  }
`
export const ContainerButton = styled.div`
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
`
export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 40px;
  width: 100%;
  h1 {
    color: var(--primary);
    font-size: 25px;
    font-weight: 700;
    padding-bottom: 40px;
  }
  form {
    width: 100%;
    > div.modal_buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  @media (max-width: 825px) {
    padding: 20px 20px;
  }
`
export const InputGroup = styled.div`
  margin-bottom: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  span {
    display: inline-block;
    color: #fa2b56;
    font-size: 13px;
    font-weight: 500;
    padding-top: 7px;
  }
  input {
    width: 100%;
    height: 50px;
    background-color: var(--black);
    border-radius: 5px;
    color: var(--primary);
    padding-left: 15px;
    font-weight: 400;
    font-size: 14px;
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`
export const LabelInput = styled.div`
  color: var(--dark-text-color);
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 5px;
`
