import styled from 'styled-components'

export const Container = styled.div``
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
  span:nth-child(1) {
    margin: 20px 0;
  }
`
export const ContainerBox = styled.div`
  position: relative;
  margin-top: 80px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: minmax(360px, auto);
  grid-column-gap: 24px;
  grid-row-gap: 24px;

  .thumbnail_container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  @media (max-width: 1120px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, minmax(300px, auto));
    padding-bottom: 60px;
    margin-top: 50px;
    .pack {
      padding-bottom: 30px;
    }
  }
  .pack {
    position: relative;
    background-color: var(--box-background);
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.2s;
  }
  .pack .title_pack {
    color: var(--primary);
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .pack .title_topico {
    color: var(--primary);
  }
  .active {
    .button_pack_container {
      background-color: #4a4a4d;
    }
    background-color: #313538;
    transform: translateY(-8px);
    @media (max-width: 1125px) {
      transform: translateY(0);
    }
  }
  .pack:last-child {
    background-color: #4a4a4d;
  }
`
export const TopicosWrapper = styled.div`
  padding-bottom: 20px;
  @media (max-width: 825px) {
    padding-bottom: 0;
  }
`
export const ContainerTopicos = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  position: relative;
  .title_topico {
    color: var(--primary);
    font-size: 14px;
    font-weight: 400;
  }
`
export const ButtonPackContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  .button_pack_container {
    width: 100%;
    max-width: 140px;
    background-color: #313538;
    display: flex;
    padding: 15px;
    border-radius: 5px;
  }
  .button_pack {
    background-color: transparent;
    border: none;
    color: var(--primary);
    font-size: 16px;
    font-weight: 500;
    position: absolute;
    bottom: 30px;
    transition: all 0.2s ease 0s;
    &:hover {
      transform: translateY(-5px);
    }
    @media (max-width: 825px) {
      position: unset;
    }
  }
`
