import { all, call, put, takeLatest } from 'redux-saga/effects'

import api from '../../services/api'

import { Types, billingData } from '../../store/ducks/checkout'

export function* getBillings() {
  try {
    const response = yield call(api.get, 'billing')

    yield put(billingData(response.data))
  } catch (err) {
    console.log(err)
  }
}

export default all([takeLatest(Types.BILLINGS_REQUEST, getBillings)])
